import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import {
  CompiereDataFieldType,
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  DataStore,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { ProcessPingInfo } from '@compiere-ws/models/process-ping-info';
import { CompiereProcessService } from '@compiere-ws/services/compiere-process/compiere-process.service';
import { ProcessInProgressService } from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsData, IupicsSpecificWindow } from '@iupics-manager/models/iupics-data';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { ApizGridUtils } from '@iupics-util/tools/apiz-grid.utils';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import TabUiComponent from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';
import { WorkspaceService } from '@web-desktop/components/workspace/components/workspace-ui/workspace.service';
import { UrlParamsService } from '@web-desktop/controllers/url-params.service';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, has, isNil } from 'lodash';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { Observable, Subscription, of } from 'rxjs';
import * as shajs from 'sha.js';
import AutocompleteEditorComponent from '../editor/autocomplete-editor.component';
import ButtonEditorComponent from '../editor/button-editor.component';
import CalendarEditorComponent from '../editor/calendar-editor.component';
import CheckboxEditorComponent from '../editor/checkbox-editor.component';
import NumberEditorComponent from '../editor/number-editor.component';
import TextEditorComponent from '../editor/text-editor.component';
import { ProcessParams } from '../process-ui/process-ui.component';

@Component({
  selector: 'iu-specific-window-ui',
  templateUrl: './specific-window-ui.component.html',
  styleUrls: ['./specific-window-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    NgClass,
    forwardRef(() => ButtonUiComponent),
    SidebarModule,
    forwardRef(() => ModalUiComponent),
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class SpecificWindowUiComponent
  extends AbstractDynamicComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  sourceModal: ModalUiComponent;
  customFormModalBodyCss = undefined;
  id: string;
  @Input() formId: number;
  @Input() name: string;
  @Input() title = '';
  @Input() description = '';
  @Input() help = '';
  @Input() isModal = false;

  iconClass: string;

  @ViewChild('optional', { read: ViewContainerRef, static: true }) vcrOptional: ViewContainerRef;
  @ViewChild('vcrButtons', { read: ViewContainerRef, static: true }) vcrButtons: ViewContainerRef;

  @Output() closeModalEmitter = new EventEmitter<any>();

  private progressSub: Subscription;
  componentRef: ComponentRef<any>;
  isLoading = false;

  vcrwindow: ViewContainerRef;

  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: true }) vcrSpecific: ViewContainerRef;
  @ViewChild('specificModal', { read: ModalUiComponent, static: true }) specificModal: ModalUiComponent;
  @ViewChild('specificContainer', { read: ElementRef, static: true }) specificContainer: ElementRef;

  index: number;

  customDesignArray: CustomDesignItem[] = [];

  dataContainers: AbstractDataContainer[] = [];
  dataContainersStandalone: AbstractDataContainer[] = [];
  dataStore: DataStore;

  gridViews: GridViewUiComponent[] = [];
  // à init si on veut eviter de refresh sur toute les grids
  activeGrids: ActiveGridData[];
  fields: any[] = [];
  otherParams: any[] = [];
  filters: any[] = [];
  filterFields: any[] = [];
  tables: any[] = [];
  rows: any[] = [];
  columns: any[] = [];
  pinnedColKeys: string[];
  record_ID;
  parentComponent: any;
  parentFormID: number;
  //   displaySearch = false;
  displayFormUI = false;
  searchLinkedComponent;
  windowType = IupicsMenuType.FORM;
  tablesForm: any[] = [];
  mandatoryFields: any[] = [];

  specificData: IupicsSpecificWindow;
  isSidebarOpen = false;
  specificWindowTitle: string;
  sourceComponentData: any;
  sourceComponent: any;
  frameworkComponents: any;
  activeGridView: GridViewUiComponent;
  activeTab: TabUiComponent;
  private channel_id: any;
  private _logs = [];
  private gridExcludedFilterValidation = new Map<String, string[]>();
  private specificGridOptions = new Map<String, SpecificGridOption>();
  logs$: Observable<string[]> = of(this._logs);

  searchPanelValidation: string;
  hasInfoWindowGrid = false;
  shouldAddPadding = false;

  //#region DI
  protected readonly windowFactory = inject(WindowFactoryService);
  protected readonly uiCreator = inject(UICreatorService);
  protected readonly store = inject(DataStoreService);
  protected readonly processService = inject(CompiereProcessService);
  protected readonly socketService = inject(SocketService);
  protected readonly connectorService = inject(SecurityManagerService);
  protected readonly progressService = inject(ProcessInProgressService);
  protected readonly translateService = inject(TranslateService);
  protected readonly messageManager = inject(MessageManagerService);
  protected readonly workspaceService = inject(WorkspaceService);
  protected readonly urlParamsService = inject(UrlParamsService);
  //#endregion

  constructor() {
    super();
    this.frameworkComponents = {
      buttonEditor: ButtonEditorComponent,
      autocompleteEditor: AutocompleteEditorComponent,
      numberEditor: NumberEditorComponent,
      calendarEditor: CalendarEditorComponent,
      textEditor: TextEditorComponent,
      checkboxEditor: CheckboxEditorComponent,
    };
  }

  ngOnInit() {
    if (!this.isModal) {
      this.workspaceService.addLinkedComponentToTabMap(this.activeTab.id, this);
    }
    this.iniNewStore();
    if (!this.customDesignArray || this.customDesignArray.length === 0) {
      this.showSpecificWindow();
    }
  }
  iniNewStore(useCtxUrl: boolean = true) {
    if (this.parentComponent instanceof EditViewUiComponent) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(
          this.formId,
          (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
        );
        this.afterNewSpecificWindowData();
      }
    } else if (this.parentComponent instanceof SpecificWindowUiComponent) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(
          this.formId,
          (<SpecificWindowUiComponent>this.parentComponent).dataStore
        );
        this.afterNewSpecificWindowData();
      }
    } else {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(this.formId);
        this.afterNewSpecificWindowData();
        if (useCtxUrl && this.activeTab && this.activeTab.ctx) {
          // context de l'url
          Object.assign(this.dataStore.data, this.activeTab.ctx);
        }
      }
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.progressSub) {
      this.progressSub.unsubscribe();
    }
    this.socketService.closeDataChannel(this.channel_id);
    if (this.activeTab) this.workspaceService.removeLinkedComponentToTabMap(this.activeTab.id);
  }

  protected newSpecificWindowData(formId: number) {
    return this.store.newSpecificWindowData(formId);
  }

  protected resetWindow() {
    this.fields = [];
    this.componentRefs = [];
    this.vcr.clear();
  }

  /**
   * Nettoie et relance la construction de la fenêtre selon les préférences
   * @param {IupicsSpecificWindow} specific
   * @param {boolean} isCssOnComponent
   */
  rebuildWindow(specific: IupicsSpecificWindow, isCssOnComponent = true) {
    this.resetWindow();
    this.buildWindow(specific, isCssOnComponent);
  }

  /**
   * Lance la construction de la fenêtre selon les préférences
   * @param {IupicsSpecificWindow} specific
   * @param {boolean} isCssOnComponent
   */
  buildWindow(specific: IupicsSpecificWindow, isCssOnComponent = true) {
    if (specific.AD_FormDetail_ID) {
      this.parentFormID = specific.AD_FormDetail_ID;
    }
    if (!this.dataStore) {
      this.dataStore = this.newSpecificWindowData(this.formId);
    }
    if (specific.title !== undefined && specific.title !== null) {
      this.title = specific.title;
    }
    if (specific.angularClass === 'LocationPanelComponent') {
      this['locationDisplaySequence'] = specific.locationDisplaySequence;
    }
    let unusedItems = specific.items;
    if (this.customDesignArray && this.customDesignArray.length > 0) {
      this.specificData = specific;
      for (const customDesignItem of this.customDesignArray) {
        this.componentRefs.push(this.createCustomDesignItem(customDesignItem, isCssOnComponent));
      }
      unusedItems = [];
      const isUnusedItemsDisplayed = this.connectorService.getConfig().getConstant('DISPLAY_UNUSED_ITEMS');
      if (isUnusedItemsDisplayed) {
        unusedItems = specific.items.filter(
          (it) =>
            !this.customDesignArray.find((cit) =>
              cit.type === CustomDesignItemType.FIELD
                ? cit.columnName === it.data.columnName
                : cit.tableName === it.name
            )
        );
      }
    }

    this.fields = [...this.fields, ...unusedItems];
    const transformedFields = this.transformFields(unusedItems);
    for (const item of transformedFields.mainItems) {
      let vcr = this.vcr;
      if (item.component === 'ButtonUiComponent' && item.data.columnName === 'Processing') {
        vcr = this.vcrButtons;
      }

      this.componentRefs.push(this.addComponent(item, isCssOnComponent, vcr));
    }

    for (const item of transformedFields.optionalItems) {
      this.componentRefs.push(this.addComponent(item, isCssOnComponent, this.vcrOptional, 'p-col-12'));
    }
  }

  protected createCustomDesignItem(
    customDesignItem: CustomDesignItem,
    isCssOnComponent = true,
    index?: number,
    datastore?: DataStore
  ) {
    const vcr = index !== undefined ? this[customDesignItem.vcr].toArray()[index] : this[customDesignItem.vcr];
    const item = this.specificData.items.find((specificItem) => {
      return customDesignItem.type === CustomDesignItemType.FIELD
        ? customDesignItem.columnName === specificItem.data.columnName
        : customDesignItem.tableName === specificItem.name;
    });
    if (!item) {
      return null;
    }
    const cssClass = item.data && item.data.cssClass ? item.data.cssClass : customDesignItem.cssClass;
    item.data['events'] = customDesignItem.events;
    if (item.component === 'GridViewUiComponent') {
      if (customDesignItem.shouldCreateQuery) {
        item.data['query'] = this.createQuery(customDesignItem.tableName);
      }
      if (customDesignItem.shouldSelectFirst) {
        item.data['shouldSelectFirst'] = customDesignItem.shouldSelectFirst;
      }
      item.data['tableName'] = customDesignItem.tableName;
      item.data['hiddenColumns'] = customDesignItem.hiddenColumns;
      item.data['columnName'] = customDesignItem.tableName;
      item.data['editorColumns'] =
        customDesignItem.editorColumns !== undefined && customDesignItem.editorColumns.length > 0
          ? customDesignItem.editorColumns
          : item.data['editorColumns'];
      item.data['frameworkComponents'] = customDesignItem.frameworkComponents
        ? customDesignItem.frameworkComponents
        : this.frameworkComponents;
      item.data['isFitResize'] = customDesignItem.isFitResize;
      item.data['suppressRowClickSelection'] =
        customDesignItem.suppressRowClickSelection !== undefined ? customDesignItem.suppressRowClickSelection : true;
    } else {
      if (customDesignItem.label) {
        item.data['label'] = customDesignItem.label;
      }
      if (customDesignItem.overridedCSS) {
        item.data['overridedCSS'] = customDesignItem.overridedCSS;
      }
      item.data['isStandalone'] = customDesignItem.isStandalone;
      item.data['isLabelDisplay'] =
        customDesignItem.isLabelDisplay === undefined || customDesignItem.isLabelDisplay ? true : false;

      if (has(customDesignItem, 'multiple')) {
        item.data['multiple'] = customDesignItem.multiple;
      }

      // ajout detail zoom pour pouvoir zoomer
      item.data.detailZoom =
        item.data.details && (item.referenceId === 30 || item.referenceId === 19 || item.referenceId === 18)
          ? {
              tableName: item.data.details.tableName,
              columnKey: item.data.details.keyColumn,
            }
          : null;
      item.data.isParam = true;
    }
    this.fields.push(item);
    const componentRef = this.addComponent(item, isCssOnComponent, vcr, cssClass, datastore);
    if (index !== undefined) {
      (<any>componentRef.instance).data['vcrIndex'] = index;
    }

    if (item.component === 'GridViewUiComponent') {
      if (!isNil(customDesignItem.isLabelDisplay)) {
        (componentRef.instance as GridViewUiComponent).isSpecificGridTitleDisplay = customDesignItem.isLabelDisplay;
      }
      if (customDesignItem.rowSelection) {
        (componentRef.instance as GridViewUiComponent).rowSelection = customDesignItem.rowSelection;
      }
    }

    if (item.component === 'ButtonUiComponent' && customDesignItem?.btnType) {
      (<ButtonUiComponent>componentRef.instance).btnType = customDesignItem.btnType;
    }
    if (vcr && this[customDesignItem.vcr + 'ViewRefs']) {
      this[customDesignItem.vcr + 'ViewRefs'].set(item.name, vcr.get(vcr.indexOf(componentRef.hostView)));
    }
    return componentRef;
  }

  /**
   * Ajoute les composants dans les les différents vcr
   * @param {any} item
   * @param {boolean} isCssOnComponent
   * @param {ViewContainerRef} vcr
   * @param {string} cssClass
   * @param {DataStore} datastore
   */
  protected addComponent(
    item: any,
    isCssOnComponent: boolean = true,
    vcr: ViewContainerRef = this.vcr,
    cssClass?: string,
    datastore?: DataStore
  ) {
    const usedDataStore = datastore ? datastore : this.dataStore;
    const eventsMap = item.data ? item.data['events'] : null;
    if (item.data && item.data.mandatoryLogic) {
      item.data.isMandatory = LogicEvaluator.evaluateLogic(
        this.connectorService.getIupicsUserContext(),
        item.data.mandatoryLogic
      );
    }
    if (item.data && (item.data.mandatoryLogic || item.data.isMandatory) && item.data.isFilterSearch) {
      this.mandatoryFields.push(item.data.columnName);
    }
    if (vcr) {
      // const componentRef = vcr.createComponent(
      //   <any>CacheManagerService.iupics_components.get(<string>item.component)()
      // );
      const componentRef = createComponent(vcr, <string>item.component);
      if (item.children) {
        for (const child of item.children) {
          this.componentRefs.push(
            this.addComponent(child, true, (<AbstractDynamicComponent>componentRef.instance).vcr)
          );
        }
      } else {
        if (!item.container) {
          item.container = this.container;
        }
        (<AbstractDynamicComponent>componentRef.instance).container = this;
        (<AbstractDynamicComponent>componentRef.instance).DOMParentComponent = this;
        (<AbstractDynamicComponent>componentRef.instance).DOMComponent = componentRef;
        if (item.infoWindowId) {
          let keyColumn: string;
          const values = (item.data.columnsDetails as Map<string, any>).values();
          for (let i = values.next(); !i.done && keyColumn === undefined; i = values.next()) {
            if (i.value.field.field.IsKey) {
              keyColumn = i.value.ColumnName;
            }
          }
          (<AbstractDynamicComponent>componentRef.instance).data = Object.assign({}, item.data, {
            details: { entityID: item.infoWindowId, keyColumn: keyColumn, entityType: 1 },
          });
        } else {
          (<AbstractDynamicComponent>componentRef.instance).data = Object.assign({}, item.data);
        }

        (<AbstractDynamicComponent>componentRef.instance).gridPaginator = item.gridPaginator;
        if (item.tabId) {
          (<AbstractDynamicComponent>componentRef.instance).tabId = item.tabId;
        }

        if (item.gridTabFilter) {
          (<AbstractDynamicComponent>componentRef.instance).gridTabFilter = item.gridTabFilter;
        }

        if (item.zoomInfo) {
          (<AbstractDynamicComponent>componentRef.instance).zoomInfo = item.zoomInfo;
        }

        if (item.linkedComponents) {
          for (const element of item.linkedComponents) {
            (<AbstractDynamicComponent>componentRef.instance).addSubscribeOnLinkedComponent(element, componentRef);
          }
        }
        if (item && item.data && item.data.cssClass) {
          cssClass = item.data.cssClass;
        }
        if (isCssOnComponent) {
          if (cssClass) {
            componentRef.location.nativeElement.className = cssClass;
          } else {
            componentRef.location.nativeElement.className = item.cssClass;
          }
        } else {
          if (cssClass) {
            (<AbstractDynamicComponent>componentRef.instance).cssClass = cssClass;
          } else {
            (<AbstractDynamicComponent>componentRef.instance).cssClass = item.cssClass;
          }
        }
        if (item.data.overridedCSS) {
          (componentRef.instance as AbstractDynamicComponent).overridedCSS = item.data.overridedCSS;
        }
        this.subscriptions.push(
          (<AbstractDynamicComponent>componentRef.instance).componentEmitter.subscribe((event) => {
            this.windowFactory.newEventHandler(event);
          })
        );

        if (componentRef.instance instanceof GridViewUiComponent) {
          const gridView = componentRef.instance;
          for (const header of gridView.data.columnsTableHeader) {
            if (header.cellEditorParams) {
              header.cellEditorParams.onClick = this.onBtnClick.bind(this);
              header.cellEditorParams.onSearch = this.onSearch.bind(this);
              header.cellEditorParams.closePanel = this.closePanel.bind(this);
              header.cellEditorParams.onCalendar = this.onCalendar.bind(this);
              header.cellEditorParams.activeGridView = gridView;
            }
          }

          componentRef.instance.elementRef.nativeElement.onclick = () => {
            this.activeGridView = <GridViewUiComponent>componentRef.instance;
          };

          this.addEventsToComponent(eventsMap, componentRef.instance.divContent);
          if (this.parentFormID) {
            gridView.parentFormID = this.parentFormID;
          }
          if (item.infoWindowId && item.infoWindowId > 0) {
            this.hasInfoWindowGrid = true;
            gridView.windowType = IupicsMenuType.INFO_WINDOW;
          } else {
            gridView.windowType = this.windowType;
          }

          gridView.fieldType = CompiereDataFieldType.COLUMN_INFO;
          if (this.sourceComponentData && this.sourceComponentData.component === 'AutocompleteUiComponent') {
            // ajout de la validation de l'autocomplete source
            gridView.data.validationCode =
              !this.sourceComponentData.validationCode && this.sourceComponentData.data
                ? this.sourceComponentData.data.validationCode
                : this.sourceComponentData.validationCode;
          }
          if (gridView.data['title'] !== undefined && gridView.data['title'] !== null) {
            gridView.isSpecificGrid = true;
            gridView.gridTitle = gridView.data['title'];
          }
          gridView.tabId = item.formDetailId;
          gridView.infoWindowId = item.infoWindowId;
          gridView.isLaunchSearchGrid = item.data.isLaunchSearchGrid;
          gridView.hasUniversalFilter = item.data.hasUniversalFilter;
          gridView.hasCheckbox = item.data.hasCheckbox;
          (componentRef.instance as GridViewUiComponent).suppressRowClickSelection =
            item.data.suppressRowClickSelection !== undefined ? item.data.suppressRowClickSelection : true;

          // ! ne fonctionne pas quand (componentRef.instance as GridViewUiComponent).suppressRowClickSelection = true
          this.subscriptions.push(
            gridView.gridViewCellClicked.subscribe((event) => {
              this.notifyFromCellClicked(<GridViewUiComponent>componentRef.instance, event);
            })
          );

          this.subscriptions.push(
            gridView.multiSelectEmitter.subscribe((rowSelected) => {
              for (const grid of usedDataStore.data['selections']) {
                if (grid['AD_FormDetail_ID'] === gridView.data['AD_FormDetail_ID']) {
                  grid['selection'] = rowSelected.data;
                  this.notifyFromRowSelected(rowSelected.data);
                }
              }
              this.notifySelect(<GridViewUiComponent>componentRef.instance, rowSelected);
            })
          );
          this.gridViews.push(gridView);
          /*Permet de savoir quelle grille s'est mis à jour */
          gridView.gridRefreshed.subscribe((dataStoreRequest: DataStoreRequest) => {
            this.notifyFromGridRefresh(<GridViewUiComponent>componentRef.instance, dataStoreRequest);
          });
          /*Permet de savoir quelle grille s'est mis à jour */
          gridView.fromForm = true;
          // permet d'afficher une ligne de total
          gridView.gridTabAfterViewInitEmitter.subscribe((gridTab) => {
            this.notifyFromGridAfterViewInit(gridView);
            this.changeButtonContainerPadding();
          });
        }

        this.DOMChildrenComponent.push(<AbstractDynamicComponent>componentRef.instance);
        if (componentRef.instance instanceof AbstractDataContainer) {
          if (item.component === 'InputFileUiComponent' && has(item.data, 'multiple')) {
            componentRef.instance.multiple = item.data.multiple;
          }
          // change isRange datacontainer label
          if (componentRef.instance.data && componentRef.instance.data.label && componentRef.instance.data.isRange) {
            switch (item.component) {
              case 'InputNumberUiComponent':
                componentRef.instance.data.label = componentRef.instance.data.label.endsWith('_From')
                  ? this.translateService.instant('ranged-value.number.from') +
                    componentRef.instance.data.label.replace(/_From$/, '')
                  : componentRef.instance.data.label;
                componentRef.instance.data.label = componentRef.instance.data.label.endsWith('_To')
                  ? this.translateService.instant('ranged-value.number.to') +
                    componentRef.instance.data.label.replace(/_To$/, '')
                  : componentRef.instance.data.label;
                break;
              case 'CalendarUiComponent':
                componentRef.instance.data.label = componentRef.instance.data.label.replace(
                  /_From$/,
                  this.translateService.instant('ranged-value.calendar.from')
                );
                componentRef.instance.data.label = componentRef.instance.data.label.replace(
                  /_To$/,
                  this.translateService.instant('ranged-value.calendar.to')
                );
                break;
              default:
                componentRef.instance.data.label = componentRef.instance.data.label.replace(
                  /_From$/,
                  this.translateService.instant('specificWindow.start')
                );
                componentRef.instance.data.label = componentRef.instance.data.label.replace(
                  /_To$/,
                  this.translateService.instant('specificWindow.end')
                );
                break;
            }
          }
          if (!(componentRef.instance instanceof ButtonUiComponent)) {
            this.addEventsToComponent(eventsMap, componentRef.instance.elementRef);
          }
          if (item.data['isStandalone'] !== undefined) {
            (<AbstractDataContainer>componentRef.instance).isStandalone = item.data['isStandalone'];
          }
          (<AbstractDataContainer>componentRef.instance).fieldType = CompiereDataFieldType.FORM_ITEM;
          const defaultValue = usedDataStore.data[item.data.columnName];
          if (defaultValue !== undefined && defaultValue !== null) {
            componentRef.instance.defaultValue = defaultValue;
          }
          // if we specify a datastore we dont want to set defaultvalue
          if (datastore) {
            componentRef.instance.newRecordKey = datastore.key;
            componentRef.instance.isSetDefaultValue = true;
          }
          // componentRef.instance.setNewData(usedDataStore);
          componentRef.instance.dataStored = usedDataStore;
          if (componentRef.instance.isAutocompleteField) {
            (<AutocompleteUiComponent>componentRef.instance).suggestionsFilter = this.filterSuggestions.bind(this);
          }
          if ((<AbstractDataContainer>componentRef.instance).isStandalone) {
            this.dataContainersStandalone.push(componentRef.instance);
            componentRef.instance.ngOnDestroy = () => {
              const index = this.dataContainersStandalone.indexOf(<AbstractDataContainer>componentRef.instance);
              this.dataContainersStandalone.splice(index, 1);
            };
          } else {
            // permettre la notification d'un changement auprès des autres datacontainers
            this.dataContainers.push(componentRef.instance);
            componentRef.instance.afterViewInitEmitter.subscribe(() => {
              this.notifyFromDatacontainerInit(<AbstractDataContainer>componentRef.instance);
            });

            const oldOnDestroy = componentRef.instance.ngOnDestroy ?? (() => {});
            componentRef.instance.ngOnDestroy = () => {
              oldOnDestroy();
              const index = this.dataContainers.indexOf(<AbstractDataContainer>componentRef.instance);
              this.dataContainers.splice(index, 1);
            };
          }
          const fieldValueModifiedSub = componentRef.instance.fieldValueModified.subscribe((dataStored) => {
            this.notifyFromDataChange(item);
            if (!(<AbstractDataContainer>componentRef.instance).isStandalone) {
              this.setDataContainersValueWithChangedStore(dataStored);
            }
          });
          (<AbstractDataContainer>componentRef.instance).data['fieldValueModifiedSub'] = fieldValueModifiedSub;
          this.subscriptions.push(fieldValueModifiedSub);

          if (item.data.isFilterSearch && item.data.isFilterSearch !== undefined) {
            this.filters.push(item.data.columnName);
          } else if (item.data.columnName !== 'Processing') {
            this.otherParams.push(item.data.columnName);
          }
          if (componentRef.instance instanceof ButtonUiComponent) {
            componentRef.instance.itemData = item;
            componentRef.instance.btnElt.nativeElement.addEventListener('click', ($event) => {
              if ($event && $event.preventDefault) {
                $event.preventDefault();
                (<ButtonUiComponent>componentRef.instance).btnElt.nativeElement.blur();
              }
              if (item.data.columnName === 'Search') {
                const missingFieldLabels = this.getMissingMantoryField();
                if (missingFieldLabels.length > 0) {
                  this.checkAndShowMandatory();
                } else {
                  this.refreshGrids(usedDataStore, false);
                }
              } else {
                const verif = this.checkBeforeProcessing();
                if (verif) {
                  if (eventsMap && eventsMap['click']) {
                    eventsMap['click'](item);
                  } else {
                    /* Si une methode ayant comme nom process + le nom de la colonne identifiant le bouton on execute celle-ci */
                    if (
                      (item.processId === undefined || (item.data && item.data.columnName)) &&
                      this['process' + item.data.columnName]
                    ) {
                      this['process' + item.data.columnName](item);
                    } else {
                      this.executeProcess(item.processId);
                    }
                  }
                }
              }
            });
          }
        }
      }
      return componentRef;
    }
  }
  /**
   * Ajout des différents events défini dans customDesignItem.events
   */

  private addEventsToComponent(eventsMap: any, elementRef: ElementRef<any>) {
    if (eventsMap) {
      for (const key of Object.keys(eventsMap)) {
        elementRef.nativeElement.addEventListener(key, eventsMap[key]);
      }
    }
  }

  protected transformFields(items: any[]): { mainItems: any[]; optionalItems: any[] } {
    const itemsOrdered = [];
    const optionalItems = [];
    const processingItems = [];
    let currentIndex = -1;
    for (const item of items) {
      if (item.component === 'GridViewUiComponent') {
        item.data['frameworkComponents'] = this.frameworkComponents;
        item.data['tableName'] = item.data['tableName'] || item.name;
      }
      if (currentIndex < 0 || (!item.data.isSameLine && !item.data.isOptional)) {
        itemsOrdered.push({
          cssClass: '',
          component: 'RowUiComponent',
          children: [],
        });
        currentIndex++;
      }
      if (item.data.isOptional) {
        optionalItems.push(item);
      } else {
        if (item.component === 'ButtonUiComponent' && item.data.columnName === 'Processing') {
          processingItems.push(item);
        } else {
          itemsOrdered[currentIndex].children.push(item);
        }
      }
    }
    return { mainItems: [...itemsOrdered, ...processingItems], optionalItems: optionalItems };
  }

  protected refreshGrids(datastore?: DataStore, isStart?: boolean, data?: IupicsData, tabId?: number) {
    const query: SpecificGridQuery = { filters: {}, validations: {}, fields: {} };
    setTimeout(() => {
      for (const field of this.fields) {
        query.fields[field.data.columnName] = field;
        if (field.data.isFilterSearch) {
          if (field.data.isRange) {
            let valueTmp;
            let valueTmp1;

            // dont put it in filter if the range not completed
            valueTmp = this.dataStore.data[field.data.columnName] ? this.dataStore.data[field.data.columnName] : null;
            valueTmp1 = this.dataStore.data[field.data.columnName + '_To']
              ? this.dataStore.data[field.data.columnName + '_To']
              : null;

            if (valueTmp || valueTmp1) {
              if (field.referenceId === 15) {
                if (valueTmp) {
                  valueTmp = new Date(valueTmp);
                }
                if (valueTmp1) {
                  valueTmp1 = new Date(valueTmp1);
                }
              }
              query.filters[field.data.columnName] = [valueTmp, valueTmp1];
            }
          } else {
            // dont put it in filter if value is null
            const dataContainer = this.dataContainers.find(
              (datacontainer) => datacontainer.data.columnName === field.data.columnName
            );
            if (dataContainer && dataContainer.fieldValue !== null) {
              const valueTmp = this.dataStore.data[field.data.columnName];
              if (valueTmp && (!(valueTmp instanceof Array) || valueTmp.length > 0)) {
                if (!dataContainer.data.hasExtendedLogic) {
                  query.filters[field.data.columnName] = valueTmp;
                } else {
                  query.validations[field.data.columnName] = valueTmp;
                }
              }
            }
          }
        }
      }

      for (const gridView of this.gridViews) {
        if (tabId === undefined || gridView.tabId === tabId) {
          gridView.updateDisplayLogic();
          if ((!isStart || gridView.isLaunchSearchGrid) && this.getMissingMantoryField().length === 0) {
            // check si la grid doit être rafrachie
            if (
              gridView.isDisplayed &&
              (!this.activeGrids ||
                (gridView.data &&
                  this.activeGrids.find(
                    (activeGrid) => activeGrid.tableName === (<GridViewUiComponent>gridView).data['title']
                  )))
            ) {
              /*On enlève les filtres qui ne correspondent pas à une colonne affichée */
              const currentQuery = this.adaptQueryForGridView(query, gridView, data);
              gridView.refreshGrid(currentQuery);
            }
          } else {
            this.reInitGridData(gridView.data.columnName);
          }
        }
      }
    }, 350);
  }

  private adaptQueryForGridView(
    query: SpecificGridQuery,
    gridView: GridViewUiComponent,
    data?: any
  ): SpecificGridQuery {
    /*On enlève les filtres qui ne correspondent pas à une colonne affichée */
    const currentFilters = { ...query.filters };
    const currentFields = { ...query.fields };
    const currentQuery = { ...query };
    if (currentFilters && Object.keys(currentFilters).length > 0) {
      const existingColumn = Object.keys(currentFilters);
      for (const columnName of existingColumn) {
        if (
          !gridView.GridTabInfinityScrollUiComponent.columnsTableHeader.find(
            (columnInfo) => columnInfo.field === columnName
          )
        ) {
          const field = currentFields[columnName];
          if (field.data.isRange) {
            // dont put it in filter if the range not completed
            const valueTmp = this.dataStore.data[field.data.columnName]
              ? this.dataStore.data[field.data.columnName]
              : null;
            const valueTmp1 = this.dataStore.data[field.data.columnName + '_To']
              ? this.dataStore.data[field.data.columnName + '_To']
              : null;

            if (valueTmp || valueTmp1) {
              currentQuery.validations[field.data.columnName] = valueTmp;
              currentQuery.validations[field.data.columnName + '_To'] = valueTmp1;
            }
          } else {
            // dont put it in filter if value is null
            const dataContainer = this.dataContainers.find(
              (datacontainer) => datacontainer.data.columnName === field.data.columnName
            );
            if (dataContainer && dataContainer.fieldValue !== null) {
              const valueTmp = this.dataStore.data[field.data.columnName];
              if (valueTmp) {
                currentQuery.validations[field.data.columnName] = valueTmp;
              }
            }
          }

          delete currentFilters[columnName];
          delete currentFields[columnName];
        }
      }
    }
    currentQuery.filters = currentFilters;
    currentQuery.fields = currentFields;
    // check  grid options
    currentQuery.specificGridOptions = this.getSpecificGridOption((<GridViewUiComponent>gridView).data['tableName']);
    currentQuery.specificGridOptions.isFromInputLaunch = !isNil(data) && data.isLaunchSearchGrid;
    gridView.GridTabInfinityScrollUiComponent.grid.api.deselectAll();
    for (const grid of this.dataStore.data['selections']) {
      grid['selection'] = [];
    }
    if (gridView.data.columnName) {
      this.removeIgnoredFilter(gridView.data.columnName, currentQuery);
    }

    return currentQuery;
  }

  protected createQuery(tableName: string = null) {
    const query: SpecificGridQuery = {
      filters: {},
      validations: {},
      fields: {},
      specificGridOptions: this.specificGridOptions.get(tableName),
    };

    for (const field of this.fields) {
      if (field.data.isFilterSearch) {
        /*déclarer les fields de la form */
        query.fields[field.data.columnName] = field;
        if (field.data.isRange) {
          // dont put it in filter if the range not completed
          const valueTmp = this.dataStore.data[field.data.columnName]
            ? this.dataStore.data[field.data.columnName]
            : null;
          const valueTmp1 = this.dataStore.data[field.data.columnName + '_To']
            ? this.dataStore.data[field.data.columnName + '_To']
            : null;

          if (valueTmp1 || valueTmp) {
            query.validations[field.data.columnName] = valueTmp;
            query.validations[field.data.columnName + '_To'] = valueTmp1;
          }
        } else {
          // dont put it in filter if value is null
          const dataContainer = this.dataContainers.find(
            (datacontainer) => datacontainer.data.columnName === field.data.columnName
          );
          if (dataContainer && dataContainer.fieldValue !== null) {
            const valueTmp = this.dataStore.data[field.data.columnName];
            if (valueTmp && (!(valueTmp instanceof Array) || valueTmp.length > 0)) {
              if (!dataContainer.data.hasExtendedLogic) {
                query.filters[field.data.columnName] = valueTmp;
              } else {
                query.validations[field.data.columnName] = valueTmp;
              }
            }
          }
        }
      }
    }

    return { ...query } as SpecificGridQuery;
  }

  /**
   * à implémenter pour ajouter un filtre selon la columnName
   * @param suggestion suggestion d'autocomplete
   * @param columnName identifiant de l'autocomplete
   */
  filterSuggestions(suggestion: any, columnName: string) {
    return suggestion;
  }

  updateContainerZone(n: number) {}
  onChildUpdate(event: IupicsEvent): void {}
  onSiblingUpdate(event: IupicsEvent): void {}
  onRemoveComponent(event: IupicsEvent): void {}
  ngAfterViewInit() {
    // if (!this.dataStore) {
    //   this.dataStore = this.store.newSpecificWindowData(this.formId);
    // }
    // permettre aux champs liés à la valeur d'un autre de s'adapter
    // commentaire: celà set des valeurs null sur les champs des forms depuis qu'on a changé la récup des defaultvalues dans le abstractdatacontainer
    // this.setDataContainersValueWithChangedStore();
    if (!this.isModal) {
      this.workspaceService.addLinkedComponentToTabMap(this.activeTab.id, this);
    }
  }

  /* showSearchPanel(linkedComponent) {
    console.warn(
      `'showSearchPanel' from specific-window.
         If you see this in console, please contact an Apiz developer (lfi for now) and show him how you did.`
    );
    let url = null;
    if (linkedComponent.data.urlSearch) {
      url = linkedComponent.data.urlSearch;
    } else if (linkedComponent.data && linkedComponent.data.details && linkedComponent.data.details.entityID) {
      url = '/Field/Search?id=' + linkedComponent.data.details.entityID + '&type=0';
    }
    if (url) {
      this.subscriptions.push(
        this.uiCreator.getFieldsData(url).subscribe((data) => {
          linkedComponent.data.searchColumns = data;
          this.displaySearch = true;
          this.searchLinkedComponent = linkedComponent;
        })
      );
    }
  } */

  /*   closeModalSearch() {
    this.displaySearch = false;
  } */

  /*
   * emailEditor: { display: { key: 'displayEmailEditor', value: displayEmailEditor }}
   * joinFilesPanel: { display: { key: 'displayJoinFilesPanel', value: displayJoinFilesPanel }}
   * processModal: { display: { key: 'displayProcessUI', value: displayProcessUI }, id: { key: 'processId', value: processId } }
   * formModal: { display: { key: 'displayFormUI', value: displayFormUI }, id: { key: 'formId', value: formId } }
   * sourceComponent : déterminer si autocomplete ou button
   */
  public updateModalDisplay(
    display: { key: string; value: boolean; sourceComponent?: any },
    id?: { key: string; value: number }
  ) {
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent);
      }
    } else {
      this.customFormModalBodyCss = undefined;
    }
  }

  protected createSpecificWindow(formId: number, sourceComponent: any) {
    this.subscriptions.push(
      this.uiCreator.getSpecificWindow(formId).subscribe((specificWindow) => {
        let component;
        if (
          specificWindow.angularClass &&
          specificWindow.angularClass.length > 0 &&
          specificWindow.angularClass !== 'default'
        ) {
          component = CacheManagerService.iupics_specific_window.get(specificWindow.angularClass);
        }
        if (!component) {
          component = CacheManagerService.iupics_specific_window.get('default');
        }
        this.vcrSpecific.clear();
        const componentRef = this.vcrSpecific.createComponent(component);
        this.specificWindowTitle = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).name = specificWindow.name;
        (<SpecificWindowUiComponent>componentRef.instance).title = specificWindow.title;
        (<SpecificWindowUiComponent>componentRef.instance).description = specificWindow.description;
        (<SpecificWindowUiComponent>componentRef.instance).help = specificWindow.help;
        // (<SpecificWindowUiComponent>componentRef.instance).iconClass = specificWindow.iconClass;
        (<SpecificWindowUiComponent>componentRef.instance).componentRef = componentRef;
        // (<SpecificWindowUiComponent>componentRef.instance).id = specificWindow.id;
        (<SpecificWindowUiComponent>componentRef.instance).isModal = true;
        (<SpecificWindowUiComponent>componentRef.instance).formId = formId;
        (<SpecificWindowUiComponent>componentRef.instance).vcrwindow = this.vcrSpecific;
        (<SpecificWindowUiComponent>componentRef.instance).parentComponent = this;
        (<SpecificWindowUiComponent>componentRef.instance).sourceModal = this.specificModal;
        (<SpecificWindowUiComponent>componentRef.instance).index = this.vcrSpecific.length - 1;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponentData =
          sourceComponent && sourceComponent.itemData ? sourceComponent.itemData : sourceComponent;
        (<SpecificWindowUiComponent>componentRef.instance).sourceComponent =
          sourceComponent; /**dupliqué si pas d'itemdata mais trop de refactoring sur scout */
        (<SpecificWindowUiComponent>componentRef.instance).closeModalEmitter.subscribe(() =>
          this.updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })
        );
      })
    );
  }

  protected showSpecificWindow() {
    const dynComponent: DynamicComponent = {
      container: this,
      DOMParentComponent: this,
      component: 'SpecificWindowUiComponent',
      cssClass: 'p-col-12',
      isCssOnComponent: false,
      tabId: this.formId,
      gridPaginator: false,
    };
    this.windowFactory.newEventHandler({
      type: IupicsTypeEvent.showSpecificWindow,
      item: dynComponent,
    });
  }

  /**
   * check params before processing
   * A implémenter si on veut une vérif avant d'éxecuter le process
   */
  checkAndShowMandatory() {
    const missingFieldLabels = this.getMissingMantoryField();
    if (missingFieldLabels.length > 0) {
      let missingFields = '';
      for (const label of missingFieldLabels) {
        missingFields += (missingFields.length > 0 ? ', ' : '') + label;
      }
      MessageManagerService.newMessageStatic(
        new IupicsMessage(
          this.translateService.instant('generic.error'),
          this.translateService.instant('specificWindow.fillMandatory') + ' ' + missingFields,
          'warning'
        )
      );
      return false;
    } else {
      return true;
    }
  }

  protected checkBeforeProcessing() {
    const missingFieldLabels = this.getMissingMantoryField();
    if (missingFieldLabels.length > 0) {
      this.checkAndShowMandatory();
      return false;
    } else {
      return true;
    }
  }

  protected executeProcess(processId: number, additionalParams?: any) {
    this.socketService.closeDataChannel(this.channel_id);
    const channel_id = shajs('sha256')
      .update(this.connectorService.getIupicsUserAccount().session_id + '_' + processId + '_' + Date.now())
      .digest('hex');
    this.channel_id = channel_id;
    const button = this.getButtonFromProcessID(processId);
    if (!button.isReadOnly) {
      this.updateButtonReadOnly(processId);
      this.processService.getProcess(processId).subscribe((success) => {
        if (success) {
          let paramsMap: ProcessParams = {
            ad_process_id: null,
            className: null,
            record_id: null,
            tableName: null,
            tables: null,
            params: null,
            windowCtx: this.getCurrentContext(),
            ad_tab_id: null,
          };
          const classname = success.process.ClassName;
          const AD_Process_ID = success.process.AD_Process_ID;
          let rowSelected = false;
          if (this.dataStore.data.selections) {
            for (const row of this.dataStore.data.selections) {
              if (row.selection.length > 0) {
                rowSelected = true;
                break;
              }
            }
          }
          if (!rowSelected) {
            /*  for (let i = 0; i < this.dataStore.data.selections[j].selection.length; i++) {
            this.rows[i] = {
              record_ID: this.dataStore.data.selections[j].selection[i].Data_UUID,
              columns: this.dataStore.data.selections[j].selection[i]
            };
          }*/
            const tableName = this.isModal
              ? this.parentComponent.editTabs
                ? this.parentComponent.editTabs[0].data.tableName
                : null
              : null;
            if (this.isModal) {
              paramsMap = {
                ad_process_id: AD_Process_ID,
                params: {},
                className: classname,
                record_id: this.dataStore.data['Data_UUID'].split(',')[1],
                tableName: tableName,
                tables: [],
                ad_tab_id: null,
                windowCtx: this.getCurrentContext(),
              };
            } else {
              paramsMap = {
                record_id: null,
                ad_process_id: AD_Process_ID,
                params: {},
                className: classname,
                tableName: tableName,
                tables: [],
                ad_tab_id: null,
                windowCtx: this.getCurrentContext(),
              };
            }
            for (const filter of this.filters) {
              for (const key of Object.keys(this.dataStore.data)) {
                if (filter === key) {
                  if (
                    this.dataStore.data[filter] !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter].id !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter].id;
                  } else if (
                    this.dataStore.data !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter] !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter];
                  } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                    paramsMap.params[filter] = null;
                  }
                }
              }
            }

            for (const filter of this.otherParams) {
              for (const key of Object.keys(this.dataStore.data)) {
                if (filter === key) {
                  if (
                    this.dataStore.data[filter] !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter].id !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter].id;
                  } else if (
                    this.dataStore.data !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter] !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter];
                  } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                    paramsMap.params[filter] = null;
                  }
                }
              }
            }
            if (additionalParams) {
              paramsMap.params = { ...paramsMap.params, ...additionalParams };
            }
            paramsMap.params['channel_id'] = channel_id;

            (async () => {
              for await (const log of this.socketService.openDataChannel<any>(this.channel_id)) {
                this.addLog(log);
                this.notifyFromLogs(log);
              }
            })();
            if (
              this.parentComponent &&
              this.parentComponent.container &&
              this.parentComponent.container.activeTab &&
              this.parentComponent.container.activeTab.actionID
            ) {
              let recordId = null;
              let sourceTableNameTargeted = null;
              if (
                this.parentComponent.currentDataStoreKey &&
                this.parentComponent.currentDataStoreKey.recordId &&
                this.parentComponent.editTabs &&
                this.parentComponent.editTabs[0] &&
                this.parentComponent.editTabs[0].data
              ) {
                recordId = this.parentComponent.currentDataStoreKey.recordId.split(',')[1];
                sourceTableNameTargeted = this.parentComponent.editTabs[0].data.tableName;
              }
              // ajout des infos pour l'ouverture via notif
              paramsMap.windowCtx = Object.assign(paramsMap.windowCtx, {
                sourceInfo: {
                  sourceType: IupicsMenuType.WINDOW,
                  sourceTypeId: this.parentComponent.container.activeTab.actionID,
                  sourceRecordId: recordId + '',
                  sourceTableNameTargeted: sourceTableNameTargeted,
                  params: paramsMap.params,
                },
              });
            } else {
              // ajout des infos pour l'ouverture via notif
              paramsMap.windowCtx = Object.assign(paramsMap.windowCtx, {
                sourceInfo: {
                  sourceType: IupicsMenuType.FORM,
                  sourceTypeId: this.formId,
                  params: paramsMap.params,
                },
              });
            }
            const obs = this.uiCreator.executeProcess(paramsMap);

            this.subscriptions.push(
              obs.subscribe((instance) => {
                this.doItAfterLaunchingOfProcess(processId);
              })
            );
          } else {
            const tableName = this.isModal
              ? this.parentComponent.editTabs
                ? this.parentComponent.editTabs[0].data.tableName
                : null
              : null;
            this.tablesForm = [];
            for (let j = 0; j < this.dataStore.data.selections.length; j++) {
              this.rows[j] = new Array(this.dataStore.data.selections[j].selection.length);
              for (let i = 0; i < this.dataStore.data.selections[j].selection.length; i++) {
                const splittedDataUUID = this.dataStore.data.selections[j].selection[i].Data_UUID.split(',');
                const row = {
                  record_ID: splittedDataUUID.length > 1 ? splittedDataUUID[1] : splittedDataUUID[0],
                  columns: this.dataStore.data.selections[j].selection[i],
                };
                // if (row.columns['Data_UUID']) {
                //   row.columns['Data_UUID'] = row.record_ID;
                // }
                this.rows[j][i] = row;
              }
              this.tablesForm.push({
                ad_FormDetail_ID: this.dataStore.data.selections[j].AD_FormDetail_ID,
                rows: this.rows[j],
              });
            }
            if (this.isModal) {
              paramsMap = {
                ad_process_id: AD_Process_ID,
                params: {},
                className: classname,
                record_id: this.dataStore.data['Data_UUID'].split(',')[1],
                tableName: tableName, // added in aim to test the process , it wrong
                tables: this.tablesForm,
                ad_tab_id: null,
                windowCtx: this.getCurrentContext(),
              };
            } else {
              paramsMap = {
                record_id: null,
                ad_process_id: AD_Process_ID,
                params: {},
                className: classname,
                tableName: tableName,
                tables: this.tablesForm,
                ad_tab_id: null,
                windowCtx: this.getCurrentContext(),
              };
            }
            for (const filter of this.filters) {
              for (const key of Object.keys(this.dataStore.data)) {
                if (filter === key) {
                  if (
                    this.dataStore.data[filter] !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter].id !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter].id;
                  } else if (
                    this.dataStore.data !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter] !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter];
                  } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                    paramsMap.params[filter] = null;
                  }
                }
              }
            }

            for (const filter of this.otherParams) {
              for (const key of Object.keys(this.dataStore.data)) {
                if (filter === key) {
                  if (
                    this.dataStore.data[filter] !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter].id !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter].id;
                  } else if (
                    this.dataStore.data !== undefined &&
                    this.dataStore.data[filter] !== null &&
                    this.dataStore.data[filter] !== undefined
                  ) {
                    paramsMap.params[filter] = this.dataStore.data[filter];
                  } else if (this.dataStore.data === undefined || this.dataStore.data[filter] === null) {
                    paramsMap.params[filter] = null;
                  }
                }
              }
            }
            paramsMap.params['channel_id'] = channel_id;
            (async () => {
              for await (const log of this.socketService.openDataChannel<any>(this.channel_id)) {
                this.addLog(log);
                this.notifyFromLogs(log);
              }
            })();
            if (
              this.parentComponent &&
              this.parentComponent.container &&
              this.parentComponent.container.activeTab &&
              this.parentComponent.container.activeTab.actionID
            ) {
              let recordId = null;
              let sourceTableNameTargeted = null;
              if (
                this.parentComponent.currentDataStoreKey &&
                this.parentComponent.currentDataStoreKey.recordId &&
                this.parentComponent.editTabs &&
                this.parentComponent.editTabs[0] &&
                this.parentComponent.editTabs[0].data
              ) {
                recordId = this.parentComponent.currentDataStoreKey.recordId.split(',')[1];
                sourceTableNameTargeted = this.parentComponent.editTabs[0].data.tableName;
              }
              // ajout des infos pour l'ouverture via notif
              paramsMap.windowCtx = Object.assign(paramsMap.windowCtx, {
                sourceInfo: {
                  sourceType: IupicsMenuType.WINDOW,
                  sourceTypeId: this.parentComponent.container.activeTab.actionID,
                  sourceRecordId: recordId + '',
                  sourceTableNameTargeted: sourceTableNameTargeted,
                  params: paramsMap.params,
                },
              });
            } else {
              // ajout des infos pour l'ouverture via notif
              paramsMap.windowCtx = Object.assign(paramsMap.windowCtx, {
                sourceInfo: {
                  sourceType: IupicsMenuType.FORM,
                  sourceTypeId: this.formId,
                  params: paramsMap.params,
                },
              });
            }
            const obs = this.uiCreator.executeProcess(paramsMap);
            this.subscriptions.push(
              obs.subscribe((instance) => {
                this.doItAfterLaunchingOfProcess(processId);
              })
            );
            this.tablesForm = [];
          }
        }
      });
    }
  }
  doItAfterLaunchingOfProcess(processId: number) {
    if (this.parentComponent !== undefined) {
      (this.parentComponent as EditViewUiComponent).isProcessLaunch = true;
    }
    if (this.progressSub) {
      this.progressSub.unsubscribe();
    }
    this.updateLoading(true);
    this.progressSub = this.progressService.watchProcessInProgress().subscribe((pings) => {
      const me = this.connectorService.getIupicsUserAccount();
      const ctx = this.connectorService.getIupicsUserContext();
      const ping = pings.find((p) => {
        return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === processId;
      });
      if (ping && (ping.Status === 'finish' || ping.Status === 'cancel')) {
        this.socketService.closeDataChannel(this.channel_id);
        this.progressSub.unsubscribe();
        this.updateLoading(false);
        this.onEndProcess(ping);
      }
    });
    this.onTriggerProcess();
  }
  getWindowContext() {
    return this.getCurrentContext();
  }
  updateLoading(isLoading) {
    this.isLoading = isLoading;
    if (this.sourceModal) {
      this.sourceModal.isLoading = isLoading;
    }
  }
  /**
   * Méthode permettant d'ajouter du traitement après le lancement du process
   */
  onTriggerProcess() {}
  /**
   * Méthode permettant d'ajouter du traitement après la réponse du process
   */
  onEndProcess(ping: ProcessPingInfo = null) {
    if (ping && ping.AD_Process_ID && ping.AD_Process_ID.id) {
      setTimeout(() => {
        this.updateButtonReadOnly(ping.AD_Process_ID.id, false);
      }, 3000);
    }
    this.refreshGrids(this.dataStore, false);
  }
  /**
   * Méthode permettant d'ajouter du traitement après le clic sur un Editor
   */
  onBtnClick(e) {}

  closePanel() {}
  onSearch(e) {}
  onCalendar(e) {}

  addLog(...logs: string[]) {
    this._logs = [...this._logs, logs];
    this.logs$ = of(this._logs);
  }

  clearLogs() {
    this._logs = [];
    this.logs$ = of(this._logs);
  }
  /*
   * ************************************************************************************************
   * ************************* METHODES A IMPLEMENTER LORSQU4ON VEUT ETRE NOTIFIER D'UN EVENT *********************************************
   * ************************************************************************************************
   */

  /**
   * ? info: a overrider pour recuperer les event de fieldValueModified
   * à implémenter si on veut faire qqchose en cas de changement d'un champs
   * @param item tem contenant les datas du composant
   */
  notifyFromDataChange(item?: any) {
    if (item.data.isLaunchSearchGrid) {
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }

  /**
   * à implémenter si on veut etre notifié des sélections
   * @param gridView grid sur laquelle s'est fait la sélection
   * @param rowSelected data de la ligne sélectionnée
   */
  notifySelect(gridView: GridViewUiComponent, rowSelected?: { selected: boolean; data: {}[] }) {}

  /**
   *  Permet d'être notifié qu'une ligne est sélectionnée
   *  @param rowSelected array of rows selected
   */
  notifyFromRowSelected(rowSelected: any) {}

  /**
   *  Permet d'être notifié quand une ligne est sélectionnée
   *  @param gridView grid sur laquelle une cellule est sélectionnée
   *  @param event event contenant les datas de la cellule
   */
  notifyFromCellClicked(gridView: GridViewUiComponent, event: any) {}

  /**
   * Permet d'être notifié quand une cellule est sélectionnée
   * @param gridView refreshed gridview
   * @param dataStoreRequest request used to get gridview data
   */
  notifyFromGridRefresh(gridView: GridViewUiComponent, dataStoreRequest: DataStoreRequest) {}

  /**
   * Permet d'être notifié quand une gridview est complètement initialisée
   * @param gridView  gridview dont le GridTab est passée dans l'afterViewInit
   */
  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    gridView.GridTabInfinityScrollUiComponent.pinnedColKeys = this.pinnedColKeys;
    this.refreshGrids(this.dataStore, true, null, gridView.tabId);
  }

  /**
   * Permet d'être notifié qu'un datacontainer est complètement construit
   * @param datacontainer datacontainer qui est passé dans son afterViewInit
   */
  notifyFromDatacontainerInit(datacontainer: AbstractDataContainer) {}

  /**
   * @param log log received by socket
   */
  notifyFromLogs(log) {}

  /*
   * ************************************************************************************************
   * ************************* METHODES SUR LES DATACONTAINERS *********************************************
   * ************************************************************************************************
   */

  /**
   *Set la nouvelle value au datacontainer
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param value nouvelle valeur ( remarque: objet de type {id,displayValue} dans le cas d'un autocomplete)
   */
  setDatacontainerValue(columnNameDB: string, value: any) {
    const dataContainerFound = this.dataContainers.find(
      (dataContainer) => dataContainer.data.columnName === columnNameDB
    );
    if (dataContainerFound !== undefined) {
      dataContainerFound.updateStore(value);
      if (value === undefined) {
        dataContainerFound.changeFieldValue(dataContainerFound.dataStored);
      }
    }
  }

  /**
   * Set à nouveau les datas du store de la form aux abstractdatacontainers ( Uitilisé après avoir modifié le datastore de la form )
   */
  setDataContainersValueWithChangedStore(dataStore?: DataStore) {
    const dataStoreUsed = dataStore ? dataStore : this.dataStore;
    for (const datacontainer of this.dataContainers) {
      if (dataStoreUsed.data && datacontainer.data && datacontainer.data.columnName) {
        datacontainer.updateStore(dataStoreUsed.data[datacontainer.data.columnName]);
      }
    }
  }

  /**
   * Initialise toutes les valeurs des datacontainers à null par défaut ou par la valeur indiqué en paramètre
   * @param value nouvelle valeur
   */
  reInitAllDatacontainersValue(value = null) {
    for (const dataContainer of this.dataContainers) {
      dataContainer.updateStore(value);
    }
  }

  /**
   * Renvoie le premier abstractdadacontainer de la fenêtre qui possède le nom de colonne spécifié venant du formdetailItem et 'undefined' si il n'existe pas.
   * @param columnNameDB columnNameDB de l'abstractDatacontainer à récupérer
   */
  getDatacontainer(columnNameDB: string) {
    return this.dataContainers.find((datacontainer) => datacontainer.data.columnName === columnNameDB);
  }
  /**
   * Renvoie le premier abstractdadacontainer de la fenêtre qui possède le process id venant du formdetailItem et 'undefined' si il n'existe pas.
   * @param processID processID de l'abstractDatacontainer à récupérer
   */
  getButtonFromProcessID(processId: number): ButtonUiComponent {
    const button = <ButtonUiComponent>(
      this.dataContainers.find(
        (datacontainer) => datacontainer.itemData && datacontainer.itemData.processId === processId
      )
    );
    return button;
  }
  /**
   * Update
   * @param processID processID de l'abstractDatacontainer à récupérer
   */
  updateButtonReadOnly(processId: number, isReadOnly = true) {
    const buttonComponent: ButtonUiComponent = this.getButtonFromProcessID(processId);
    if (buttonComponent) {
      // for cypress
      buttonComponent.channelId = this.channel_id;
      buttonComponent.isReadOnly = isReadOnly;
    }
  }
  /**
   * Renvoie les données reçu du ws pour le champ qui possède le nom de colonne spécifié venant du formdetailItem et 'undefined' si il n'existe pas.
   * @param columnNameDB columnNameDB de l'abstractDatacontainer à récupérer
   */
  getField(columnNameDB: string) {
    return this.fields.find((f) => f.data.columnName === columnNameDB);
  }
  /**
   * Renvoie un tableau des noms des champs mandatory vide
   */
  getMissingMantoryField(): string[] {
    const mandatoryFields = [];
    if (this.mandatoryFields.length > 0) {
      for (const column of this.mandatoryFields) {
        const dataContainer = this.getDatacontainer(column);
        if (dataContainer && dataContainer.data && dataContainer.data.isMandatory) {
          if (
            this.dataStore &&
            (this.dataStore.data[column] === null || this.dataStore.data[column] === undefined) &&
            (dataContainer.fieldValue === null || dataContainer.fieldValue === undefined)
          ) {
            mandatoryFields.push(dataContainer.label);
          }
        }
      }
    }
    return mandatoryFields;
  }

  /*
   * ************************************************************************************************
   * ************************* METHODES SUR LES CALLOUTS *********************************************
   * ************************************************************************************************
   */

  /**
   * Permet de lancer un callout pour un field en indiquant la fin de l'url, si on n'indique pas de dataStore, le datastore de la form sera utilisé.
   * @param urlCallout fin de l'url du callout exemple pour le M_Product_id : 'C_OrderLine/M_Product_ID'
   * @param dataStore store de la form
   * @param callbackFunction function de callback à la fin de ce callout
   */
  callout(urlCallout: string, dataStore: DataStore = this.dataStore, callbackFunction?: Function) {
    const columnName = urlCallout.split('/').pop();
    if (columnName) {
      this.store.calloutData(
        '/Field/Callout/' + urlCallout,
        {
          columnName: columnName,
          newValue: dataStore.data[columnName],
          windowCtx: this.getCurrentContext(dataStore),
        },
        dataStore,
        null,
        () => {
          if (callbackFunction) {
            callbackFunction();
          }
        }
      );
    }
  }

  /*
   * ************************************************************************************************
   * ************************* METHODES SUR LE STORE DE LA FORM *********************************************
   * ************************************************************************************************
   */
  /**
   * Renvoie la valeur pour le nom de colonne donné dans le datastore de la form
   * @param columnName nom de la colonne dans le dataStore de la form à setter
   * @param defaultValue une valeur par défaut si on ne le trouve pas dans le store ou que la valeur est null ou undefined
   */
  getFormStoreData(columnName: string, defaultValue?: any) {
    let value;
    if (this.dataStore && this.dataStore.data) {
      value = this.dataStore.data[columnName];
    }
    return value !== null && value !== undefined ? value : defaultValue ? defaultValue : value;
  }
  /**
   * Set la valeur pour le nom de colonne donné dans le datastore de la form
   * @param columnName nom de la colonne dans le dataStore de la form à setter
   * @param value valeur à setter
   */
  setFormStoreData(columnName: string, value: any) {
    if (this.dataStore && this.dataStore.data) {
      this.dataStore.data[columnName] = value;
    } else {
      throw new Error('formDataStore not initialized, method "this.store.newSpecificWindowData()" not called yet ');
    }
  }

  /*
   * ************************************************************************************************
   * ************************* METHODES SUR LES GRILLES *********************************************
   * ************************************************************************************************
   */
  /**
   * Renvoie la grille ayant cet identifiant
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  getGrid(columnNameDB: string) {
    const gridFound = this.gridViews.find((grid) => grid.data.columnName === columnNameDB);
    return gridFound;
  }
  /**
   * Renvoie toutes les datas des lignes sélectionnées pour une grille donnée
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  getGridSelection(columnNameDB: string) {
    const gridFound = this.getGrid(columnNameDB);
    if (gridFound) {
      return gridFound.GridTabInfinityScrollUiComponent.grid.api.getSelectedRows();
    }
  }

  /**
   * Déselectionne toutes les lignes pour une grille donnée
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  clearGridSelection(columnNameDB: string) {
    const gridFound = this.getGrid(columnNameDB);
    if (gridFound) {
      const gridSelectionsFound = this.dataStore.data['selections'].find(
        (grid) => grid.AD_FormDetail_ID === gridFound.data['AD_FormDetail_ID']
      );
      if (gridSelectionsFound) {
        gridSelectionsFound['selection'] = [];
      }
      gridFound.GridTabInfinityScrollUiComponent.grid.api.deselectAll();
    }
  }

  /**
   * Déselectionne toutes les lignes pour toutes les grilles construites
   */
  clearAllGridsSelection() {
    for (const grid of this.gridViews) {
      this.clearGridSelection(grid.data.columnName);
    }
  }
  /**
   * Sélectionne le/les ligne(s) pour une grille donnée, si dataUUIDs n'est pas renseigné, sélectionne la première ligne par défaut.
   * On peut choisir de simuler un clic avec simulateClick à true.
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param simulateClick indique si on veut le comprotement de clic sur la ligne false par défaut
   * @param dataUUIDs Tableau des Data_UUID des lignes à sélectionnées
   */
  selectLinesByDataUUID(
    columnNameDB: string,
    behaviour: { simulateClick?: boolean; dataUUIDs?: string[]; shouldDeselectAll?: boolean }
  ) {
    let allLines: any[] = [];
    let dataUUIDs = behaviour.dataUUIDs;
    const simulateClick = behaviour.simulateClick;
    const shouldDeselectAll = behaviour.shouldDeselectAll;
    if (shouldDeselectAll) {
      this.clearGridSelection(columnNameDB);
    }
    const gridFound = this.getGrid(columnNameDB);
    if (gridFound) {
      const gridSelectionsFound = this.dataStore.data['selections'].find(
        (gridSelection) => gridSelection.AD_FormDetail_ID === gridFound.data['AD_FormDetail_ID']
      );
      if (gridSelectionsFound) {
        allLines = gridSelectionsFound['selection'];
        if (dataUUIDs === undefined || dataUUIDs === null) {
          const firstNode = gridFound.GridTabInfinityScrollUiComponent.grid.api.getRowAtIndex(0);
          if (firstNode && firstNode.getRowData()) {
            dataUUIDs = [firstNode.getRowData()['Data_UUID']];
          }
        }
        let nodeFound;
        gridFound.GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
          const data = node.getRowData();
          if (data) {
            if (dataUUIDs.find((dataUUID) => dataUUID == data['Data_UUID'])) {
              nodeFound = node;
              node.setSelected(true);
              if (!allLines.find((line) => line['Data_UUID'] == data['Data_UUID'])) {
                allLines.push(data);
              }
              if (simulateClick) {
                // On simule le clic
                gridFound.GridTabInfinityScrollUiComponent.multiSelectEmitter.emit({
                  selected: gridFound.GridTabInfinityScrollUiComponent.grid.api.getSelectedRows().length > 0,
                  data: gridFound.GridTabInfinityScrollUiComponent.grid.api.getSelectedRows(),
                });
              }
            }
          }
        });
        gridSelectionsFound['selection'] = allLines;
      }
    }
  }
  /**
   * Sélectionne toutes les lignes pour une grille donnée
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  selectAllLines(columnNameDB: string) {
    const gridFound = this.getGrid(columnNameDB);
    if (gridFound) {
      const gridSelectionsFound = this.dataStore.data['selections'].find(
        (gridSelection) => gridSelection.AD_FormDetail_ID === gridFound.data['AD_FormDetail_ID']
      );
      if (gridSelectionsFound) {
        let i = 0;
        const allLines: any[] = [];
        gridFound.GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
          node.setSelected(true);
          allLines[i] = node.getRowData();
          i++;
        });
        gridSelectionsFound['selection'] = allLines;
      }
    }
  }
  /**
   * Sélectionne toutes les lignes pour toutes les grilles construites
   */
  selectAllGridLines() {
    for (const grid of this.gridViews) {
      this.selectAllLines(grid.data.columnName);
    }
  }

  /**
   * Ajoute les options qui influence le comportement d'une grille
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param specificGridOptions options définissant le comportement de la grille
   */
  setSpecificGridOption(columnNameDB: string, specificGridOptions: SpecificGridOption) {
    this.specificGridOptions.set(columnNameDB, specificGridOptions);
  }

  /**
   * Supprime les options influençant le comportement d'une grille
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  removeGridDrivenByQuery(columnNameDB: string) {
    this.specificGridOptions.delete(columnNameDB);
  }
  /**
   * récupère les options de la grille
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  getSpecificGridOption(columnNameDB: string): SpecificGridOption {
    return this.specificGridOptions.get(columnNameDB)
      ? this.specificGridOptions.get(columnNameDB)
      : { shouldResetRequest: false };
  }
  /**
   * Ajoute les colonnes spécifiés des colonnes à ignorer à celle déjà ignorer
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param fieldColumnNames nom des colonnes des fields spécifiés dans le formdetailitem qu'on veut ignorer dans la validation et le filtermodel de la request de la grid
   */
  addExcludedFiltersToGrid(columnNameDB: string, ...fieldColumnNames: string[]) {
    const newArray = this.excludeDuplicatesColumnName(
      this.gridExcludedFilterValidation.get(columnNameDB)
        ? this.gridExcludedFilterValidation.get(columnNameDB).concat(fieldColumnNames)
        : fieldColumnNames
    );
    this.gridExcludedFilterValidation.set(columnNameDB, newArray);
  }

  /**
   * Ajoute les colonnes spécifiés des colonnes à ignorer en écrasant les précédentes
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param fieldColumnNames nom des colonnes des fields spécifiés dans le formdetailitem qu'on veut ignorer dans la validation et le filtermodel de la request de la grid
   */
  setExcludedFiltersToGrid(columnNameDB: string, fieldColumnNames: string[]) {
    this.gridExcludedFilterValidation.set(columnNameDB, this.excludeDuplicatesColumnName(fieldColumnNames));
  }

  /**
   * Supprime l'exclusion des colonnes à filtrer pour le tableau spécifié
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  removeExcludedFiltersToGrid(columnNameDB: string) {
    this.gridExcludedFilterValidation.delete(columnNameDB);
  }
  /**
   * Retire les colonnes spécifiés des colonnes à ignorer
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param fieldColumnNames nom des colonnes des fields spécifiés dans le formdetailitem qu'on ne veut plus ignorer dans la validation et le filtermodel de la request de la grid
   */
  unSetExcludedFiltersToGrid(columnNameDB: string, ...fieldColumnNames: string[]) {
    const newArray = this.gridExcludedFilterValidation
      .get(columnNameDB)
      .filter((fieldColumnName) => !fieldColumnNames.includes(fieldColumnName));
    this.gridExcludedFilterValidation.set(columnNameDB, newArray);
  }

  /**
   * Renvoie un tableau sans doublons
   * @param array tableau avec des valeurs simples dupliqués
   */
  excludeDuplicatesColumnName(array: string[]) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) {
          a.splice(j--, 1);
        }
      }
    }
    return a;
  }

  /**
   * Retire de la validation et du filtermodel les colonnes à ignorer
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param query query devant être passé au gridTab
   */
  removeIgnoredFilter(columnNameDB: string, query: SpecificGridQuery) {
    if (this.gridExcludedFilterValidation.has(columnNameDB)) {
      for (const fieldColumnName of this.gridExcludedFilterValidation.get(columnNameDB)) {
        delete query.filters[fieldColumnName];
        delete query.validations[fieldColumnName];
      }
    }
  }

  /**
   * Change le type de selection des grids actives 'single' ou 'multiple'
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param rowSelectionType type de sélection de la grille ( 'single' ou 'multiple' )
   */
  setRowSelection(columnNameDB: string, rowSelectionType: RowSelectionType) {
    const gridviewFound = this.getGrid(columnNameDB);
    if (gridviewFound) {
      gridviewFound.GridTabInfinityScrollUiComponent.rowSelection = rowSelectionType;
    }
  }

  /**
   * permet de récupérer les données d'une ligne
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   * @param dataUUID identifiant de la table (sous forme: nomDeColonneIdentitfiant,valeur. exemple: 'C_Invoice_ID,1000000' )
   */
  getLineFromGrid(columnNameDB: string, dataUUID: string) {
    let nodeFound;
    const gridFound = this.getGrid(columnNameDB);
    if (gridFound) {
      gridFound.GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
        const data = node.getRowData();
        if (data && data['Data_UUID'] == dataUUID) {
          nodeFound = node;
          return;
        }
      });
    }
    return nodeFound;
  }
  /**
   * reset les données à null pour une grille donnée
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  reInitGridData(columnNameDB: string) {
    const gridFound = this.getGrid(columnNameDB);
    if (gridFound) {
      //   gridFound.GridTabInfinityScrollUiComponent.grid.api.hideOverlay();
      gridFound.GridTabInfinityScrollUiComponent.tableHeight = '140px';
      // gridFound.GridTabInfinityScrollUiComponent.grid.api.setServerSideDatasource(null);
      //   gridFound.GridTabInfinityScrollUiComponent.grid.api.showNoRowsOverlay();
      if (gridFound.GridTabInfinityScrollUiComponent.pinnedBottomRow) {
        gridFound.GridTabInfinityScrollUiComponent.grid.api.setPinnedRowData();
      }
    }
  }
  /**
   * reset les données à null pour toutes les grilles construites
   */
  reInitAllGridsData() {
    for (const grid of this.gridViews) {
      this.reInitGridData(grid.data.columnName);
    }
  }
  /**
   * permet de zoom sur les lignes choisies si celà est possible
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  zoomOnLineSelected(columnNameDB?: string) {
    let gridFound = this.getGrid(columnNameDB);
    let selections = [];
    if (!columnNameDB) {
      gridFound = this.gridViews[0];
    }
    if (gridFound) {
      selections = gridFound.GridTabInfinityScrollUiComponent.grid.api.getSelectedRows();
    }
    if (selections && selections.length > 0) {
      if (gridFound && gridFound.data && gridFound.data.columnsDetails) {
        let identifiant = null;
        if (selections[0]['Data_UUID']) {
          identifiant = selections[0]['Data_UUID'].split(',')[0];
        }
        if (!identifiant) {
          identifiant = gridFound.data.columnsDetails.keys[0];
        }
        const tableName = identifiant.replace('_ID', '');
        if (selections.length > 0) {
          const selection = selections[0];
          this.subscriptions.push(
            this.uiCreator.zoomAcross(tableName, identifiant, selection[identifiant]).subscribe((dataWs) => {
              if (dataWs && dataWs.length > 0) {
                this.subscriptions.push(
                  this.uiCreator.getIupicsMenus().subscribe((menus) => {
                    const menuFound = menus.find(
                      (menu) =>
                        menu.actionID === parseInt(dataWs[0].Window_ID, 10) && menu.menuType === IupicsMenuType.WINDOW
                    );
                    if (menuFound) {
                      dataWs[0].Window_ID = menuFound.actionID;
                      this.zoomInfo = {
                        // tableName: this.data.detailZoom.tableName,
                        windowId: dataWs[0].Window_ID,
                        dataUUID: dataWs[dataWs.length - 1]['Record_ID'],
                        record_id: selection[identifiant],
                        children: dataWs.length > 1 ? dataWs.splice(0, dataWs.length - 1) : null,
                      };
                      if (selections.length > 1) {
                        let values = [];
                        for (const currentSelection of selections) {
                          values.push(currentSelection[identifiant] + '');
                        }
                        values = values.filter((a, b) => values.indexOf(a) == b);
                        const dataGridRequest: CompiereDataGridRequestJSON = {
                          filterModel: {},
                          sortModel: [],
                          rowGroupCols: [],
                          valueCols: [],
                        };
                        dataGridRequest.filterModel[identifiant] = {
                          operators: [OperatorFilterType.EQUALS],
                          values: [values],
                          filterType: CompiereDataGridFilterType.NUMBER,
                        };
                        this.urlParamsService.getUrlParams().dataGridRequest = dataGridRequest;
                      } else {
                        this.urlParamsService.getUrlParams().dataGridRequest = null;
                      }
                      this.workspaceService.openTargetSearchEmt.emit({
                        zoomInfo: this.zoomInfo,
                        cat: { id: parseInt(dataWs[0].Window_ID, 10) },
                        source: {
                          id: selection[identifiant] !== -1 ? dataWs[0].Record_ID : 'newRecord',
                        }, // dataWs[0].Record_ID
                      });
                    }
                  })
                );
              }
            })
          );
        }
      }
    } else {
      throw new Error(this.translateService.instant('infodialog.dialogs.noLineSelected.message'));
    }
  }
  /*
   * ************************************************************************************************
   * ************************* METHODES SUR LE PARENT *********************************************
   * ************************************************************************************************
   */
  /**
   *Set la ligne sélectionnée à l'autocomplete qui ouvre cette form
   * @param columnNameDB columnNameDB spécifié dans le formdetailitem contenant le formdetailChildId de la grille
   */
  setSelectedRowOnAutocomplete(columnNameDB?: string) {
    // permet d'annuler le refresh de l'editViewParent à la fermeture de la fenetre
    if (<EditViewUiComponent>this.parentComponent) {
      this.parentComponent.isProcessLaunch = false;
    }
    let gridFound = this.getGrid(columnNameDB);
    let selections = [];
    if (!columnNameDB) {
      gridFound = this.gridViews[0];
    }
    if (gridFound) {
      selections = gridFound.GridTabInfinityScrollUiComponent.grid.api.getSelectedRows();
    }
    if (selections && selections.length > 0) {
      if (gridFound && gridFound.data && gridFound.data.columnsDetails) {
        let identifiant = null;

        // Update Context Data
        this.addContextDataToParentStore(
          selections[0],
          this.connectorService.getConfig().getConstant('ContextPrefixSearchWindow')
        );
        if (selections[0]['Data_UUID']) {
          identifiant = selections[0]['Data_UUID'].split(',')[0];
        }
        if (!identifiant) {
          identifiant = gridFound.data.columnsDetails.keys[0];
        }
        const sourceFieldName = this.sourceComponent.data.columnName;
        if (sourceFieldName) {
          const event = { data: { Data_UUID: sourceFieldName + ',' + selections[0][identifiant] } };
          (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
        }
      }
    }
  }
  /**
   *Set la nouvelle value au datacontainer du parent
   * @param columnNameDB columnNameDB du field à modifier
   * @param value nouvelle valeur ( remarque: objet de type {id,displayValue} dans le cas d'un autocomplete)
   */
  updateParentStore(columnNameDB: string, value: any) {
    if (
      this.parentComponent &&
      this.parentComponent.editTabs &&
      this.parentComponent.editTabs[0] &&
      (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
    ) {
      (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[columnNameDB] = value;
      (<EditViewUiComponent>this.parentComponent).editTabs[0].updateData(
        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
      );
    }
  }
  /**
   *Set la nouvelle value au datacontainer du parent
   * @param columnNameDB columnNameDB du field à modifier
   * @param value nouvelle valeur ( remarque: objet de type {id,displayValue} dans le cas d'un autocomplete)
   */
  getParentStore(): DataStore {
    if (
      this.parentComponent &&
      this.parentComponent.editTabs &&
      this.parentComponent.editTabs[0] &&
      (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
    ) {
      return (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored;
    }
  }
  addContextDataToParentStore(data: {}, ctxPrefix = '') {
    if (
      this.parentComponent &&
      this.parentComponent.editTabs &&
      this.parentComponent.editTabs[0] &&
      (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
    ) {
      (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.addContextData(data, ctxPrefix);
    }
  }

  /**
   * récupération du contexte complet du composant
   * @param dataStore nouveau datastore à prendre en compte
   * @param dataStoreParent datastore parent à merge
   */
  getCurrentContext(dataStore?: DataStore, dataStoreParent?: DataStore) {
    let specificParent;
    let editViewParent;
    if (this.parentComponent && this.parentComponent.editTabs && this.parentComponent.editTabs[0]) {
      editViewParent = this.parentComponent;
    } else if (this.parentComponent instanceof SpecificWindowUiComponent) {
      specificParent = this.parentComponent;
    }
    /*merge avec le contexte du parent */
    if (dataStoreParent) {
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore.data : this.dataStore.data),
        cloneDeep(dataStoreParent ? dataStoreParent.data : this.dataStore.data)
      );
    }
    if (specificParent) {
      const specificParentContext = specificParent.getCurrentContext();
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore : this.dataStore.data),
        specificParentContext
      );
    } else {
      return EditViewUtils.getCurrentContext(
        editViewParent,
        dataStore ? dataStore : this.dataStore,
        this.connectorService.getIupicsUserContext()
      );
    }
  }
  /**
   * Permet de fermer la modal contenant la form si il y'en a une
   */
  closeForm() {
    this.updateLoading(false);
    if (this.closeModalEmitter) {
      this.isLoading = false;
      this.closeModalEmitter.emit();
    }
  }
  changeButtonContainerPadding(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.shouldAddPadding = true;
    if (this.specificContainer) {
      if (
        this.specificContainer.nativeElement.offsetParent &&
        this.specificContainer.nativeElement.offsetParent.offsetParent
      ) {
        this.shouldAddPadding =
          this.specificContainer.nativeElement.offsetHeight <
          this.specificContainer.nativeElement.offsetParent.offsetParent.offsetHeight * 0.6;
      } else {
        this.shouldAddPadding =
          this.specificContainer.nativeElement.offsetHeight <
          this.specificContainer.nativeElement.offsetParent.offsetHeight * 0.6;
      }
    }
  }

  /**
   * Applique un filtre à la grid affichée
   * @param gridView
   * @param filter
   */
  protected applyFilter(gridView: GridViewUiComponent, filter: CompiereDataGridRequestJSON) {
    const appliedItems = ApizGridUtils.appliedItemsFromCompiereRequest(filter, gridView.api, gridView.columnApi);
    gridView.api.updateAppliedItems(appliedItems, undefined);
    const query = this.createQuery(gridView.data.tableName);
    const currentQuery = this.adaptQueryForGridView(query, gridView, null);
    gridView.refreshGrid(currentQuery);
  }

  protected afterNewSpecificWindowData() {}
}
export class ActiveGridData {
  vcrName: string;
  tableName: string;
}
export enum RowSelectionType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}
export interface SpecificGridOption {
  shouldResetRequest?: boolean;
  isFromInputLaunch?: boolean;
}
export interface SpecificGridQuery {
  filters: {};
  validations: {};
  fields: {};
  specificGridOptions?: SpecificGridOption;
}
