<ul class="smartbutton p-grid">
  @for (button of displayed().visible; track button.key) {
    <iu-smart-button-ui [data]="button" />
  }
</ul>

@if (displayed()?.list?.length > 0) {
  <div class="tabManage">
    <ul class="smartbuttonMore">
      <li>
        <a href="#" class="icon-more-title" (click)="$event.preventDefault()">
          <i class="icon-menu-more" aria-hidden="true"></i>
        </a>
        <ul>
          @for (button of displayed().list; track button.key) {
            <iu-smart-button-ui [data]="button" />
          }
        </ul>
      </li>
    </ul>
  </div>
}
