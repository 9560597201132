import { EventEmitter, Injectable } from '@angular/core';
import { DataConflict } from '@iupics-manager/models/data-conflict';
import { DataRequest, FilterModelEntry } from '@iupics/apiz-grid';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';

export interface MessageDelete {
  type: string;
  message: string;
}

export interface CompiereDeleteDataJson {
  messages: MessageDelete[];
  passThrough: boolean;
  commands?: any;
  targetWinCmd: number;
  newConfirmedFieldValue?: any;
  changedFields?: any;
  changedDropDowns?: any;
  changedContext?: any;
  changedComponents?: any;
  rowData?: any;
  queryComponents?: any;
  updateCurrentRow: boolean;
  updateWindowVO: boolean;
  newWindowVO?: any;
  trxInfo?: any;
  links: any[];
  processID: number;
  formId: number;
  refreshAll: boolean;
  refreshChildRows: boolean;
}

export interface CompiereDataJSON {
  data: any[][];
  fields: string[];
  tabId: number;
}

export interface DataStoreRequest {
  windowId: number;
  record_id?: string;
  parent_constraint?: string;
  compiereRequest: CompiereDataGridRequestJSON;
  isUserFilter?: boolean;
}

export interface CompiereDataGridRequestJSON extends Partial<DataRequest> {
  ad_language?: string;
  windowType?: CompiereDataGridType;
  entityId?: number;
  headerCols?: CompiereDataGridGroupModel[];
  validation?: string;
  query?: string;
  parent_formDetail_id?: number;
  label?: string;
  isDefault?: boolean;
  widgetID?: number;
  searchEntityType?: any;
  searchKeyColumn?: string;
  tableName?: string;
  windowCtx?: any;
  exportSelections?: { [key: string]: any }[];
}

export interface CompiereDataGridResponseJSON {
  data: {}[];
  data_UUID: string[];
  displayData: {};
  lastRow: number;
  secondaryColumnFields: any[];
  dataStoreKeys?: {};
  compiereRequest?: CompiereDataGridRequestJSON;
}
export interface CompiereDataJSON2 {
  data: {}[];
  data_UUID: string[];
  displayData: {};
  lastRow: number;
  secondaryColumnFields: any[];
  tab_id?: number;
}

export interface CompiereDataGridFilterModel extends FilterModelEntry {
  configs?: any[];
}

export enum CompiereDataGridFilterType {
  NUMBER = 'number',
  TEXT = 'text',
  DATE = 'date',
  TIME = 'time',
  NONE = 'none',
  SET = 'set',
}

export enum CompiereDataGridSortModelType {
  ASC = 'asc',
  DESC = 'desc',
}

export interface CompiereDataGridSortModel {
  colId: string;
  sort: CompiereDataGridSortModelType;
}

export interface CompiereDataGridGroupModel {
  aggFunc?: string;
  displayName: string;
  field: string;
  id?: string;
}

export enum CompiereDataGridType {
  WINDOW = 'W',
  FORM = 'F',
  SEARCH_WINDOW = 'S',
  TABLE = 'T',
}
export enum CompiereDataFieldType {
  FIELD = 'F',
  FORM_ITEM = 'I',
  PROCESS_PARA = 'P',
  COLUMN_INFO = 'C',
}

export enum DataGridExportFormat {
  CSV = 'csv',
  EXCEL = 'xlsx',
  // HTML = 'html',
  PDF = 'pdf',
}

export enum DataStoreState {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export enum DataStoreStatus {
  SYNC = 'sync',
  NOTSYNC = 'not sync',
  NEWRECORD = 'new record',
}

export interface DataStoreKey {
  parentId: string;
  windowId: number;
  tabId: number;
  recordId: string;
}

export class DataStoreSet {
  data: { [key: string]: DataStore };
  nextSeqNo: number;
  orderKeys: string[];
  lastRowRemote: number;

  constructor() {
    this.nextSeqNo = 0;
    this.data = {};
    this.orderKeys = [];
    this.lastRowRemote = -1;
  }
}
export enum DataStoreName {
  CURRENT = 'current',
  OLD = 'old',
  REMOTE = 'remote',
}
export enum CalloutStatus {
  WAITING = 'waiting',
  LOADING = 'loading',
}
@Injectable({
  providedIn: 'root',
})
export class DataStore {
  key: DataStoreKey;
  state: DataStoreState;
  status: DataStoreStatus;
  calloutStackEmptied: EventEmitter<boolean> = new EventEmitter();
  dataChange: EventEmitter<any>;
  dataConflict: EventEmitter<DataConflict>;
  initContextField: EventEmitter<any>;
  data: any;
  seqNo: number;
  isCopied = false;
  calloutStates: Map<string, CalloutStatus> = new Map<string, CalloutStatus>();
  currentContext: any;
  constructor(windowType?: IupicsMenuType) {
    this.state = DataStoreState.VISIBLE;
    this.status = DataStoreStatus.SYNC;
    this.dataChange = new EventEmitter();
    this.dataConflict = new EventEmitter();
    this.initContextField = new EventEmitter();
    this.data = {
      windowType: windowType ?? IupicsMenuType.WINDOW,
    };
  }

  queueCallout(calloutName: string) {
    if (!['apiz_dataResult', 'apiz_ctxChanged'].includes(calloutName)) {
      this.calloutStates.set(calloutName, CalloutStatus.WAITING);
    }
  }

  startCallout(calloutName: string) {
    if (!['apiz_dataResult', 'apiz_ctxChanged'].includes(calloutName)) {
      this.calloutStates.set(calloutName, CalloutStatus.LOADING);
    }
  }

  endCallout(calloutName: string) {
    if (!['apiz_dataResult', 'apiz_ctxChanged'].includes(calloutName)) {
      this.calloutStates.delete(calloutName);
      if (this.calloutStates.size === 0) {
        this.calloutStackEmptied.emit(true);
      }
    }
  }

  addContextData(data: {}, ctxPrefix = '') {
    if (ctxPrefix) {
      const dataKeys = Object.keys(this.data);
      for (const key of dataKeys) {
        if (key.startsWith(ctxPrefix)) {
          delete this.data[key];
          if (this.currentContext) delete this.currentContext[key];
        }
      }
    }

    const keys = Object.keys(data);
    for (const key of keys) {
      this.data[ctxPrefix + key] = data[key];
      if (this.currentContext) this.currentContext[ctxPrefix + key] = data[key];
    }
  }
}
