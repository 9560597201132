import { EventEmitter, Injectable } from '@angular/core';
import { URLParams } from '@web-desktop/models/url-params';

@Injectable({
  providedIn: 'root',
})
export class UrlParamsService {
  #urlParams: URLParams;

  handleURLEmt = new EventEmitter<void>();

  setUrlParams(urlParams: URLParams) {
    this.#urlParams = urlParams;
  }

  getUrlParams(): URLParams {
    return this.#urlParams;
  }
}
