import { Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { Month } from '@iupics-components/models/date-utils';
import {
  GanttCalendar,
  GanttData,
  GanttDragConfig,
  GanttDragMode,
  GanttGridColumn,
  GanttZoomLevel,
  LinkType,
} from '@iupics-components/models/gantt.model';
import GanttComponent from '@iupics-components/standard/grid/gantt/gantt.component';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { TranslateModule } from '@ngx-translate/core';
import { GanttStatic, gantt } from 'dhtmlx-gantt';
import { cloneDeep, merge } from 'lodash';
import { SidebarModule } from 'primeng/sidebar';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectArrayFilterPipe } from '../../../../iupics-util/pipes/object-array-filter/object-array-filter.pipe';
import InputSwitchUiComponent from '../../../standard/fields/input-switch-ui/input-switch-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import {
  CALENDAR_ID,
  ProductionGanttTask,
  convertToGanttData,
  formatDate,
  ganttMomentFormat,
  getOFRequest,
  getOperationRequest,
} from './planning-window-utils';

@Component({
  selector: 'iu-planning-window-ui',
  templateUrl: './planning-window-ui.component.html',
  styleUrls: ['./planning-window-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [GanttComponent, SidebarModule, InputSwitchUiComponent, TranslateModule, ObjectArrayFilterPipe],
})
export default class PlanningWindowUiComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {
  // readonly momentDateFormat: string = 'YYYY-MM-DD HH:mm:ss';
  readonly ganttDateFormat: string = '%Y-%m-%d %H:%i:%s';

  ganttData$: Observable<GanttData>;
  gridWidth = 750;
  dragConfiguration: GanttDragConfig = {
    drag_progress: false,
  };

  private loadedTasks: ProductionGanttTask[] = [];

  private productions: any[] = [];
  private operations: any[] = [];
  lastRow: boolean;
  maximumPage = 1;
  actualPage = 1;
  paginationStep = 50;
  paginationEnable = true;

  public onBeforeTaskDrag = this._onBeforeTaskDrag.bind(this);
  public onAfterTaskDrag = this._onAfterTaskDrag.bind(this);
  public onOFOpened = this._onOFOpened.bind(this);
  public buildCalendar = this._buildCalendar;

  _columns: GanttGridColumn[] = [
    {
      name: '_empty_',
      label: '',
      width: 40,
      tree: true,
      show: true,
    },
    {
      name: 'reference',
      label: this.translateService.instant('planning-window.columns.reference'),
      width: 90,
      show: true,
    },
    {
      name: 'description',
      label: this.translateService.instant('planning-window.columns.description'),
      width: 150,
      show: false,
    },
    {
      name: 'product',
      label: this.translateService.instant('planning-window.columns.product'),
      template: (task: ProductionGanttTask) => {
        return `<div>${task.product.displayValue}</div>`;
      },
      width: 140,
      show: true,
    },
    {
      name: 'qty_to_produce',
      label: this.translateService.instant('planning-window.columns.qtyToProduce'),
      align: 'center',
      width: 60,
      show: true,
    },
    {
      name: 'start_date',
      label: this.translateService.instant('planning-window.columns.start_date'),
      align: 'center',
      width: 110,
      show: false,
    },
    {
      name: 'end_date',
      label: this.translateService.instant('planning-window.columns.end_date'),
      align: 'center',
      width: 110,
      show: false,
    },
    {
      name: 'duration',
      label: this.translateService.instant('planning-window.columns.duration'),
      align: 'center',
      width: 50,
      show: false,
    },
  ];

  columns: GanttGridColumn[] = cloneDeep(this._columns.filter((c) => c.show));

  //#region universal-filter-related
  uf_columns: any[] = [];
  setFilterEmitter: EventEmitter<CompiereDataGridRequestJSON> = new EventEmitter();
  //#endregion

  taskText = (start: Date, end: Date, task: ProductionGanttTask) => {
    return task.description;
  };

  taskClass = (start: Date, end: Date, task: ProductionGanttTask) => {
    return task.is_sub_contracting
      ? 'gantt-task-subcontract'
      : task.type === 'OF'
        ? 'gantt-task-OF'
        : task.type === 'OP'
          ? 'gantt-task-OP'
          : '';
  };

  gridRowClass = (start: Date, end: Date, task: ProductionGanttTask) => {
    return task.type === 'OF' ? 'gantt-row-OF' : task.type === 'OP' ? 'gantt-row-OP' : '';
  };

  //#region template related
  readonly GanttZoomLevel = GanttZoomLevel;
  //#endregion

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initGanttData$(this.actualPage, true);
  }

  private initGanttData$(pageNumber = 0, reset = false, filterToApply?: CompiereDataGridRequestJSON): void {
    if (reset) {
      this.productions = [];
      this.operations = [];
    }
    // formdetail_parent : this.parentFormID
    // formdetail_id : this.fields.find(f=> f.component === 'GridViewUiComponent' && f.name === 'planning-window-table-fabrication').formDetailId
    const request = getOFRequest(
      this.fields.find((f) => f.component === 'GridViewUiComponent' && f.name === 'planning-window-table-fabrication')
        .formDetailId,
      this.parentFormID,
      pageNumber,
      this.paginationStep
    );
    if (filterToApply) {
      request.compiereRequest.filterModel = merge(request.compiereRequest.filterModel, filterToApply.filterModel);
      request.compiereRequest.sortModel.push(
        ...filterToApply.sortModel.filter(
          (sm) => request.compiereRequest.sortModel.find((_sm) => _sm.colId === sm.colId) === undefined
        )
      );
    }
    this.ganttData$ = this.store.getDataGrid(request).pipe(
      map((productions) => {
        this.setFilterEmitter.emit(request.compiereRequest);
        this.productions.push(...productions.data);
        this.lastRow = productions.lastRow !== -1;
        const ganttData: GanttData = convertToGanttData(
          {
            data: this.productions.slice(
              (this.actualPage - 1) * this.paginationStep,
              this.actualPage * this.paginationStep
            ),
            id_key: 'Z_Production_ID',
          },
          { data: this.operations, id_key: 'Z_Production_Operation_ID' }
        );
        return ganttData;
      })
    );
  }

  updateDisplayColumns(columnName: string, value: string) {
    this._columns[this._columns.findIndex((c) => c.name === columnName)].show = value === 'Y';
    this.columns = cloneDeep(this._columns.filter((c) => c.show));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private _onBeforeTaskDrag(task: ProductionGanttTask, _gantt: GanttStatic, mode: GanttDragMode, e: Event): boolean {
    switch (mode) {
      case GanttDragMode.RESIZE:
        return task.type !== 'OF';
      default:
        return true;
    }
  }

  private _onAfterTaskDrag(
    task: ProductionGanttTask,
    _gantt: GanttStatic,
    mode: GanttDragMode,
    e: Event
  ): ProductionGanttTask[] {
    switch (mode) {
      case GanttDragMode.RESIZE:
        // update de la tâche
        if (task.duration < task.static_duration) {
          if ((e.target as HTMLElement).classList.contains('task_start_date')) {
            // end_date ne bouge pas
            task.start_date = DateUtils.subtract(task.end_date, task.static_duration, 'day', ganttMomentFormat);
          } else if ((e.target as HTMLElement).classList.contains('task_end_date')) {
            // start_date ne bouge pas
            task.end_date = DateUtils.add(task.start_date, task.static_duration, 'day', ganttMomentFormat);
          }
          task.duration = task.static_duration;
        }
        break;
      case GanttDragMode.MOVE:
        if (task.start_date.valueOf() === task.end_date.valueOf()) {
          if (task.static_duration > 1) {
            task.end_date = DateUtils.add(task.start_date, task.static_duration, 'day', ganttMomentFormat);
          } else if (task.static_duration > 0) {
            task.end_date = DateUtils.add(task.start_date, 1, 'day', ganttMomentFormat);
          }
        }
        break;
    }

    if (!task.parent) {
      return [task];
    }

    // Préparation des infos pour l'update du parent
    const parent: ProductionGanttTask = _gantt.getTask(task.parent);
    const children: ProductionGanttTask[] = _gantt.getChildren(task.parent).map((_id) => _gantt.getTask(_id));
    const edited_task_i = children.findIndex((c) => c.id === task.id);
    children[edited_task_i].start_date = task.start_date;
    children[edited_task_i].end_date = task.end_date;
    children[edited_task_i].duration = task.duration;

    // update du parent de la tâche
    const start_date = new Date(Math.min(...children.map((t) => (t.start_date as Date)?.valueOf()).filter((i) => i)));
    const end_date = new Date(Math.max(...children.map((t) => (t.end_date as Date)?.valueOf()).filter((i) => i)));
    const duration = DateUtils.diff(end_date, start_date, 'days');
    if (duration < parent.static_duration) {
      if ((e.target as HTMLElement).classList.contains('task_start_date')) {
        // end_date ne bouge pas
        parent.start_date = DateUtils.subtract(parent.end_date, parent.static_duration, 'day', ganttMomentFormat);
        parent.end_date = end_date;
      } else if ((e.target as HTMLElement).classList.contains('task_end_date')) {
        // start_date ne bouge pas
        parent.end_date = DateUtils.subtract(parent.start_date, parent.static_duration, 'day', ganttMomentFormat);
        parent.start_date = start_date;
      }
      parent.duration = parent.static_duration;
    } else {
      parent.start_date = start_date;
      parent.end_date = end_date;
      parent.duration = duration;
    }
    return [task, parent];
  }

  private _onOFOpened(task: ProductionGanttTask, ganttComponent: GanttComponent) {
    if (!this.loadedTasks.find((t) => t.id === task.id)) {
      this.store
        .getDataGrid(
          getOperationRequest(this.connectorService.getIupicsDefaultLanguage().iso_code, [task.id as number])
        )
        .subscribe((operations) => {
          this.operations.push(...operations.data);
          this.loadedTasks.push(task);
          const data = [];
          for (let i = 0; i < operations.data.length; i++) {
            const o: any = operations.data[i];
            const o_start_date = o.StartDate ? formatDate(o.StartDate) : task.start_date;
            const o_end_date = o.EndDate ? formatDate(o.EndDate) : task.end_date;
            const taskToAdd: ProductionGanttTask = {
              id: parseFloat(task.id + '.' + o[operations.data_UUID[0]]),
              reference: o.Value,
              description: o.Name,
              product: { id: -1, displayValue: '' },
              qty_to_produce: o.QtyToProduce,
              start_date: o_start_date,
              end_date: o_end_date,
              duration: o.ProductionTime === 0 ? DateUtils.diff(o_end_date, o_start_date, 'days') : o.ProductionTime,
              type: 'OP',
              parent: task.id,
              $open: false,
              static_duration:
                o.ProductionTime === 0 ? DateUtils.diff(o_end_date, o_start_date, 'days') : o.ProductionTime,
              is_sub_contracting: o.IsSubContracting === 'Y',
              calendar_id: CALENDAR_ID,
            };
            ganttComponent.gantt.addTask(taskToAdd);
            data.push(taskToAdd);
            if (i > 0) {
              ganttComponent.gantt.addLink({
                id: data[data.length - 1].id,
                source: data[data.length - 2].id + '',
                target: data[data.length - 1].id + '',
                type: LinkType.END_TO_START,
              });
            }
          }
        });
    }
  }

  private _buildCalendar(_gantt: GanttStatic): void {
    gantt.addCalendar({
      id: CALENDAR_ID,
      worktime: {
        days: [0, 1, 1, 1, 1, 1, 0],
        hours: [8, 17],
      },
    } as GanttCalendar);

    gantt.setWorkTime({ date: new Date(2020, Month.JULY, 8), hours: false });
  }

  onPageChange(e: { gantt: GanttComponent; type: 'next' | 'prev' }) {
    if (e.type === 'next') {
      this.actualPage++;
      if (this.actualPage > this.maximumPage) {
        if (this.lastRow) {
          this.actualPage = this.maximumPage;
          e.gantt.onPageChange();
        } else {
          this.initGanttData$(this.actualPage);
          this.maximumPage = this.actualPage;
          e.gantt.onPageChange(this.ganttData$);
        }
      } else {
        this.ganttData$ = of(
          convertToGanttData(
            {
              data: this.productions.slice(
                (this.actualPage - 1) * this.paginationStep,
                this.actualPage * this.paginationStep
              ),
              id_key: 'Z_Production_ID',
            },
            { data: this.operations, id_key: 'Z_Production_Operation_ID' }
          )
        );
        e.gantt.onPageChange(this.ganttData$);
      }
    } else if (e.type === 'prev') {
      this.actualPage = --this.actualPage < 1 ? 1 : this.actualPage;
      this.ganttData$ = of(
        convertToGanttData(
          {
            data: this.productions.slice(
              (this.actualPage - 1) * this.paginationStep,
              this.actualPage * this.paginationStep
            ),
            id_key: 'Z_Production_ID',
          },
          { data: this.operations, id_key: 'Z_Production_Operation_ID' }
        )
      );
      e.gantt.onPageChange(this.ganttData$);
    }
  }

  onFilterChange(e: { filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean; gantt: GanttComponent }) {
    if (!e.isNotFromUF) {
      this.actualPage = 1;
      this.initGanttData$(this.actualPage, true, e.filterToApply);
      this.maximumPage = this.actualPage;
      e.gantt.onPageChange(this.ganttData$);
    }
  }
}
