import {NgClass, NgStyle, NgTemplateOutlet} from '@angular/common';
import {Component, ViewEncapsulation,} from '@angular/core';
import {Accordion } from "primeng/accordion";

@Component({
    selector: 'iu-prime-accordion',
    templateUrl: './prime-accordion.component.html',
    providers: [{provide: Accordion , useExisting: PrimeAccordionComponent}],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgTemplateOutlet,
    ],
})
export class PrimeAccordionComponent extends Accordion {
    onTabHomeKey(event) {
    }

    onTabEndKey(event) {
    }
}
