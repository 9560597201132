import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@iupics-util/tools/date.utils';

@Pipe({
  name: 'toDate',
  standalone: true,
})
export class ToDatePipe implements PipeTransform {
  transform(value: any, needTime: boolean = false): Date {
    return DateUtils.toDateSpecific(value, needTime);
  }
}
