<div class="iupics-calendar-view-box">
  @if (!isCollapsed) {
  <iu-calendar-toolbar-ui
    (next)="showNextPeriod()"
    (prev)="showPrevPeriod()"
    (today)="showToday()"
    (changeView)="changeView($event)"
    [title]="title" />
  }
  <div #fc class="iupics-calendar-view-content"></div>
</div>
