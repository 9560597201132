import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  forwardRef,
  inject,
} from '@angular/core';
import { CompiereDataFieldType, DataStore } from '@compiere-ws/models/compiere-data-json';
import {
  ValuePreference,
  ValuePreferencesService,
} from '@compiere-ws/services/value-preference/value-preference.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import InputSwitchUiComponent from '../fields/input-switch-ui/input-switch-ui.component';
import InputTextUiComponent from '../fields/input-text-ui/input-text-ui.component';
@Component({
  selector: 'iu-value-preference-panel',
  templateUrl: './value-preference-panel.component.html',
  styleUrls: ['./value-preference-panel.component.scss'],
  standalone: true,
  imports: [NgClass, InputTextUiComponent, NgIf, TranslateModule, forwardRef(() => InputSwitchUiComponent)],
})
export default class ValuePreferencePanelComponent implements OnInit, AfterViewInit {
  #valuePreferenceService = inject(ValuePreferencesService);
  #connectorService = inject(SecurityManagerService);
  #translateService = inject(TranslateService);
  cd = inject(ChangeDetectorRef);
  #zone = inject(NgZone);
  el = inject(ElementRef);
  renderer = inject(Renderer2);

  attribute: string;
  attributeDisplayValue: string;
  value: string;
  displayValue: string;
  adClient = 'N';
  adClientReadOnly = false;
  adOrg = 'N';
  adOrgReadOnly = false;
  adUser = 'Y';
  adUserReadOnly = false;
  adWindow = 'Y';
  adWindowValue: number;
  fieldType: CompiereDataFieldType;
  prefix = 'P';
  isDeletable = false;
  @Input()
  fieldValue: any;
  @Input()
  data: any;
  @Input()
  dataStored: DataStore;
  @Input()
  sourceComponent: any;
  @Input()
  enableSize: number;
  @Output()
  closeEmitter: EventEmitter<any> = new EventEmitter<any>();
  description = '';
  documentClickListener: () => void;
  @Input()
  overlayPanel: OverlayPanel;

  ngAfterViewInit(): void {
    this.bindDocumentClickListener();
  }

  ngOnInit(): void {
    const preferenceType = this.#connectorService.getIupicsUserAccount().current_role.preferenceType;
    this.adClient = 'Y';
    this.adClientReadOnly = true;
    if (preferenceType != 'C') {
      this.adOrg = 'Y';
      this.adOrgReadOnly = true;
    }
    if (preferenceType != 'C' && preferenceType != 'O') {
      this.adUser = 'Y';
      this.adUserReadOnly = true;
    }
    this.value = this.fieldValue
      ? this.fieldValue.id != null && this.fieldValue.id != undefined
        ? this.fieldValue.id + ''
        : this.fieldValue
      : this.fieldValue;
    this.displayValue = this.fieldValue
      ? this.fieldValue.displayValue
        ? this.fieldValue.displayValue + ''
        : this.fieldValue
      : this.fieldValue;
    if (this.displayValue && this.sourceComponent.isDateField) {
      // TODO: check if this really useful
      DateUtils.setLocale(this.#connectorService.getIupicsDefaultLanguage().iso_code);
      this.displayValue = DateUtils.formatLocaleStr(this.displayValue);
      this.value = DateUtils.valueOf(this.value) + '';
    }
    this.attribute = this.data.columnName;
    this.attributeDisplayValue = this.data.label;
    this.adWindowValue = this.dataStored.key.windowId;
    this.fieldType = this.sourceComponent ? this.sourceComponent.fieldType : 'F';
    switch (this.fieldType) {
      case CompiereDataFieldType.FORM_ITEM:
        this.prefix = 'PF';
        break;
      case CompiereDataFieldType.PROCESS_PARA:
        this.prefix = 'PP';
        break;

      default:
        this.prefix = 'P';
        break;
    }
    this.updateDescription();
    this.checkDeleteAvailable();
  }
  updateDescription() {
    if (this.adClient === 'Y' && this.adOrg === 'Y') {
      this.description = this.#translateService.instant('valuePreference.thisOrg');
    } else {
      this.description = this.#translateService.instant('valuePreference.allOrg');
    }
    if (this.adUser === 'Y') {
      this.description += this.#translateService.instant('valuePreference.thisUser');
    } else {
      this.description += this.#translateService.instant('valuePreference.allUser');
    }
    if (this.adWindow === 'Y') {
      this.description += this.#translateService.instant('valuePreference.thisWindow');
    } else {
      this.description += this.#translateService.instant('valuePreference.allWindow');
    }
    this.description += '.';
  }
  delete(event: any) {
    if (event) {
      event.stopPropagation();
    }
    const valuepref = this.createValuePref();
    this.#valuePreferenceService.deleteValuePreference(valuepref).subscribe({
      next: (response) => {
        if (response) {
          const id = this.getUsedId();
          const ctx = this.#connectorService.getIupicsUserContext();
          const entryKey = `${this.prefix}${id > 0 ? id : ''}|${valuepref.attribute}`;
          delete ctx[entryKey];
          this.#connectorService.setIupicsUserContext(ctx);
        }
        this.hide(event);
      },
      error: () => {
        this.hide(event);
      },
    });
  }
  save(event) {
    if (event) {
      event.stopPropagation();
    }
    const valuepref = this.createValuePref();
    this.#valuePreferenceService.saveValuePreference(valuepref).subscribe({
      next: (response) => {
        if (response) {
          const id = this.getUsedId();
          const ctx = this.#connectorService.getIupicsUserContext();
          const entryKey = `${this.prefix}${id > 0 ? id : ''}|${valuepref.attribute}`;
          ctx[entryKey] = valuepref.value;
          this.#connectorService.setIupicsUserContext(ctx);
        }
        this.hide(event);
      },
      error: () => {
        this.hide(event);
      },
    });
  }
  cancel(event: any) {
    if (event) {
      event.stopPropagation();
    }
    this.hide(event);
  }
  createValuePref() {
    const ctx = this.#connectorService.getIupicsUserContext();
    const ctxAdClient = isNaN(parseInt(ctx['#AD_Client_ID'])) ? 0 : parseInt(ctx['#AD_Client_ID']);
    const ctxAdOrg = isNaN(parseInt(ctx['#AD_Org_ID'])) ? 0 : parseInt(ctx['#AD_Org_ID']);
    const ctxAdUser = isNaN(parseInt(ctx['##AD_User_ID'])) ? 0 : parseInt(ctx['##AD_User_ID']);
    return {
      ad_Client_ID: this.adClient === 'Y' ? ctxAdClient : 0,
      ad_Org_ID: this.adOrg === 'Y' ? ctxAdOrg : 0,
      ad_User_ID: this.adUser === 'Y' ? ctxAdUser : 0,
      ad_Window_ID: this.adWindow === 'Y' && this.fieldType == CompiereDataFieldType.FIELD ? this.adWindowValue : 0,
      ad_Process_ID:
        this.adWindow === 'Y' && this.fieldType == CompiereDataFieldType.PROCESS_PARA ? this.adWindowValue : 0,
      ad_Form_ID: this.adWindow === 'Y' && this.fieldType == CompiereDataFieldType.FORM_ITEM ? this.adWindowValue : 0,
      attribute: this.attribute,
      value: this.value + '',
    } as ValuePreference;
  }
  checkDeleteAvailable() {
    const ctx = this.#connectorService.getIupicsUserContext();
    const valuePref = this.createValuePref();
    const id = this.getUsedId();
    const entryKey = `${this.prefix}${id > 0 ? id : ''}|${valuePref.attribute}`;
    if (ctx[entryKey] !== undefined && ctx[entryKey] !== null) {
      this.isDeletable = true;
    } else {
      this.isDeletable = false;
    }
  }
  updateToggleValue(property: string, value: string) {
    this[property] = value;
    this.updateDescription();
    this.checkDeleteAvailable();
  }
  getUsedId() {
    switch (this.fieldType) {
      case CompiereDataFieldType.FORM_ITEM:
        return this.adWindow === 'Y' && this.fieldType == CompiereDataFieldType.FORM_ITEM ? this.adWindowValue : 0;
      case CompiereDataFieldType.PROCESS_PARA:
        return this.adWindow === 'Y' && this.fieldType == CompiereDataFieldType.PROCESS_PARA ? this.adWindowValue : 0;

      default:
        this.prefix = 'P';
        return this.adWindow === 'Y' && this.fieldType == CompiereDataFieldType.FIELD ? this.adWindowValue : 0;
    }
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.#zone.runOutsideAngular(() => {
        const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

        this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
          if (this.isOutsideClicked(event)) {
            this.#zone.run(() => {
              this.hide(event);
              this.cd.markForCheck();
            });
          }
        });
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  hide(event) {
    if (this.overlayPanel && this.overlayPanel.overlayVisible) {
      this.unbindDocumentClickListener();
      this.closeEmitter.emit(event);
    }
  }
  isOutsideClicked(event: Event) {
    return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
  }
}
