@if (isSpecificGrid && isSpecificGridTitleDisplay) {
<div [ngClass]="[!isDisplayed ? 'isHidden' : '', 'title']">
<label>
<strong>{{ gridTitle }}</strong>
</label>
</div>
}

<div
    #divContent
    [ngClass]="[!isDisplayed ? 'isHidden' : '', 'compContainer']"
    style.width="{{ cssWidth }}"
    [attr.data-cy-grid-tablename]="
    this.data ? (this.data['AD_FormDetail_ID'] ? this.data.columnName : this.data.tableName) : 'none'
  ">
    @if (!noColumnToDisplay) {
    <div
      [ngClass]="[isGridCollapsed && isTabTopLevel ? 'gridCollapsed' : '', cssClass || '']"
      style.width="{{ cssWidth }}">
      @if (isMenuDisplay) {
    <iu-menu-bar-ui
            (changeViewEvent)="switchViewType($event)"
            (filterChange)="displayHideFilters($event)"
            (newEmitter)="openNew()"
            (openExportDataModalEmitter)="openExportDataModal($event)"
            (refreshEmitter)="refreshGrid()"
            (removeEmitter)="remove($event)"
            [visibleTreeButton]="this.isTree"
            [activeTabID]="container?.activeTab?.id"
            [gridView]="this"
            [hasUniversalFilter]="hasUniversalFilter"
            [isDeleteable]="this.isDeleteable"
            [isFromForm]="fromForm"
            [isInsertRecord]="isInsertRecord"
            [isReadOnly]="isReadOnly"
            [isSplitView]="isSplitView"
            [isTabTopLevel]="isTabTopLevel"
            [tabId]="tabId"
            [visibleButton]="visibleButtons"/>
    }

    @if (isLoading) {
    <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'"/>
    }

    @switch (viewType) {
    @case ('tree') {
    <iu-tree-ui (selectItem)="forceOpenEditView($event)" [data]="this.data" />
    }

    @case ('kanban') {
    <iu-kanban-ui
      [isCollapsed]="isGridCollapsed"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [data]="data"
      [tabId]="tabId"
      [tableName]="this.data.tableName"
      [container]="container"
      [columnsDisplayAD]="this.data.columnsDisplayAD"
      [kanbanImageColumn]="this.data.kanbanImageColumn"
      (clickEmitter)="forceOpenEditView($event)"
      class="kanban-board"
      [initRequest]="initRequest" />
    }

    @case ('calendar') {
    <iu-calendar-view-ui
      [isCollapsed]="isGridCollapsed"
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [data]="data"
      [tabId]="tabId"
      [container]="container"
      [initRequest]="initRequest"
      (clickEmitter)="forceOpenEditView($event)" />
    }

    @case ('chart') {
    <iu-chart-ui
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [tabId]="tabId"
      [data]="data"
      [container]="container"
      [initRequest]="initRequest" />
    }

    @case ('cards') {
    <iu-cards-ui
      [gridTabFilter]="gridTabFilter"
      [gridTabValidator]="gridTabValidator"
      [DOMParentComponent]="this"
      [type]="data.tabType"
      (clickEmitter)="forceOpenEditView($event)" />
    }
    }
    <iu-grid-tab-infinity-scroll-headless-ui
      [ngStyle]="{ display: viewType === 'grid' ? 'block' : 'none' }"
        (gridCellEditingStopped)="gridCellEditingStopped.emit($event)"
        (gridRefreshed)="gridRefreshed.emit($event)"
        (gridViewCellClicked)="gridCellClicked($event)"
        (multiSelectEmitter)="displayHideMultiButton($event)"
        (notifyGridTabAfterViewInitEmitter)="notifyGridTabAfterViewInitEmitter($event)"
        (gridPrefLoaded)="gridPrefLoaded.emit($event)"
        [container]="container"
        [data]="data"
        [DOMParentComponent]="this"
        [gridTabFilter]="gridTabFilter"
        [gridTabValidator]="gridTabValidator"
        [initFromSwitch]="initFromSwitch"
        [initRequest]="initRequest"
        [isChangeLog]="isChangelog"
        [isTabTopLevel]="!gridPaginator"
        [isZoomTargetGrid]="isZoomTargetGrid"
        [query]="data?.query"
        [rowSelection]="rowSelection"
        [style.height.%]="gridPaginator ? 100 : 'unset'"
        [suppressRowClickSelection]="suppressRowClickSelection"
        [tabId]="tabId"
        [windowType]="windowType" />
    </div>
    }

    @if (displayExportDataModal) {
    <div>
      <iu-modal-ui
        #exportDataModal
        [title]="'exportData.title'"
        [hasCloseBtn]="false"
        (closeModalEmitter)="displayExportDataModal = false"
        [angularStyle]="{ 'iu-modal-body': { overflow: 'unset' } }">
        <iu-export-data-ui
          [datagridRequest]="request"
          [ids]="exportIds"
          [tableId]="this.data.AD_Table_ID"
          [tabId]="this.tabId"
          [whereClause]="whereClause"
          [sourceModal]="exportDataModal"
          (cancelExportDataEmitter)="displayExportDataModal = false" />
      </iu-modal-ui>
    </div>
    }

    @if (displayProcessUI) {
    <div>
      <iu-modal-ui
        (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
        [hasCloseBtn]="true"
        [angularStyle]="{ 'iu-modal-body': { padding: 0 } }">
        <iu-process-ui
          (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
          [windowId]="processId"
          [parentComponent]="this"
          [isModal]="true" />
      </iu-modal-ui>
    </div>
    }

    <div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
        <iu-modal-ui
                [title]="specificWindowTitle"
                [isModalDisplay]="displayFormUI"
                (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
                [modalClass]="modalClass">
            <ng-template #vcrSpecific/>
        </iu-modal-ui>
    </div>
</div>

<!-- @if (displaySearch) {
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [hidden]="!displaySearch"
    [title]="searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="this.displaySearch = false">
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab" />
    </div>
    <iu-grid-tab-infinity-scroll-headless-ui
      #gridTab
      (searchEmitter)="
        searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
      "
      [data]="searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="searchLinkedComponent.autoComplete.dataStored"
      [windowType]="windowType"
      rowSelection="single"
      [validation]="searchPanelValidation"
      [hasCheckBox]="searchLinkedComponent.autoComplete.data.columnName === 'M_Product_ID'" />
  </iu-modal-ui>
}
 -->
