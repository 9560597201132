import { Injectable, Injector, Optional, SkipSelf, inject } from '@angular/core';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { IupicsData, IupicsTableDataHeader } from '@iupics-manager/models/iupics-data';
import { IupicsColumnInfo } from '@iupics-manager/models/iupics_column_info';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { ColDef, createInjectionToken, injectGridApiService } from '@iupics/apiz-grid';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { Observable, Subject, iif, map, of, switchMap, tap } from 'rxjs';

@Injectable()
export class ViewColumnsService {
  uuid = self.crypto.randomUUID();
  #uiCreator = inject(UICreatorService);
  #gridApi = injectGridApiService();

  #view = new Subject<ViewParams>();

  #columns: IupicsColumnInfo[] = [];
  data: ViewColumnsData = undefined;

  columns$ = this.#view.asObservable().pipe(
    switchMap((context) =>
      iif(
        () => !context.tabId || this.#columns.length > 0,
        of({ columns: this.#columns, context }),
        this.#uiCreator
          .getColumnInfos(context.tabId, context?.fromSpecific)
          .pipe(map((columns) => ({ columns, context })))
      )
    ),
    tap(({ columns }) => (this.#columns = columns))
  );

  colDefs$: Observable<ViewColumnsData> = this.columns$.pipe(
    map(({ columns, context }) => ({
      columns,
      columnsData: columns.reduce((acc, { fieldEntity: { field, details } }) => {
        if (
          (field?.Name || field?.name) &&
          (field?.Name ?? field.name).trim() !== '' &&
          (field.AD_Field_ID === -2 || field.IsDisplayed || field.IsKey)
        ) {
          acc.push({ data: field, details });
        }

        return acc;
      }, []),
      context,
    })),
    map(
      ({
        columns,
        columnsData,
        context: { data, hasCheckBox, windowType, ctx, columnFormatter, shouldSetColDefs = false },
      }) => {
        let tableDataHeader!: IupicsTableDataHeader[];
        if (data?.columnsTableHeader) {
          tableDataHeader = [...data.columnsTableHeader];
          // change columnHeader depending on issotrx
          if (data.columnsDetails && windowType !== IupicsMenuType.FORM) {
            if (ctx?.['IsSOTrx'] !== null && ctx?.['IsSOTrx'] === 'N') {
              tableDataHeader.map((colHeader: ColDef) => {
                if (data.columnsDetails.has(colHeader.field)) {
                  const colDetail = data.columnsDetails.get(colHeader.field);
                  if (colDetail?.field?.poName) {
                    colHeader.headerName = colDetail.field.poName;
                  }
                }
                colHeader.valueFormatter = columnFormatter;
                return colHeader;
              });
            }
          }
        }

        if (data?.urlSearch) {
          if (tableDataHeader) {
            tableDataHeader.splice(0, 1); // TODO: Check why this is done
          } else if (data.searchColumns) {
            tableDataHeader = [];
            for (const el of data.searchColumns) {
              tableDataHeader.push(UICreatorUtils.transformFieldForGrid(el.field, true));
            }
            data.hasCheckbox = false;
          }
        }

        if (tableDataHeader?.[0]) {
          if (!tableDataHeader?.[0]?.checkboxSelection) {
            tableDataHeader.unshift({
              field: 'Data_UUID',
              headerName: '',
              sortable: false,
              enableRowGroup: false,
              width: 38,
              resizable: false,
              hide: data?.hasCheckbox !== undefined ? !data.hasCheckbox : !hasCheckBox,
            });

            tableDataHeader[0].checkboxSelection = (params) => {
              return data?.hasCheckbox ?? hasCheckBox ?? params.colDef.field === 'Data_UUID';
            };
          } else {
            tableDataHeader[0].hide = false;
          }

          for (const column of tableDataHeader) {
            if (column.field === 'Data_UUID') continue;
            column.sortable = true;
            column.resizable = true;
            column.valueFormatter = columnFormatter;
            if (data?.editorColumns?.length > 0 && data.editorColumns.indexOf(column.field) > -1) {
              column.sortable = false;
              // column.enablePivot = false;
              column.enableRowGroup = false;
            }
            const _column = columns.find((_col) => _col.fieldEntity.field.ColumnName === column.field);
            if (_column) {
              column.type =
                UICreatorUtils.getComponentNameFromReference(_column.fieldEntity.field.AD_Reference_ID) ===
                'InputNumberUiComponent'
                  ? 'number'
                  : 'text';
            }
          }
        }

        const selectionColumn = columns
          .filter((col) => this.#hasDefaultFilter(col.fieldEntity))
          .sort((a, b) => a.fieldEntity.field.SelectionSeqNo - b.fieldEntity.field.SelectionSeqNo)[0];

        if (selectionColumn) {
          const i = tableDataHeader.findIndex((_tdh) => _tdh.field === selectionColumn.fieldEntity.field.ColumnName);
          if (i !== -1) tableDataHeader[i].defaultFilters = [{ filterType: 'text', operators: ['contains'] }];
        }

        return {
          columns,
          columnsData,
          columnsTableHeader: this.getColumnsDisplayLogic(tableDataHeader ?? [], data, ctx, columnFormatter),
          shouldSetColDefs,
        };
      }
    ),
    tap((data) => {
      this.data = data;
      if (data.shouldSetColDefs) {
        this.#gridApi.updateOptions('columnDefs', data.columnsTableHeader);
      }
    })
  );

  setView(view: ViewParams) {
    this.#view.next(view);
  }

  getColumnsDisplayLogic(
    cols: IupicsTableDataHeader[],
    data: IupicsData,
    ctx: any,
    columnFormatter: ((params: any) => string) | undefined
  ) {
    cols = cols.map((col: IupicsTableDataHeader) => {
      col.valueFormatter = columnFormatter;
      return col;
    });

    if (data.columnsDisplayLogicMap && data.columnsDisplayLogicMap.size > 0) {
      return cols.filter((col) => {
        let returnCol = null;
        const displayLogic = data.columnsDisplayLogicMap.get(col.field);
        if (data.hiddenColumns !== undefined) {
          if (!data.hiddenColumns.includes(col.field)) {
            if (!displayLogic || displayLogic === '' || LogicEvaluator.evaluateLogic(ctx, displayLogic)) {
              returnCol = col;
            }
          } else {
            col.hide = true;
            returnCol = col;
          }
          return returnCol;
        } else {
          if (!displayLogic || displayLogic === '' || LogicEvaluator.evaluateLogic(ctx, displayLogic)) {
            returnCol = col;
          }

          return returnCol;
        }
      });
    } else {
      return cols;
    }
  }

  #hasDefaultFilter(fieldEntity: any): boolean {
    return (
      [10, 14, 40, 38, 39].includes(fieldEntity.field.AD_Reference_ID) &&
      (fieldEntity?.field?.isQueryCriteria ?? fieldEntity?.field?.IsSelectionColumn)
    );
  }
}

type ViewParams = {
  tabId: number;
  fromSpecific?: boolean;
  data?: IupicsData;
  ctx?: any;
  hasCheckBox?: boolean;
  windowType?: IupicsMenuType;
  columnFormatter?: (params: any) => string;
  shouldSetColDefs?: boolean;
};

type ViewColumnsData = {
  columns: IupicsColumnInfo[];
  columnsData: IupicsTableDataHeader[];
  columnsTableHeader: IupicsTableDataHeader[];
  shouldSetColDefs: boolean;
};

export const [injectViewColumnsService, provideViewColumnsService] = createInjectionToken(
  (parentInjector: Injector, viewColumnsService?: ViewColumnsService) => {
    if (!viewColumnsService) {
      const injector = Injector.create({
        providers: [{ provide: ViewColumnsService }],
        parent: parentInjector,
      });
      viewColumnsService = injector.get(ViewColumnsService);
    }
    return viewColumnsService;
  },
  { isRoot: false, deps: [Injector, [new Optional(), new SkipSelf(), ViewColumnsService]] }
);
