import { NgStyle } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ZIndexUtils } from 'primeng/utils';
import * as shajs from 'sha.js';

export enum InfoDialogType {
  ERROR = 'error',
  INFO = 'info',
  CONFIRM = 'confirm',
  CONFIRM_YESNO = 'confirmyesno',
}

@Component({
  selector: 'iu-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  standalone: true,
  imports: [NgStyle, ToastModule, SharedModule, ButtonModule, TranslateModule],
})
export default class InfoDialogComponent implements OnInit, OnDestroy {
  @Input()
  message: {
    summary: string;
    detail: string;
  };
  @Input()
  dialogType: InfoDialogType;

  @Output()
  confirm = new EventEmitter<any>();
  @Output()
  cancel = new EventEmitter<any>();
  @ViewChild('dialogContainer', { static: true }) dialogContainerRef: ElementRef<HTMLInputElement>;
  key: string;

  isOpen = false;

  baseZIndex = 5000;

  constructor(
    private messageService: MessageService,
    private el: ElementRef
  ) {}

  ngOnInit() {
    if (this.dialogContainerRef) ZIndexUtils.set('info-dialog', this.dialogContainerRef.nativeElement, this.baseZIndex);
    if (this.message) {
      this.key = shajs('sha256')
        .update(this.message.summary + this.message.detail + this.dialogType + Date.now())
        .digest('hex');
    } else {
      this.key = shajs('sha256')
        .update('global' + Date.now())
        .digest('hex');
    }
  }
  ngOnDestroy(): void {
    if (this.dialogContainerRef) ZIndexUtils.clear(this.dialogContainerRef.nativeElement);
  }

  confirmDialog() {
    this.isOpen = false;
    this.confirm.emit();
    this.messageService.clear(this.key);
    if (this.dialogContainerRef) ZIndexUtils.clear(this.dialogContainerRef.nativeElement);
  }

  cancelDialog() {
    this.isOpen = false;
    this.cancel.emit();
    this.messageService.clear(this.key);
    if (this.dialogContainerRef) ZIndexUtils.clear(this.dialogContainerRef.nativeElement);
  }

  showInfoDialog() {
    if (this.dialogContainerRef) ZIndexUtils.set('info-dialog', this.dialogContainerRef.nativeElement, this.baseZIndex);
    this.isOpen = true;
    this.messageService.add({
      key: this.key,
      sticky: true,
      closable: false,
      severity:
        this.dialogType === InfoDialogType.CONFIRM || this.dialogType === InfoDialogType.CONFIRM_YESNO
          ? 'warn'
          : this.dialogType,
      summary: this.message.summary,
      detail: this.message.detail,
    });
  }
}
