import { EventEmitter, Injectable } from '@angular/core';
import {
  CompiereNotification,
  CompiereNotificationDataIcon,
  CompiereNotificationPriority,
  CompiereNotificationType,
  CompiereUINotificationWS,
} from '@compiere-ws/models/compiere-notification-json';
import { CompiereNotificationService } from '@compiere-ws/services/compiere-notification/compiere-notification.service';
import { PrintReportService } from '@compiere-ws/services/compiere-print-report/print-report.service';
import { SocketService } from '@compiere-ws/services/socket/socket.service';
import { Global } from '@iupics-manager/models/global-var';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityManagerService } from '../security-manager/security-manager.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationManagerService {
  onChangeRoleChannel = new EventEmitter<any>();
  onRoleChanged = new EventEmitter<any>();
  private syncWaitingNotifications: {
    [key: string]: Subject<CompiereNotification>;
  } = {};

  constructor(
    private printReportService: PrintReportService,
    private messageService: MessageService,
    private compiereNotificationService: CompiereNotificationService,
    private socketService: SocketService,
    private connectorService: SecurityManagerService,
    private translateService: TranslateService
  ) {}

  getNotificationsByType(
    type: string,
    offset: number,
    limit: number,
    onlyUnread: boolean
  ): Observable<CompiereNotification[]> {
    return this.compiereNotificationService
      .getNotificationsByType(type, offset, limit, onlyUnread)
      .pipe(map((notifications) => this.initNotificationDatas(notifications)));
  }

  getNotificationsCounts(): Observable<CompiereUINotificationWS> {
    return this.compiereNotificationService.getNotificationsCounts();
  }

  handleNotification(notif: CompiereNotification): Observable<CompiereNotification> {
    return this.compiereNotificationService.handleNotification(notif);
  }

  handleAllNotification(type: CompiereNotificationType): Observable<CompiereNotification> {
    return this.compiereNotificationService.handleAllNotification(type);
  }

  closeNotification(notif: CompiereNotification): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeNotification(notif);
  }

  closeAllNotification(type: CompiereNotificationType, onlyUnread: boolean): Observable<CompiereNotification> {
    return this.compiereNotificationService.closeAllNotification(type, onlyUnread);
  }

  changeRoleChannel(previous: number, next: number): void {
    this.onChangeRoleChannel.emit({ previousRole: previous, nextRole: next });
  }

  roleChanged(): void {
    this.onRoleChanged.emit();
  }

  popNotification(...notifications: CompiereNotification[]) {
    notifications.forEach((notification) => {
      this.messageService.add({
        summary: notification.title,
        detail: notification.summary,
        data: { notification: notification, type: 'Notification' },
      });
    });
  }

  syncWithNotification(channelId: string): Subject<CompiereNotification> {
    this.syncWaitingNotifications[channelId] = new Subject();
    (async () => {
      for await (const notification of this.socketService.enableRoleNotifications()) {
        const foundChannelId = (<CompiereNotification>notification).processChannelId
          ? (<CompiereNotification>notification).processChannelId
          : (<CompiereNotification>notification).processParams?.channel_id;
        if (foundChannelId === channelId) {
          this.syncWaitingNotifications[channelId].next(notification);
          delete this.syncWaitingNotifications[channelId];
        }
      }
    })();
    return this.syncWaitingNotifications[channelId];
  }

  downloadReport(url: string): void {
    this.printReportService.downloadReport(url).subscribe((response) =>
      setTimeout(() => {
        Global.downloadFile(response);
      }, 100)
    );
  }

  initNotificationDatas(list: CompiereNotification[]) {
    return list.map((notif) => this.initNotifData(notif));
  }

  initNotifData(notif: CompiereNotification) {
    return {
      ...notif,
      data: notif.data || {
        icons: this.getIcons(notif),
        readAbleDate: this.getReadableDate(notif),
        priorityData: this.getPriorityData(notif),
      },
    };
  }

  getPriorityData(notif: CompiereNotification) {
    if (!notif.priority) return {};
    let priorityData;
    switch (notif.priority) {
      case CompiereNotificationPriority.IMPORTANT:
        priorityData = {
          priorityTitle: this.translateService.instant('notification.priority.critical'),
          priorityClass: 'critical',
        };
        break;
      case CompiereNotificationPriority.HIGH:
        priorityData = {
          priorityTitle: this.translateService.instant('notification.priority.high'),
          priorityClass: 'high',
        };
        break;
      case CompiereNotificationPriority.LOW:
        priorityData = {
          priorityTitle: this.translateService.instant('notification.priority.low'),
          priorityClass: 'low',
        };
        break;
      default:
        priorityData = {
          priorityTitle: this.translateService.instant('notification.priority.medium'),
          priorityClass: 'medium',
        };
        break;
    }
    return priorityData;
  }

  getReadableDate(notification: CompiereNotification) {
    if (!notification.created) return '';

    // TODO: check if this really useful
    DateUtils.setLocale(this.connectorService.getIupicsDefaultLanguage().iso_code);

    const format = DateUtils.isSame(notification.created, Date.now()) ? 'LT' : 'L LT';
    return DateUtils.formatStr(notification.created, format);
  }

  /**
   * Défini l'icon pour les fichiers
   */
  private getIcons(notif: CompiereNotification) {
    if (!notif.fileLinks) return [];
    return notif.fileLinks.map((fileLink) => ({
      fileLink: fileLink.path,
      icon: this.getFileIcon(fileLink.extension),
      fileName: fileLink.fileName,
    })) as CompiereNotificationDataIcon[];
  }

  /**
   * Obtiens l'icône par rapport à son extension
   * @param {string}fileLink
   */
  private getFileIcon(fileLink: string) {
    const iconMap: { [extension: string]: string } = {
      pdf: 'icon-pdf',
      csv: 'icon-csv',
      txt: 'icon-txt',
      xls: 'icon-excel',
      xlsx: 'icon-excel',
      doc: 'icon-word',
      docx: 'icon-word',
      sql: 'icon-sql',
      zip: 'icon-zip',
      html: 'icon-html',
    };

    return iconMap[fileLink] || 'icon-file';
  }
}
