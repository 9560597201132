import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgClass, NgStyle } from '@angular/common';
import { Component, ElementRef,HostBinding, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { DataStore } from '@compiere-ws/models/compiere-data-json';
import { DataKanban, KanbanGroup } from '@iupics-components/models/kanban-interface';
import BladeUiComponent from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsColumnKanban, IupicsField } from '@iupics-manager/models/iupics-data';
import { IupicsEvent } from '@iupics-manager/models/iupics-event';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Global } from '@iupics-manager/models/global-var';
import KanbanCardUiComponent from '../kanban-card-ui/kanban-card-ui.component';
import { KanbanUtils } from '../utils/kanban.utils';

@Component({
  selector: 'iu-kanban-board-ui',
  templateUrl: './kanban-board-ui.component.html',
  styleUrls: ['./kanban-board-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    CdkDropList,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkDrag,
    KanbanCardUiComponent,
  ],
})
export default class KanbanBoardUiComponent extends AbstractDynamicComponent implements OnInit {
  #store = inject(DataStoreService);
  #config = inject(AppConfig);
  #uiCreatorService = inject(UICreatorService);
  #translateService = inject(TranslateService);

  @Input()
  draggedElement: any;

  @Input()
  tableName: string;

  @Input()
  columnsDisplayAD: IupicsColumnKanban[];

  @Input()
  kanbanImageColumn: string;

  @Input()
  kanbanGroup: KanbanGroup;

  @Input()
  isFlexDesign: boolean;

  @Input()
  isCollapsed: boolean;
  @HostBinding('class.kanbanCollapsed') get isHostCollapsed() {
    return this.isCollapsed;
  }
  @HostBinding('class.mobile') get isMobile() {
    return Global.isMobile();
  }
  @Output()
  clickEmitter = new EventEmitter<any>();

  @Output()
  changeGroupEmitter = new EventEmitter<any>();

  @Output()
  draggedElementChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('boardList')
  boardListDOM: ElementRef;

  ngOnInit() {
    //? This condition seems to be always true at the time we arrive here
    //? Maybe we should find a better way to handle this
    if (this.kanbanGroup.datas.length <= 0) {
      this.moreData(true);
    }
  }

  onClick(dataKanban: DataKanban) {
    this.clickEmitter.emit(dataKanban.data['Data_UUID']);
  }

  dragstart(event, element, dataKanban: DataKanban) {
    if (this.kanbanGroup.columnName) {
      this.draggedElement = element;
      this.draggedElement['dataStore'] = dataKanban;
      this.draggedElement['kanbanGroup'] = this.kanbanGroup;
      this.draggedElement['updateKanbanGroup'] = (kanbanGroup) => {
        this.kanbanGroup = kanbanGroup;
      };
      this.draggedElementChange.emit(this.draggedElement);
    }
  }

  changeGroup(event) {
    if (
      this.draggedElement &&
      this.kanbanGroup.columnName &&
      this.draggedElement['dataStore']?.data &&
      (this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']].id !==
        this.kanbanGroup.groupValue.id ||
        (this.kanbanGroup.groupValue.id === undefined &&
          this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']] !== this.kanbanGroup.groupValue))
    ) {
      const dataModified = {};
      dataModified[this.kanbanGroup.columnName['field']] = this.kanbanGroup.groupValue;
      const dataStoreKey = this.#store.generateDataStoreKey(
        (<BladeUiComponent>this.container).infoComponent.windowId,
        this.tabId,
        this.draggedElement['dataStore'].data['Data_UUID'],
        null
      );
      const previousGroup = this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']];
      this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']] = this.kanbanGroup.groupValue;
      this.transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.updateContainers(event.previousContainer, event.container);

      this.#uiCreatorService
        .getTab((<BladeUiComponent>this.container).infoComponent.windowId, this.tabId)
        .subscribe((tab) => {
          let field = null;
          if (tab) {
            field = this.findDataField(tab[0].editView.children);
          }
          const fieldData = field ? field.data : null;
          if (!fieldData || !this.isFieldReadOnly(fieldData, this.draggedElement['dataStore'])) {
            this.#store.updateStoreWithoutFields(dataStoreKey, this.draggedElement['dataStore'], dataModified);
            this.subscriptions.push(
              this.#store.saveWindowData([dataStoreKey]).subscribe({
                next: (res) => {
                  if (!res) {
                    this.changeGroupEmitter.emit();
                  }
                },
                error: (err) => {
                  this.changeGroupEmitter.emit();
                },
              })
            );
          } else {
            let msg = this.#translateService.instant('kanban.dropError');
            this.draggedElement['dataStore'].data[this.kanbanGroup.columnName['field']] = previousGroup;
            this.transferArrayItem(
              event.container.data,
              event.previousContainer.data,
              event.currentIndex,
              event.previousIndex
            );
            this.updateContainers(event.previousContainer, event.container);
            throw new Error(msg.replace('@propertyName@', this.kanbanGroup.columnName['displayName']));
          }
        });
    } else if (event.previousContainer === event.container) {
      this.moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.updateContainers(event.container);
    }
  }

  isFieldReadOnly(fieldData: any, dataStored: DataStore): boolean {
    if (fieldData.readOnlyLogic) {
      return LogicEvaluator.evaluateLogic(dataStored.data, fieldData.readOnlyLogic);
    }

    return (
      dataStored.data.DocStatus !== undefined &&
      dataStored.data.DocStatus !== null &&
      dataStored.data.DocStatus.id !== 'DR' &&
      fieldData.isAlwaysUpdatable !== undefined &&
      fieldData.isAlwaysUpdatable !== null &&
      fieldData.isAlwaysUpdatable === false
    );
  }

  moreData(init = false) {
    //Pour éviter d'incrémenter le startrow si c'est la première fois
    if (!init) {
      this.kanbanGroup.dataStoreRequest.compiereRequest.startRow =
        this.kanbanGroup.dataStoreRequest.compiereRequest.endRow;
      this.kanbanGroup.dataStoreRequest.compiereRequest.endRow =
        this.kanbanGroup.dataStoreRequest.compiereRequest.endRow +
        this.#config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize');
    }

    this.subscriptions.push(
      this.#store.getDataGrid(this.kanbanGroup.dataStoreRequest).subscribe((response) => {
        if (response.data) {
          const kanbanDatas = <DataKanban[]>(
            KanbanUtils.transformDataForKanbanView(response.data, this.columnsDisplayAD, this.kanbanImageColumn)
          );
          let kanbanGroupsCopy = cloneDeep(this.kanbanGroup);
          kanbanGroupsCopy.datas = [...this.kanbanGroup.datas, ...kanbanDatas];
          kanbanGroupsCopy.isMoreData = response.lastRow <= -1;
          this.kanbanGroup = kanbanGroupsCopy;
        }
      })
    );
  }

  findDataField(fields: IupicsField[]) {
    for (const field of fields) {
      if (field.data.columnName === this.kanbanGroup.columnName['field']) {
        return field;
      } else if (field.children) {
        const value = this.findDataField(field.children);
        if (value) {
          return value;
        }
      }
    }
    return null;
  }

  /**
   * Moves an item one index in an array to another.
   * @param array Array in which to move the item.
   * @param fromIndex Starting index of the item.
   * @param toIndex Index to which the item should be moved.
   */
  moveItemInArray<T = any>(array: T[], fromIndex: number, toIndex: number): void {
    if (fromIndex === toIndex) {
      return;
    }

    const element = array.splice(fromIndex, 1)[0];
    array.splice(toIndex, 0, element);
  }

  /**
   * Moves an item from one array to another.
   * @param currentArray Array from which to transfer the item.
   * @param targetArray Array into which to put the item.
   * @param currentIndex Index of the item in its current array.
   * @param targetIndex Index at which to insert the item.
   */
  transferArrayItem<T = any>(currentArray: T[], targetArray: T[], currentIndex: number, targetIndex: number): void {
    const from = this.clamp(currentIndex, currentArray.length - 1);
    const to = this.clamp(targetIndex, targetArray.length);

    if (currentArray.length) {
      const item = currentArray.splice(from, 1)[0];
      // currentArray = [...currentArray];
      targetArray.splice(to, 0, item);
      // targetArray = [...targetArray];
    }
  }

  /** Clamps a number between zero and a maximum. */
  clamp(value: number, max: number): number {
    return Math.max(0, Math.min(max, value));
  }

  /* reinit kanbangroup to refresh view */
  updateContainers(draggingContainer, droppingContainer = null) {
    if (this.draggedElement?.['kanbanGroup']) {
      const kanbanGroupsCopy = cloneDeep(this.draggedElement['kanbanGroup']);
      kanbanGroupsCopy.datas = [...draggingContainer.data];
      this.draggedElement['updateKanbanGroup'](kanbanGroupsCopy);
    }

    if (this.kanbanGroup && droppingContainer) {
      const kanbanGroupsCopy = cloneDeep(this.kanbanGroup);
      kanbanGroupsCopy.datas = [...droppingContainer.data];
      this.kanbanGroup = kanbanGroupsCopy;
    }
  }

  onVirtualScroll(event) {
    if (this.kanbanGroup.isMoreData) {
      if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
        this.moreData();
      }
    }
  }

  trackByFn(_, item) {
    return item[this.tableName + '_ID'];
  }

  onChildUpdate(event): void {}
  onSiblingUpdate(event: IupicsEvent) {}
  onRemoveComponent(event: IupicsEvent) {}
}
