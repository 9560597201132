import { EventEmitter, Injectable } from '@angular/core';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  //#region linkedComponentToTabMap
  #linkedComponentToTabMap = new Map<string, AbstractDynamicComponent>();

  addLinkedComponentToTabMap(key: string, component: AbstractDynamicComponent) {
    this.#linkedComponentToTabMap.set(key, component);
  }

  getLinkedComponentToTabMap(key: string) {
    return this.#linkedComponentToTabMap.get(key);
  }

  removeLinkedComponentToTabMap(key: string) {
    this.#linkedComponentToTabMap.delete(key);
  }
  //#endregion

  //#region ContextPanel
  showContextPanelEmt = new EventEmitter<any>();
  //#endregion

  //#region openTargetSearch
  openTargetSearchEmt = new EventEmitter<any>();
  //#endregion

  //#region UrlParams
  //#endregion
}
