<li class="listSmartButtonMore">
  <a [style.width.px]="buttonWidth" href="#" (click)="onSmartButtonClick($event)">
    <span>
      @if (iconClass()) {
        <i [ngClass]="[iconClass()]" aria-hidden="true"></i>
      }
      {{ label() | textLimit: buttonWidth / 8 }}
    </span>
    <i class="icon-next" aria-hidden="true"></i>
  </a>
</li>
