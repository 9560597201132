import { Injectable, inject } from '@angular/core';
import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridSortModelType,
  CompiereDataGridType,
} from '@compiere-ws/models/compiere-data-json';
import {
  ColumnFilterAutocomplete,
  OperatorFilterAutocomplete,
} from '@iupics-components/models/autocomplete-interfaces';
import { OperatorFilterType, filterOperators } from '@iupics-components/models/universal-filter';
import { CalendarConfig } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { AppConfig } from '@iupics-config/app.config';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { AppliedItem, ColDataRequest, FilterModel, FilterModelOperator, SortModel, SortType } from '@iupics/apiz-grid';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UniversalFilterUtils {
  private config = inject(AppConfig);
  private connectorService = inject(SecurityManagerService);
  private translator = inject(TranslateService);

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * **************************** CHIPS ******************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */
  public dataToDisplayToChips(dataToDisplay: DataToDisplay): FilterChip[] {
    const dataToTransform = this.cleanDataToDisplay(cloneDeep(dataToDisplay));
    // TODO: check if this really useful
    DateUtils.setLocale(this.connectorService.getIupicsDefaultLanguage().iso_code);
    const filterChips: FilterChip[] = [];
    for (let i = 0; i < dataToTransform.filters.length ?? 0; i++) {
      const f = dataToTransform.filters[i];
      const needTime =
        f.column.columnInfo.fieldEntity.field.AD_Reference_ID === 16 ||
        f.column.columnInfo.fieldEntity.field.AD_Reference_ID === 24;
      let filterStr: string;
      let operatorStr: string;
      if (f.operator.operator) {
        if (
          f.operator.operator.type === OperatorFilterType.IS_NULL ||
          f.operator.operator.type === OperatorFilterType.IS_NOT_NULL
        ) {
          filterChips.push({
            icon: 'icon-filter',
            displayValue: `${f.column.displayValue} ${f.operator.displayValue}`,
            type: 'filters',
            index: i,
            columnName: f.column.id,
          });
        } else {
          if (f.operator.operator.isRange) {
            if (f.filter && f.filterTo) {
              filterStr =
                this.#getFilterStr(
                  f.operator.operator.filterType,
                  f.filter,
                  f.configs?.calendarConfig?.todayMode,
                  needTime
                ) +
                (this.translator ? this.translator.instant('universalFilter.and') : ' and ') +
                this.#getFilterStr(
                  f.operator.operator.filterType,
                  f.filterTo,
                  f.configs?.calendarConfig?.todayMode,
                  needTime
                );
              operatorStr = f.operator.displayValue;
            } else if (f.filter && !f.filterTo) {
              filterStr = this.#getFilterStr(
                f.operator.operator.filterType,
                f.filter,
                f.configs?.calendarConfig?.todayMode,
                needTime
              );
              operatorStr = filterOperators.find(
                (op) =>
                  op.type === OperatorFilterType.BIGGERTHANOREQUAL && op.filterType === f.column.columnInfo.filterType
              ).label;
            } else if (!f.filter && f.filterTo) {
              filterStr = this.#getFilterStr(
                f.operator.operator.filterType,
                f.filterTo,
                f.configs?.calendarConfig?.todayMode,
                needTime
              );
              operatorStr = filterOperators.find(
                (op) =>
                  op.type === OperatorFilterType.LESSTHANOREQUAL && op.filterType === f.column.columnInfo.filterType
              ).label;
            }
          } else if (f.filter !== null && f.filter !== undefined) {
            if (Array.isArray(f.filter)) {
              if (f.filter.length > 5) {
                filterStr = '';
                const nb = f.filter.length - 5;
                for (let i = 0; i < 5; i++) {
                  const item = f.filter[i];
                  filterStr +=
                    (item.displayValue !== undefined ? item.displayValue : item) +
                    (this.translator ? this.translator.instant('universalFilter.or') : ' or ');
                }
                filterStr += this.translator
                  ? this.translator.instant('universalFilter.moreOther', { nb })
                  : `+${nb} others`;
              } else {
                filterStr = f.filter
                  .map((item) => (item.displayValue !== undefined ? item.displayValue : item))
                  .join(this.translator ? this.translator.instant('universalFilter.or') : ' or ');
              }
              operatorStr = f.operator.displayValue;
            } else {
              filterStr = this.#getFilterStr(
                f.operator.operator.filterType,
                f.filter,
                f.configs?.calendarConfig?.todayMode,
                needTime
              );
              operatorStr = f.operator.displayValue;
            }
          }
          if (filterStr !== null && filterStr !== undefined && operatorStr !== null && operatorStr !== undefined) {
            filterChips.push({
              icon: 'icon-filter',
              displayValue: `${f.column.displayValue} ${operatorStr} '${filterStr}'`,
              type: 'filters',
              index: i,
              columnName: f.column.id,
            });
          }
        }
      }
    }

    for (let i = 0; i < dataToTransform.groups.length ?? 0; i++) {
      const group = dataToTransform.groups[i];
      if (group && group.displayValue) {
        filterChips.push({
          icon: 'icon-group-check',
          displayValue: group.displayValue,
          type: 'groups',
          index: i,
        });
      }
    }

    for (let i = 0; i < dataToTransform.sortings.length ?? 0; i++) {
      const sorting = dataToTransform.sortings[i];
      if (sorting && sorting.column && sorting.column.displayValue) {
        filterChips.push({
          icon:
            sorting.sortingType === CompiereDataGridSortModelType.ASC
              ? 'icon-tri-az'
              : sorting.sortingType === CompiereDataGridSortModelType.DESC
                ? 'icon-tri-za'
                : 'icon-tri',
          displayValue: sorting.column.displayValue,
          type: 'sortings',
          index: i,
        });
      }
    }
    return filterChips;
  }

  #getFilterStr(filterType: CompiereDataGridFilterType, filter: any, todayMode: boolean, needTime: boolean) {
    if (filterType === CompiereDataGridFilterType.TIME) {
      return DateUtils.formatStr(filter, 'LT');
    }

    if (filterType === CompiereDataGridFilterType.DATE && !todayMode) {
      return needTime ? DateUtils.formatLongLocaleStr(filter) : DateUtils.formatLocaleStr(filter);
    }

    return filter?.displayValue ?? filter;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * ********************** DATA TO DISPLAY **************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */

  public compiereDataGridToDataToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    requests: CompiereDataGridRequestJSON[]
  ): DataToDisplay[] {
    return requests.map((req) => this.singleCompiereDataGridToDataToDisplay(columnFilters, operatorFilters, req));
  }

  public singleCompiereDataGridToDataToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    request: CompiereDataGridRequestJSON
  ): DataToDisplay {
    const dataToDisplay: DataToDisplay = {
      favorite: request.label,
      isDefault: request.isDefault,
      widgetID: request.widgetID,
      groups: request.rowGroupCols.map((groupCol) =>
        columnFilters.items.find((columnFilter) => groupCol.id === columnFilter.id)
      ),
      sortings: request.sortModel.map((sortModel) => ({
        column: columnFilters.items.find(
          (columnName) => sortModel.colId === columnName.columnInfo.fieldEntity.field.ColumnName
        ),
        sortingType: sortModel.sort,
      })),
      filters: [],
      notUFData: { valueCols: request.valueCols },
    };
    dataToDisplay.filters = this.filterModelToFilterToDisplay(columnFilters, operatorFilters, request);

    return dataToDisplay;
  }

  public filterModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    operatorFilters: { items: OperatorFilterAutocomplete[] },
    f: CompiereDataGridRequestJSON
  ): FilterToDisplay[] {
    const filterToDisplays: FilterToDisplay[] = [];
    if (f.filterModel) {
      const keys = Object.keys(f.filterModel);
      for (const columnKey of keys) {
        const base: FilterToDisplay = {
          column: columnFilters.items.find(
            (columnFilter) =>
              columnFilter.columnInfo.fieldEntity.field.ColumnName === columnKey ||
              columnFilter.columnInfo.fieldEntity.field.ColumnSQL === columnKey
          ),
          filter: undefined,
          operator: undefined,
        };
        const filterModel = f.filterModel[columnKey];
        if (
          filterModel.operators.length > 0 &&
          filterModel.values.length > 0 &&
          filterModel.operators.length === filterModel.values.length
        ) {
          for (let i = 0; i < filterModel.operators.length; i++) {
            const operator: FilterModelOperator = filterModel.operators[i];
            const filterToDisplay: FilterToDisplay = Object.assign({}, base);
            filterToDisplay.operator = operatorFilters.items.find(
              (operatorFilter) =>
                operatorFilter.operator.type === operator &&
                operatorFilter.operator.filterType === f.filterModel[columnKey].filterType
            );
            let values = filterModel.values[i];
            if (
              (filterModel.filterType === CompiereDataGridFilterType.NUMBER ||
                filterModel.filterType === CompiereDataGridFilterType.SET) &&
              !Array.isArray(values) &&
              (operator === OperatorFilterType.EQUALS || operator === OperatorFilterType.NOT_EQUALS)
            ) {
              values = [values + ''];
            }
            filterToDisplay.filter = values;
            if (filterModel.configs && filterModel.configs.length) {
              filterToDisplay.configs = filterModel.configs[i];
              if (
                filterToDisplay.configs &&
                filterToDisplay.configs.calendarConfig &&
                (filterToDisplay.configs.calendarConfig as CalendarConfig).todayMode
              ) {
                filterToDisplay.filter = this.getTranslatedTodayValue(
                  filterToDisplay.configs.calendarConfig.todayValue
                );
              }
            }
            if (operator === 'between') {
              filterToDisplay.filterTo = filterModel.values[++i];
              if (filterModel.configs && filterModel.configs.length > i) {
                filterToDisplay.configsTo = filterModel.configs[i];
                if (
                  filterToDisplay.configsTo &&
                  filterToDisplay.configsTo.calendarConfig &&
                  (filterToDisplay.configsTo.calendarConfig as CalendarConfig).todayMode
                ) {
                  filterToDisplay.filterTo = this.getTranslatedTodayValue(
                    filterToDisplay.configsTo.calendarConfig.todayValue
                  );
                }
              }
            }
            filterToDisplays.push(filterToDisplay);
          }
        }
      }
    }
    return filterToDisplays;
  }

  public sortModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    s: CompiereDataGridRequestJSON
  ): SorterToDisplay[] {
    const sorterToDisplays: SorterToDisplay[] = [];
    if (s.sortModel) {
      for (const sortModelTmp of s.sortModel) {
        const columnFound = columnFilters.items.find(
          (columnFilter) => columnFilter.columnInfo.fieldEntity.field.ColumnName === sortModelTmp.colId
        );
        const base: SorterToDisplay = {
          column: columnFound
            ? columnFound
            : { id: sortModelTmp.colId, displayValue: sortModelTmp.colId, columnInfo: null },
          sortingType: undefined,
        };
        const sortToDisplay: SorterToDisplay = Object.assign({}, base);
        sortToDisplay.sortingType = sortModelTmp.sort;
        sorterToDisplays.push(sortToDisplay);
      }
    }
    return sorterToDisplays;
  }

  public groupModelToFilterToDisplay(
    columnFilters: { items: ColumnFilterAutocomplete[] },
    s: CompiereDataGridRequestJSON
  ): ColumnFilterAutocomplete[] {
    const groupToDisplays: ColumnFilterAutocomplete[] = [];
    if (s.rowGroupCols) {
      for (const groupColTmp of s.rowGroupCols) {
        const base = columnFilters.items.find((columnFilter) => columnFilter.id === groupColTmp.id);
        const groupToDisplay: ColumnFilterAutocomplete = Object.assign({}, base);
        groupToDisplays.push(groupToDisplay);
      }
    }
    return groupToDisplays;
  }

  /*
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * ********************* COMPIERE DATA GRID ************************
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */

  public az_dataToDisplayToCompiereDataGrid(
    dataToDisplay: AZ_DataToDisplay,
    tabID: number
  ): CompiereDataGridRequestJSON {
    const dataToTransform: AZ_DataToDisplay = this.az_transformDisplayOperators(cloneDeep(dataToDisplay));
    dataToTransform.filters = dataToTransform.filters.filter(
      (f) =>
        f.operator?.operator?.type === OperatorFilterType.IS_NULL ||
        f.operator?.operator?.type === OperatorFilterType.IS_NOT_NULL ||
        (f.filter !== undefined && f.filter !== null && f.filter !== '') ||
        (f.filterTo !== undefined && f.filterTo !== null && f.filterTo !== '')
    );
    return {
      label: dataToTransform.favorite,
      filterModel: this.az_filterToDisplayToFilterModel(dataToTransform),
      startRow: 0,
      endRow: this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize'),
      entityId: tabID,
      windowType: CompiereDataGridType.WINDOW,
      rowGroupCols: [],
      sortModel: [],
      valueCols: dataToDisplay.notUFData
        ? dataToDisplay.notUFData.valueCols
          ? dataToDisplay.notUFData.valueCols
          : []
        : [],
    };
  }

  public dataToDisplayToCompiereDataGrid(dataToDisplay: DataToDisplay, tabID: number): CompiereDataGridRequestJSON {
    const dataToTransform: DataToDisplay = this.transformDisplayOperators(cloneDeep(dataToDisplay));
    dataToTransform.filters = dataToTransform.filters.filter(
      (f) =>
        f.operator?.operator?.type === OperatorFilterType.IS_NULL ||
        f.operator?.operator?.type === OperatorFilterType.IS_NOT_NULL ||
        (f.filter !== undefined && f.filter !== null && f.filter !== '') ||
        (f.filterTo !== undefined && f.filterTo !== null && f.filterTo !== '')
    );

    return {
      label: dataToTransform.favorite,
      filterModel: this.filterToDisplayToFilterModel(dataToTransform),
      startRow: 0,
      endRow: this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize'),
      entityId: tabID,
      windowType: CompiereDataGridType.WINDOW,
      rowGroupCols: dataToTransform.groups.reduce<Omit<ColDataRequest, 'colId'>[]>((acc, group) => {
        if (group && group.id !== -1)
          acc.push({
            id: group.id,
            displayName: group.displayValue,
            field: group.columnInfo.fieldEntity.field.ColumnName,
          });
        return acc;
      }, []),
      sortModel: dataToTransform.sortings.reduce<SortModel[]>((acc, sorting) => {
        if (sorting.column && sorting.column.id !== -1)
          acc.push({
            colId: sorting.column.columnInfo
              ? sorting.column.columnInfo.fieldEntity.field.ColumnName
              : sorting.column.id,
            sort: sorting.sortingType,
          });
        return acc;
      }, []),
      valueCols: dataToDisplay.notUFData
        ? dataToDisplay.notUFData.valueCols
          ? dataToDisplay.notUFData.valueCols
          : []
        : [],
    };
  }

  public filterToDisplayToFilterModel(dataToTransform: DataToDisplay): {
    [columnName: string]: CompiereDataGridFilterModel;
  } {
    const filterModel: { [columnName: string]: CompiereDataGridFilterModel } = {};
    for (const f of dataToTransform.filters) {
      if (f.column.id !== -1 && f.column.displayValue) {
        const columnName = f.column.columnInfo.fieldEntity.ColumnName
          ? f.column.columnInfo.fieldEntity.ColumnName
          : f.column.columnInfo.fieldEntity.field.ColumnName;
        const values = this.#getFilterValue(f);
        if (filterModel.hasOwnProperty(columnName)) {
          filterModel[columnName].values.push(values);
          filterModel[columnName].operators.push(f.operator.operator.type);
          filterModel[columnName].configs.push(f.configs);
        } else {
          filterModel[columnName] = {
            filterType: f.operator.operator.filterType,
            values: [values],
            operators: [f.operator.operator.type],
            configs: [f.configs],
          };
        }
        if (f.operator.operator.filterType === CompiereDataGridFilterType.NUMBER) {
          for (let i = 0; i < filterModel[columnName].values.length; i++) {
            if (Array.isArray(filterModel[columnName].values[i])) {
              filterModel[columnName].values[i] = filterModel[columnName].values[i].map((value) => {
                if (typeof value === 'string') {
                  value = value.replace(',', '.');
                }
                if (!isNaN(parseFloat(value))) {
                  value = parseFloat(value);
                } else {
                  value = 0;
                }
                return value;
              });
            } else {
              if (filterModel[columnName].values[i] instanceof String) {
                filterModel[columnName].values[i] = filterModel[columnName].values[i].replace(',', '.');
              }
              if (!isNaN(parseFloat(filterModel[columnName].values[i]))) {
                filterModel[columnName].values[i] = parseFloat(filterModel[columnName].values[i]);
              } else {
                filterModel[columnName].values[i] = 0;
              }
            }
          }
        }
      }
    }
    return filterModel;
  }

  public az_filterToDisplayToFilterModel(dataToTransform: AZ_DataToDisplay): FilterModel {
    const filterModel: FilterModel = {};
    for (const f of dataToTransform.filters) {
      if (f.column.id !== -1 && f.column.displayValue) {
        const columnName = f.column.columnInfo.fieldEntity.ColumnName
          ? f.column.columnInfo.fieldEntity.ColumnName
          : f.column.columnInfo.fieldEntity.field.ColumnName;
        const values = this.#getFilterValue(f);
        if (filterModel.hasOwnProperty(columnName)) {
          filterModel[columnName].values.push(values);
          filterModel[columnName].operators.push(f.operator.operator.type);
          filterModel[columnName].configs.push(f.configs);
        } else {
          filterModel[columnName] = {
            filterType: f.operator.operator.filterType,
            values: [values],
            operators: [f.operator.operator.type],
            configs: [f.configs],
          };
        }
        if (f.operator.operator.filterType === CompiereDataGridFilterType.NUMBER) {
          for (let i = 0; i < filterModel[columnName].values.length; i++) {
            if (Array.isArray(filterModel[columnName].values[i])) {
              filterModel[columnName].values[i] = filterModel[columnName].values[i].map((_v) => {
                if (typeof _v === 'string') _v = _v.replace(',', '.');
                if (!isNaN(parseFloat(_v))) _v = parseFloat(_v);
                else _v = 0;
                return _v;
              });
            } else {
              if (filterModel[columnName].values[i] instanceof String)
                filterModel[columnName].values[i] = filterModel[columnName].values[i].replace(',', '.');

              if (!isNaN(parseFloat(filterModel[columnName].values[i])))
                filterModel[columnName].values[i] = parseFloat(filterModel[columnName].values[i]);
              else filterModel[columnName].values[i] = 0;
            }
          }
        }
      }
    }

    return filterModel;
  }

  private transformDisplayOperators(dataToDisplay: DataToDisplay): DataToDisplay {
    if (dataToDisplay.filters.filter((f) => f.column.id === -1 || f.operator.id === -1).length === 0) {
      const betweens = [];
      for (const f of dataToDisplay.filters) {
        if (f.operator.operator.type === 'between') betweens.push(f);
      }

      for (const f of betweens) {
        const index = dataToDisplay.filters.findIndex((fil) => fil === f);
        if (f.filter !== undefined && f.filterTo !== undefined && f.filter !== null && f.filterTo !== null) {
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: f.operator,
            configs: f.configs,
          };
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: f.operator,
            configs: f.configsTo,
          };
          dataToDisplay.filters.splice(index, 1, borneInf, borneSup);
        } else if (f.filter !== undefined && f.filter !== null && (f.filterTo === undefined || f.filterTo === null)) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.BIGGERTHANOREQUAL && op.filterType === f.column.columnInfo.filterType
          );
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator,
            },
            configs: f.configs,
          };
          dataToDisplay.filters.splice(index, 1, borneInf);
        } else if ((f.filter === undefined || f.filter === null) && f.filterTo !== undefined && f.filterTo !== null) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.LESSTHANOREQUAL && op.filterType === f.column.columnInfo.filterType
          );
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator,
            },
            configs: f.configsTo,
          };
          dataToDisplay.filters.splice(index, 1, borneSup);
        }
      }
    }
    return cloneDeep(dataToDisplay);
  }

  private az_transformDisplayOperators(dataToDisplay: AZ_DataToDisplay): AZ_DataToDisplay {
    if (dataToDisplay.filters.filter((f) => f.column.id === -1 || f.operator.id === -1).length === 0) {
      const betweens = [];
      for (const f of dataToDisplay.filters) {
        if (f.operator.operator.type === 'between') betweens.push(f);
      }

      for (const f of betweens) {
        const index = dataToDisplay.filters.findIndex((fil) => fil === f);
        if (f.filter !== undefined && f.filterTo !== undefined && f.filter !== null && f.filterTo !== null) {
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: f.operator,
            configs: f.configs,
          };
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: f.operator,
            configs: f.configsTo,
          };
          dataToDisplay.filters.splice(index, 1, borneInf, borneSup);
        } else if (f.filter !== undefined && f.filter !== null && (f.filterTo === undefined || f.filterTo === null)) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.BIGGERTHANOREQUAL && op.filterType === f.column.columnInfo.filterType
          );
          const borneInf: FilterToDisplay = {
            column: f.column,
            filter: f.filter,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator,
            },
            configs: f.configs,
          };
          dataToDisplay.filters.splice(index, 1, borneInf);
        } else if ((f.filter === undefined || f.filter === null) && f.filterTo !== undefined && f.filterTo !== null) {
          const operator = filterOperators.find(
            (op) => op.type === OperatorFilterType.LESSTHANOREQUAL && op.filterType === f.column.columnInfo.filterType
          );
          const borneSup: FilterToDisplay = {
            column: f.column,
            filter: f.filterTo,
            operator: {
              id: operator.id,
              displayValue: operator.label,
              operator: operator,
            },
            configs: f.configsTo,
          };
          dataToDisplay.filters.splice(index, 1, borneSup);
        }
      }
    }
    return cloneDeep(dataToDisplay);
  }

  public getTodayModeDate(todayValue: string, fieldEntity?: any): Date {
    const regexToday = /^\s*today\s*(?<operation>[\+|\-]{0,1})\s*$/i;
    const regexTodayNumber = /^\s*today\s*(?<operation>\+|\-)\s*(?<number>[\-]{0,1}\d+)\s*$/i;
    const regexTodayNumberUnit =
      /^\s*today\s*(?<operation>\+|\-)\s*(?<number>[\-]{0,1}\d+)\s*(?<unit>day|week|month|year)\s*$/i;
    let date: Date;

    if (regexToday.test(todayValue)) {
      date = new Date();
    } else if (regexTodayNumber.test(todayValue)) {
      const result = regexTodayNumber.exec(todayValue);
      const { operation, number } = result.groups;
      date = (operation === '+' ? DateUtils.add : DateUtils.subtract)(Date.now(), number, 'day');
    } else if (regexTodayNumberUnit.test(todayValue)) {
      const result = regexTodayNumberUnit.exec(todayValue);
      const { operation, number, unit } = result.groups;
      date = (operation === '+' ? DateUtils.add : DateUtils.subtract)(Date.now(), number, unit as any);
    }
    if (
      fieldEntity &&
      fieldEntity.field &&
      fieldEntity.field.AD_Reference_ID !== 16 &&
      fieldEntity.field.AD_Reference_ID !== 24
    ) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }

    return date;
  }

  private getTranslatedTodayValue(todayValue: string): string {
    const regexToday = /^\s*today\s*(?<operation>[\+|\-]{0,1})\s*$/i;
    const regexTodayNumber = /^\s*today\s*(?<operation>\+|\-)\s*(?<number>[\-]{0,1}\d+)\s*$/i;
    const regexTodayNumberUnit =
      /^\s*today\s*(?<operation>\+|\-)\s*(?<number>[\-]{0,1}\d+)\s*(?<unit>day|week|month|year)\s*$/i;

    if (regexToday.test(todayValue)) {
      return this.translator.instant('calendar.today');
    } else if (regexTodayNumber.test(todayValue)) {
      const { operation, number } = regexTodayNumber.exec(todayValue).groups;
      return `${this.translator.instant('calendar.today')} ${operation} ${number}`;
    } else if (regexTodayNumberUnit.test(todayValue)) {
      const { operation, number, unit } = regexTodayNumberUnit.exec(todayValue).groups;
      return `${this.translator.instant('calendar.today')} ${operation} ${number} ${this.translator.instant(
        'calendar.unit.' + unit
      )}`;
    } else {
      throw new IupicsMessage('todayValue', 'An error occured while trying to parse todayValue', 'error');
    }
  }

  /*
   * ************************************************************
   * ************************************************************
   * ************************************************************
   * *********************** FILTER UTILS ***********************
   * ************************************************************
   * ************************************************************
   * ************************************************************
   */

  public cleanDataToDisplay(dataToDisplay: DataToDisplay) {
    dataToDisplay.filters = dataToDisplay.filters.filter(
      (filter) =>
        filter.column &&
        filter.operator &&
        (filter.column.id !== -1 ||
          filter.operator.id !== -1 ||
          filter.filter !== '' ||
          (filter.operator.id !== -1 && filter.operator.operator.isRange && filter.filterTo !== ''))
    );
    dataToDisplay.groups = dataToDisplay.groups.filter((group) => group.id !== -1);
    dataToDisplay.sortings = dataToDisplay.sortings.filter((sorting) => sorting.column && sorting.column.id !== -1);
    return dataToDisplay;
  }

  public az_cleanDataToDisplay(dataToDisplay: AZ_DataToDisplay): AZ_DataToDisplay {
    dataToDisplay.filters = dataToDisplay.filters.filter(
      (filter) =>
        filter.column &&
        filter.operator &&
        (filter.column.id !== -1 ||
          filter.operator.id !== -1 ||
          filter.filter !== '' ||
          (filter.operator.id !== -1 && filter.operator.operator.isRange && filter.filterTo !== ''))
    );
    return dataToDisplay;
  }

  /*
   * ************************************************************
   * ************************************************************
   * ************************************************************
   * ************************ APIZ GRID *************************
   * ************************************************************
   * ************************************************************
   * ************************************************************
   */
  /** Les applied items viennent d'apiz-grid et les FilterToDisplay sont ce qui est utilisé pour faire le lien dans apiz. */
  public appliedItemsToFilterToDisplay(
    columnFilters: ColumnFilterAutocomplete[],
    operators: OperatorFilterAutocomplete[],
    appliedItems: AppliedItem<'filter'>[]
  ) {
    const filterToDisplays: FilterToDisplay[] = [];
    if (appliedItems && appliedItems.length) {
      for (const appliedItem of appliedItems) {
        const column = columnFilters.find(
          (cf) =>
            (cf.columnInfo.fieldEntity.ColumnName ?? cf.columnInfo.fieldEntity.field.ColumnName) === appliedItem.colId
        );

        const operator = operators.find(
          (op) => op.operator.type === appliedItem.operators[0] && op.operator.filterType === appliedItem.filterType
        );
        let filter = undefined,
          filterTo = undefined,
          configs = undefined,
          configsTo = undefined;

        if (appliedItem.values.length > 0) filter = appliedItem.values[0];
        if (appliedItem.values.length > 1) filterTo = appliedItem.values[1];
        if (appliedItem.configs?.length > 0) configs = appliedItem.configs[0];
        if (appliedItem.configs?.length > 1) configsTo = appliedItem.configs[1];

        if (configs?.calendarConfig?.todayMode && configs?.calendarConfig?.todayValue)
          filter = this.getTranslatedTodayValue(configs.calendarConfig.todayValue);
        if (configsTo?.calendarConfig?.todayMode && configsTo?.calendarConfig?.todayValue)
          filterTo = this.getTranslatedTodayValue(configsTo.calendarConfig.todayValue);

        const base: FilterToDisplay = {
          column,
          filter,
          filterTo,
          operator,
          configs,
          configsTo,
        };
        filterToDisplays.push(base);
      }
    }
    return filterToDisplays;
  }

  public dataToDisplayToAppliedItems(dataToDisplay: AZ_DataToDisplay): AppliedItem<'filter'>[] {
    const filters = [...dataToDisplay.filters].filter(
      (f) =>
        f.operator?.operator?.type === OperatorFilterType.IS_NULL ||
        f.operator?.operator?.type === OperatorFilterType.IS_NOT_NULL ||
        (f.filter !== undefined && f.filter !== null && f.filter !== '') ||
        (f.filterTo !== undefined && f.filterTo !== null && f.filterTo !== '')
    );

    const appliedItems: AppliedItem<'filter'>[] = [];

    const getValue = (f: FilterToDisplay, key: keyof Pick<FilterToDisplay, 'filter' | 'filterTo'>): {} => {
      if (f[key] === undefined) return f[key];
      let value =
        f.operator.operator.filterType === CompiereDataGridFilterType.DATE ||
        f.operator.operator.filterType === CompiereDataGridFilterType.TIME
          ? DateUtils.formatStr(
              f.configs?.calendarConfig?.todayMode
                ? this.getTodayModeDate(f.configs.calendarConfig.todayValue, f.column.columnInfo.fieldEntity)
                : f[key]
            )
          : f.operator.operator.type === 'contains' && !Array.isArray(f[key]) && !(f[key] instanceof Object)
            ? f[key].trim()
            : f[key];
      if (f.operator.operator.filterType === CompiereDataGridFilterType.NUMBER) {
        if (Array.isArray(value)) {
          value = value.map((_v) => {
            if (typeof _v === 'string') _v = _v.replace(',', '.');
            if (!isNaN(parseFloat(_v))) _v = parseFloat(_v);
            else _v = 0;
            return _v;
          });
        } else {
          if (value instanceof String) value = value.replace(',', '.');
          if (!isNaN(parseFloat(value))) value = parseFloat(value);
          else value = 0;
        }
      }
      return value;
    };

    for (const f of filters) {
      if (f.column.id !== -1 && f.column.displayValue) {
        const columnName = f.column.columnInfo.fieldEntity.ColumnName
          ? f.column.columnInfo.fieldEntity.ColumnName
          : f.column.columnInfo.fieldEntity.field.ColumnName;
        const value = getValue(f, 'filter');
        const valueTo = getValue(f, 'filterTo');
        const appliedItem: AppliedItem<'filter'> = {
          type: 'filter',
          colId: columnName,
          filterType: f.operator.operator.filterType,
          operators: [f.operator.operator.type],
          values: [value].concat(!!valueTo ? [valueTo] : []),
          configs: [f.configs].concat(!!f.configsTo ? [f.configsTo] : []),
        };
        appliedItems.push(appliedItem);
      }
    }

    return appliedItems;
  }

  #getFilterValue(f: FilterToDisplay) {
    if (
      f.operator.operator.filterType === CompiereDataGridFilterType.DATE ||
      f.operator.operator.filterType === CompiereDataGridFilterType.TIME
    ) {
      return DateUtils.formatStr(
        f.configs?.calendarConfig?.todayMode
          ? this.getTodayModeDate(f.configs.calendarConfig.todayValue, f.column.columnInfo.fieldEntity)
          : f.filter
      );
    }

    if (Array.isArray(f.filter)) {
      return f.filter.map((item) => (item instanceof Object ? item.id : item));
    }

    if (f.filter instanceof Object) {
      return f.filter.id;
    }

    if (f.operator.operator.type === 'contains') {
      return f.filter.trim();
    }

    return f.filter;
  }
}

export interface AZ_DataToDisplay {
  isDefault?: boolean;
  widgetID?: number;
  favorite: string;
  filters: FilterToDisplay[];
  notUFData: {
    valueCols?: ColDataRequest[];
  };
}

export interface DataToDisplay {
  isDefault?: boolean;
  widgetID?: number;
  favorite: string;
  filters: FilterToDisplay[];
  groups: ColumnFilterAutocomplete[];
  sortings: SorterToDisplay[];
  notUFData: {
    valueCols?: ColDataRequest[];
  };
}

export interface SorterToDisplay {
  column: ColumnFilterAutocomplete;
  sortingType: SortType;
}

export interface FilterToDisplay {
  column: ColumnFilterAutocomplete;
  operator: OperatorFilterAutocomplete;
  filter: any;
  filterTo?: any;
  configs?: any;
  configsTo?: any;
}

export interface FilterChip {
  displayValue: string;
  icon: string;
  type: string;
  index: number;
  columnName?: string;
}
