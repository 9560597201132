import { Injectable } from '@angular/core';
import TabGroupUiComponent from '@web-desktop/components/menu-top/components/tab-group-ui/tab-group-ui.component';
import { Tab } from '@web-desktop/models/Tab';
import { has, isNil } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ActiveTabManagerService {
  #activeTab!: Tab;

  private listeners: {
    [key: string]: { listen: (event: ActiveTabEvent) => void; listenerOptions: ActiveTabListenerOptions }[];
  } = {};

  private readonly defaultOptions: ActiveTabListenerOptions = { inSplitView: true };

  setActiveTab(tab?: Tab): void {
    if (isNil(this.#activeTab) || isNil(tab) || tab.id !== this.#activeTab.id) {
      // on prévient qu'on n'est plus actif si un tab est défini
      this.emit(false);
      this.#activeTab = tab;
      this.emit(true);
    }
  }

  addListener(tab: Tab, listen: (event: ActiveTabEvent) => void, listenerOptions = this.defaultOptions): () => void {
    this.listeners[tab.id] = [...(this.listeners[tab.id] ?? []), { listen, listenerOptions }];
    return () => {
      this.listeners[tab.id] = this.listeners[tab.id].filter((listener) => listener.listen !== listen);
    };
  }

  isActive(tab: Tab): boolean {
    return this.#activeTab && this.#activeTab.id === tab.id;
  }

  private emit(active: boolean) {
    if (this.#activeTab && has(this.listeners, this.#activeTab.id)) {
      for (const listener of this.listeners[this.#activeTab.id]) {
        listener.listen({
          type: active ? ActiveTabEventType.TAB_ACTIVE : ActiveTabEventType.TAB_INACTIVE,
          listenerOptions: listener.listenerOptions,
        });
      }
    }

    // on prévient ceux dans le tabGroup si le listener est configuré comme tel
    if (this.#activeTab instanceof TabGroupUiComponent) {
      for (const tabRef of (<TabGroupUiComponent>this.#activeTab).components) {
        for (const listener of this.listeners[tabRef.instance.id] ?? []) {
          if (listener.listenerOptions.inSplitView) {
            listener.listen({
              type: active ? ActiveTabEventType.TAB_GROUP_ACTIVE : ActiveTabEventType.TAB_GROUP_INACTIVE,
              listenerOptions: listener.listenerOptions,
            });
          }
        }
      }
    }
  }
}

export interface ActiveTabEvent {
  type: ActiveTabEventType;
  listenerOptions: ActiveTabListenerOptions;
}

export interface ActiveTabListenerOptions {
  inSplitView: boolean;
}

export enum ActiveTabEventType {
  TAB_ACTIVE = 'tab_active',
  TAB_INACTIVE = 'tab_inactive',
  TAB_GROUP_ACTIVE = 'tab_group_active',
  TAB_GROUP_INACTIVE = 'tab_group_inactive',
}
