import { DOCUMENT, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Global } from '@iupics-manager/models/global-var';
import { TextLimitPipe } from '@iupics-util/pipes/text-limit/text-limit.pipe';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { Utils } from '@iupics-util/tools/util';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { OverlayService, PrimeNGConfig } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Calendar } from 'primeng/calendar';
import { DomHandler } from 'primeng/dom';
import { ZIndexUtils } from 'primeng/utils';

export const CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PrimeCalendarComponent),
  multi: true,
};
@Component({
  selector: 'iu-prime-calendar',
  templateUrl: './prime-calendar.component.html',
  styleUrls: ['./prime-calendar.component.scss'],
  animations: Global.overlayAnimationCalendar,
  providers: [CALENDAR_VALUE_ACCESSOR],
  standalone: true,
  imports: [NgClass, NgStyle, NgTemplateOutlet, FormsModule, ButtonModule, TextLimitPipe],
})
export default class PrimeCalendarComponent extends Calendar implements OnInit {
  @Input() columnName: string;
  @Input() label: string;
  @Input() isLabelDisplay: boolean;
  @Input() isStandalone = false;
  @Input() isGridEditor: boolean;
  @Input() iupicsLocale: string;
  @Input() isFieldOnly = false;
  @Input() hasTodayBtn = false;
  @Input() calendarConfig: CalendarConfig = { todayMode: false };

  @Output() fieldChange = new EventEmitter<any>();
  @Output() checkGridEditorEmitter = new EventEmitter<any>();
  @Output() selectItemEmitter = new EventEmitter<any>();
  @Output() calendarConfigChange = new EventEmitter<CalendarConfig>();

  @ViewChild('inputfield', { static: false }) inputRef: ElementRef<HTMLInputElement>;

  mandatoryCss: string;
  showTransitionOptions = '225ms ease-out';
  hideTransitionOptions = '195ms ease-in';

  todayModeSuggestions: string[] = [];

  private timeOnlyInfos: {
    date: any;
    month: any;
    fullYear: any;
    seconds: any;
    milliseconds: any;
  };

  @Input() get showTime(): boolean {
    return this._showTime;
  }

  set showTime(showTime: boolean) {
    this._showTime = showTime;
    if (
      this._showTime &&
      this.isStandalone &&
      this.currentHour === undefined &&
      this.value &&
      !isNaN(Date.parse(this.value))
    ) {
      this.value = new Date(this.value);
      this.initTime(this.value || new Date());
    }
  }

  get locale() {
    return this._locale;
  }
  set locale(newLocale) {
    this._locale = newLocale;
  }

  constructor(
    @Inject(DOCUMENT) document: Document,
    el: ElementRef,
    renderer: Renderer2,
    cd: ChangeDetectorRef,
    config: PrimeNGConfig,
    private translateService: TranslateService,
    zone: NgZone,
    overlayService: OverlayService
  ) {
    super(document, el, renderer, cd, zone, config, overlayService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.appendTo = 'body';
    // TODO: check if this really useful
    DateUtils.setLocale(this.iupicsLocale);
    this.locale = this.translateService.store.translations[this.iupicsLocale.replace(/-/g, '_')].calendar;
    this.dateFormat = this.locale.dateFormat;
    const date: Date = new Date();
    this.initTime(date);
    this.createMonth(date.getMonth(), date.getFullYear());
    this.changeFloatLabelCss();
    this.selectOtherMonths = true;
    if (this.timeOnly) {
      this.saveInfoForTimeOnly();
    }
    // TODO: this option is deprecated
    this.yearRange = `${date.getFullYear() - 90}:${date.getFullYear() + 10}`;
  }

  private saveInfoForTimeOnly() {
    const _date: Date = this.value || new Date();
    this.timeOnlyInfos = {
      date: _date.getDate(),
      month: _date.getMonth(),
      fullYear: _date.getFullYear(),
      seconds: _date.getSeconds(),
      milliseconds: _date.getMilliseconds(),
    };
  }

  setMandatoryCss(css: string) {
    this.mandatoryCss = css;
  }

  changeFloatLabelCss() {}

  onDateSelect(event: MouseEvent, dateMeta: any) {
    event.stopPropagation();
    this.hideOnDateTimeSelect = !(this.showTime && !this.timeOnly);
    super.onDateSelect(event, dateMeta);
  }

  onTodayButtonClick(event: any) {
    event.stopPropagation();
    this.hideOnDateTimeSelect = true;
    super.onTodayButtonClick(event);
  }

  onOKButtonClick(event: any) {
    event.stopPropagation();
    if (this.overlayVisible) {
      this.hideOverlayAndResetIndex();
    }
  }

  cancelFocus(event: MouseEvent) {
    if (event && event.button === 2) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onInputFocus(event: Event) {
    this.focus = true;
    if (this.calendarConfig.todayMode) {
      this.updateTodaySuggestions();
    }
    if (this.showOnFocus) {
      if (this.isGridEditor) {
        this.checkGridEditorEmitter.emit();
      }
      this.showOverlay();
    }
    this.onFocus.emit(event);
  }

  onInputClick() {
    if (this.overlay && this.autoZIndex) {
      ZIndexUtils.set('overlay', this.overlay, this.baseZIndex);
    }
  }

  onInputBlur(event: Event) {
    if (!event || this.isOutsideClicked(event)) {
      if (this.overlayVisible) {
        this.hideOverlayAndResetIndex();
      }
      if (this.calendarConfig.todayMode) {
        this.value = (event.target as HTMLInputElement).value;
      }
      this.focus = false;
      this.keepInvalid = false;
      this.updateInputfield();
      this.onModelTouched();
      if (this.calendarConfig.todayMode) {
        this.updateTodaySuggestions();
      }
    }
  }
  isOutsideClicked(event) {
    let target = event.target;
    if (event.type === 'blur') {
      if (event.relatedTarget) {
        target = event.relatedTarget;
      } else {
        return true;
      }
    }
    return !(
      this.el.nativeElement.isSameNode(target) ||
      this.isNavIconClicked(event) ||
      this.el.nativeElement.contains(target) ||
      (this.overlay && this.overlay.contains(target))
    );
  }
  updateInputfield(updateCalendarConfig = false) {
    this.setFieldValue();
    if (this.calendarConfig.todayMode || updateCalendarConfig) {
      this.calendarConfig.todayValue = this.getTodayModeValue(this.value);
      this.calendarConfigChange.emit(this.calendarConfig);
    }
    this.fieldChange.emit(this.value);
    this.selectItemEmitter.emit();
  }

  setFieldValue() {
    let formattedValue = '';
    if (!this.calendarConfig.todayMode) {
      if (this.value && !this.timeOnly) {
        if (this.isSingleSelection()) {
          formattedValue = this.formatValue(this.value);
        } else if (this.isMultipleSelection()) {
          for (let i = 0; i < this.value.length; i++) {
            formattedValue += this.formatValue(this.value[i]);
            if (i !== this.value.length - 1) {
              formattedValue += ', ';
            }
          }
        } else if (this.isRangeSelection()) {
          if (this.value && this.value.length) {
            formattedValue = this.formatValue(this.value[0]);
            if (this.value[1]) {
              formattedValue += ' - ' + this.formatValue(this.value[1]);
            }
          }
        }
      }
    } else {
      formattedValue = this.value;
    }
    if (this.timeOnly && !this.calendarConfig.todayMode) {
      this.inputFieldValue = this.formatTime(this.value);
    } else {
      this.inputFieldValue = formattedValue;
    }
    this.updateFilledState();
    if (this.inputfieldViewChild && this.inputfieldViewChild.nativeElement) {
      this.inputfieldViewChild.nativeElement.value = this.inputFieldValue;
    }
    this.changeFloatLabelCss();
    if (isNil(this.value)) {
      this.inputFieldValue = null;
    }
  }

  private formatValue(value: string): string {
    if (this.calendarConfig?.todayMode) {
      return value;
    }

    if (this.showTime) {
      return DateUtils.formatLongLocaleStr(value);
    }

    return DateUtils.formatLocaleStr(value);
  }

  onChange(event) {
    this.updateInputfield();
  }

  onUserInput(event: InputEvent) {
    // IE 11 Workaround for input placeholder : https://github.com/primefaces/primeng/issues/2026
    if (this.calendarConfig.todayMode) {
      this.updateTodaySuggestions();
      return;
    }
    if (!this.isKeydown) {
      return;
    }
    if (this.timeOnly) {
      if (event.inputType === 'deleteContentBackward') {
        if (
          (event.target as HTMLInputElement).value.length === 2 ||
          (event.target as HTMLInputElement).value.length === 5
        ) {
          (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(0, -1);
        }
      } else if (!/[0-9]|:/.test(event.data)) {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(0, -1);
      } else if ((event.target as HTMLInputElement).value.length === 2) {
        (event.target as HTMLInputElement).value += ':';
      }
    } else {
      if (event.inputType === 'deleteContentBackward') {
        if (
          (event.target as HTMLInputElement).value.length === 2 ||
          (event.target as HTMLInputElement).value.length === 5
        ) {
          (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(0, -1);
        }
      } else if (event.data === '/') {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(0, -1);
      } else if (isNaN(Number(event.data)) || event.data === ' ') {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(0, -1);
      } else if (
        (event.target as HTMLInputElement).value.length === 2 ||
        (event.target as HTMLInputElement).value.length === 5
      ) {
        (event.target as HTMLInputElement).value += '/';
      } else if (!this.showTime && (event.target as HTMLInputElement).value.length > 10) {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(0, 10);
      }

      if (this.showTime) {
        if ((event.target as HTMLInputElement).value.length === 10) {
          (event.target as HTMLInputElement).value += ' ';
        } else if ((event.target as HTMLInputElement).value.length === 13) {
          (event.target as HTMLInputElement).value += ':';
        } else if ((event.target as HTMLInputElement).value.length === 16) {
          if (event.data === 'a' || event.data === 'A') {
            (event.target as HTMLInputElement).value += 'AM';
          } else if (event.data === 'p' || event.data === 'P') {
            (event.target as HTMLInputElement).value += 'PM';
          }
        } else if ((event.target as HTMLInputElement).value.length > 18) {
          (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.slice(0, 18);
        }
      }
    }

    this.isKeydown = false;

    let val: string = (event.target as HTMLInputElement).value;
    if (this.timeOnly) {
      const s = val.split(':');
      if (Number.parseInt(s[0], 10) > 23) {
        s[0] = '23';
      }
      if (Number.parseInt(s[1], 10) > 59) {
        s[1] = '59';
      }
      val = s.join(':');
      (event.target as HTMLInputElement).value = val;
    }

    val = val.endsWith(':') ? val + '00' : val;

    try {
      const value: Date = this.timeOnly ? this.parseDateTime(val) : this.parseValueFromString(val);
      if (this.timeOnly) {
        value.setDate(this.timeOnlyInfos.date);
        value.setMonth(this.timeOnlyInfos.month);
        value.setFullYear(this.timeOnlyInfos.fullYear);
        value.setSeconds(this.timeOnlyInfos.seconds);
        value.setMilliseconds(this.timeOnlyInfos.milliseconds);
      }
      if (this.isSelectable(value.getDate(), value.getMonth(), value.getFullYear(), false) || this.timeOnly) {
        this.updateModel(value);
        this.updateUI();
        if (this.isGridEditor) {
          this.checkGridEditorEmitter.emit();
        }
      }
    } catch (err) {
      // invalid date
      this.updateModel(null);
    }

    this.filled = val != null && val.length > 0;
    this.onInput.emit(event);
  }

  parseValueFromString(text: string): Date {
    if (!text || text.trim().length === 0) {
      return null;
    } else if (text.trim().length === 2 || text.trim().length === 5) {
      this.inputRef.nativeElement.value += '/';
    }

    let value: any;

    if (this.isSingleSelection()) {
      value = this.parseDateTime(text);
    } else if (this.isMultipleSelection()) {
      const tokens = text.split(',');
      value = [];
      for (const token of tokens) {
        value.push(this.parseDateTime(token.trim()));
      }
    } else if (this.isRangeSelection()) {
      const tokens = text.split(' - ');
      value = [];
      for (let i = 0; i < tokens.length; i++) {
        value[i] = this.parseDateTime(tokens[i].trim());
      }
    }

    return value;
  }

  onTimePickerElementMouseDown(event: Event, type: number, direction: number) {
    if (!this.disabled) {
      this.repeat(event, null, type, direction);
      event.preventDefault();
    }
  }

  onTimePickerElementMouseUp(event: Event) {
    if (!this.disabled) {
      this.clearTimePickerTimer();
      this.updateTime();
    }
  }

  repeat(event: Event, interval: number, type: number, direction: number) {
    const i = interval || 500;

    this.clearTimePickerTimer();
    this.timePickerTimer = setTimeout(() => {
      this.repeat(event, 100, type, direction);
    }, i);

    switch (type) {
      case 0:
        if (direction === 1) {
          this.incrementHour(event);
        } else {
          this.decrementHour(event);
        }
        break;

      case 1:
        if (direction === 1) {
          this.incrementMinute(event);
        } else {
          this.decrementMinute(event);
        }
        break;

      case 2:
        if (direction === 1) {
          this.incrementSecond(event);
        } else {
          this.decrementSecond(event);
        }
        break;
    }
  }

  updateTime() {
    let value = this.value;
    if (this.isRangeSelection()) {
      value = this.value[1] || this.value[0];
    }
    if (this.isMultipleSelection()) {
      value = this.value[this.value.length - 1];
    }
    value = value ? new Date(value.getTime()) : new Date();

    if (this.value) {
      if (this.hourFormat == '12') {
        if (this.currentHour === 12) {
          value.setHours(this.pm ? 12 : 0);
        } else {
          value.setHours(this.pm ? this.currentHour + 12 : this.currentHour);
        }
      } else {
        value.setHours(this.currentHour);
      }

      value.setMinutes(this.currentMinute);
      value.setSeconds(this.currentSecond);
    } else {
      this.currentHour = value.getHours();
    }
    if (this.isRangeSelection()) {
      if (this.value[1]) {
        value = [this.value[0], value];
      } else {
        value = [value, null];
      }
    }

    if (this.isMultipleSelection()) {
      value = [...this.value.slice(0, -1), value];
    }

    this.updateModel(value);
    this.onSelect.emit(value);
    this.setFieldValue();
    this.updateInputfield();
  }

  showOverlay() {
    if (!this.overlayVisible) {
      if (!this.calendarConfig.todayMode) {
        this.updateUI();
      } else if (this.todayModeSuggestions.length === 0) {
        return;
      }
      this.overlayVisible = true;
      if (this.timeOnly && this.inputFieldValue === null) {
        this.onTimePickerElementMouseDown(new MouseEvent('click'), 0, 0);
        this.onTimePickerElementMouseUp(new MouseEvent('click'));
      }
    }
  }

  onButtonClick(event, inputfield) {
    if (this.isGridEditor) {
      this.checkGridEditorEmitter.emit();
    } else {
      if (!this.overlayVisible) {
        inputfield.focus();
        this.showOverlay();
      } else {
        this.hideOverlayAndResetIndex();
      }
    }
  }

  navForward(event) {
    event.stopPropagation();

    if (this.disabled) {
      event.preventDefault();
      return;
    }

    if (this.view === 'month') {
      this.incrementYear();
    } else {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.incrementYear();
      } else {
        this.currentMonth++;
      }

      this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
      this.createMonths(this.currentMonth, this.currentYear);
    }
  }
  navBackward(event) {
    event.stopPropagation();

    if (this.disabled) {
      event.preventDefault();
      return;
    }

    if (this.view === 'month') {
      this.decrementYear();
    } else {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.decrementYear();
      } else {
        this.currentMonth--;
      }

      this.onMonthChange.emit({ month: this.currentMonth + 1, year: this.currentYear });
      this.createMonths(this.currentMonth, this.currentYear);
    }
  }
  @HostListener('document:keydown.tab', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    if (this.overlayVisible) {
      this.hideOverlayAndResetIndex();
    }
  }

  onTodayModeChange(todayMode: boolean) {
    this.hideOverlayAndResetIndex();
    this.calendarConfig.todayMode = todayMode;
    this.value = this.calendarConfig.todayMode ? `${this.translateService.instant('calendar.today')} ` : null;
    this.updateInputfield(true);
  }

  //#region today mode
  private updateTodaySuggestions() {
    const regexToday = new RegExp(
      `^${Utils.cleanUpSpecialChars(this.translateService.instant('calendar.today'), true)}$`,
      'i'
    );
    const regexTodayNumber = new RegExp(
      `^${Utils.cleanUpSpecialChars(this.translateService.instant('calendar.today'), true)}\\s*[\\+|\\-]\\s*$`,
      'i'
    );
    const regexTodayNumberUnit = new RegExp(
      `^${Utils.cleanUpSpecialChars(this.translateService.instant('calendar.today'), true)}\\s*[\\+|\\-]\\s*\\d+$`,
      'i'
    );

    const value = this.inputRef.nativeElement.value.trim();
    if (regexToday.test(value) || !value) {
      this.todayModeSuggestions = [
        `${this.translateService.instant('calendar.today')} + X ${this.translateService.instant('calendar.unit.day')}`,
        `${this.translateService.instant('calendar.today')} + X ${this.translateService.instant('calendar.unit.week')}`,
        `${this.translateService.instant('calendar.today')} + X ${this.translateService.instant(
          'calendar.unit.month'
        )}`,
        `${this.translateService.instant('calendar.today')} + X ${this.translateService.instant('calendar.unit.year')}`,
        `${this.translateService.instant('calendar.today')} - X ${this.translateService.instant('calendar.unit.day')}`,
        `${this.translateService.instant('calendar.today')} - X ${this.translateService.instant('calendar.unit.week')}`,
        `${this.translateService.instant('calendar.today')} - X ${this.translateService.instant(
          'calendar.unit.month'
        )}`,
        `${this.translateService.instant('calendar.today')} - X ${this.translateService.instant('calendar.unit.year')}`,
      ];
    } else if (regexTodayNumber.test(value)) {
      this.todayModeSuggestions = [
        `${value} X ${this.translateService.instant('calendar.unit.day')}`,
        `${value} X ${this.translateService.instant('calendar.unit.week')}`,
        `${value} X ${this.translateService.instant('calendar.unit.month')}`,
        `${value} X ${this.translateService.instant('calendar.unit.year')}`,
      ];
    } else if (regexTodayNumberUnit.test(value)) {
      this.todayModeSuggestions = [
        `${value} ${this.translateService.instant('calendar.unit.day')}`,
        `${value} ${this.translateService.instant('calendar.unit.week')}`,
        `${value} ${this.translateService.instant('calendar.unit.month')}`,
        `${value} ${this.translateService.instant('calendar.unit.year')}`,
      ];
    } else {
      this.todayModeSuggestions = [];
    }
    if (this.focus && !this.overlayVisible && this.todayModeSuggestions.length) {
      this.showOverlay();
    } else if (this.focus && this.overlayVisible && !this.todayModeSuggestions.length) {
      this.hideOverlayAndResetIndex();
    }
  }

  hideOverlayAndResetIndex() {
    this.hideOverlay();
    ZIndexUtils.clear(this.overlay);
  }
  onSuggestionClick(event: Event, suggestion: string) {
    event.stopPropagation();
    this.value = suggestion.trim().replace(/\s*X$/, '');
    this.inputRef.nativeElement.value = this.value;
    this.updateInputfield();
    this.updateTodaySuggestions();
    this.inputRef.nativeElement.focus();
  }

  private getTodayModeValue(value: string) {
    if (!value) return ``;

    const regexToday = new RegExp(
      `^\\s*${Utils.cleanUpSpecialChars(
        this.translateService.instant('calendar.today'),
        true
      )}\\s*(?<operation>[\\+|\\-]{0,1})\\s*$`,
      'i'
    );
    const regexTodayNumber = new RegExp(
      `^\\s*${Utils.cleanUpSpecialChars(
        this.translateService.instant('calendar.today'),
        true
      )}\\s*(?<operation>\\+|\\-)\\s*(?<number>[\\-]{0,1}\\d+)\\s*$`,
      'i'
    );
    const regexTodayNumberUnit = new RegExp(
      `^\\s*${Utils.cleanUpSpecialChars(
        this.translateService.instant('calendar.today'),
        true
      )}\\s*(?<operation>\\+|\\-)\\s*(?<number>[\\-]{0,1}\\d+)\\s*(?<unit>${Utils.cleanUpSpecialChars(
        this.translateService.instant('calendar.unit.day'),
        true
      )}|${Utils.cleanUpSpecialChars(
        this.translateService.instant('calendar.unit.week'),
        true
      )}|${Utils.cleanUpSpecialChars(
        this.translateService.instant('calendar.unit.month'),
        true
      )}|${Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.year'), true)})\\s*$`,
      'i'
    );
    let valueToTest = value
      .replace(
        this.translateService.instant('calendar.unit.day'),
        Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.day'))
      )
      .replace(
        this.translateService.instant('calendar.unit.week'),
        Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.week'))
      )
      .replace(
        this.translateService.instant('calendar.unit.year'),
        Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.year'))
      );
    if (regexToday.test(valueToTest)) {
      return `today`;
    } else if (regexTodayNumber.test(valueToTest)) {
      const result = regexTodayNumber.exec(valueToTest);
      const { operation, number } = result.groups;
      return `today ${operation} ${number}`;
    } else if (regexTodayNumberUnit.test(valueToTest)) {
      const result = regexTodayNumberUnit.exec(valueToTest);
      const { operation, number, unit } = result.groups;
      return `today ${operation} ${number} ${this.getUnit(unit)}`;
    }
  }

  private getUnit(unit: string): string {
    switch (unit) {
      case Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.day', {})):
        return 'day';
      case Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.week')):
        return 'week';
      case Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.month')):
        return 'month';
      case Utils.cleanUpSpecialChars(this.translateService.instant('calendar.unit.year')):
        return 'year';
      default:
        return 'day';
    }
  }

  initFocusableCell() {
    let cell;

    if (this.currentView === 'month') {
      const cells = DomHandler.find(
        this.contentViewChild?.nativeElement,
        '.p-monthpicker .p-monthpicker-month:not(.p-disabled)'
      );
      const selectedCell = DomHandler.findSingle(
        this.contentViewChild.nativeElement,
        '.p-monthpicker .p-monthpicker-month.p-highlight'
      );
      cells.forEach(({ tabIndex }) => (tabIndex = -1));
      cell = selectedCell || cells[0];

      if (cells.length === 0) {
        const disabledCells = DomHandler.find(
          this.contentViewChild?.nativeElement,
          '.p-monthpicker .p-monthpicker-month.p-disabled[tabindex = "0"]'
        );
        disabledCells.forEach(({ tabIndex }) => (tabIndex = -1));
      }
    } else if (this.currentView === 'year') {
      const cells = DomHandler.find(
        this.contentViewChild?.nativeElement,
        '.p-yearpicker .p-yearpicker-year:not(.p-disabled)'
      );
      const selectedCell = DomHandler.findSingle(
        this.contentViewChild?.nativeElement,
        '.p-yearpicker .p-yearpicker-year.p-highlight'
      );
      cells.forEach(({ tabIndex }) => (tabIndex = -1));
      cell = selectedCell || cells[0];

      if (cells.length === 0) {
        const disabledCells = DomHandler.find(
          this.contentViewChild?.nativeElement,
          '.p-yearpicker .p-yearpicker-year.p-disabled[tabindex = "0"]'
        );
        disabledCells.forEach(({ tabIndex }) => (tabIndex = -1));
      }
    } else {
      cell = DomHandler.findSingle(this.contentViewChild?.nativeElement, 'span.p-highlight');
      if (!cell) {
        const todayCell = DomHandler.findSingle(
          this.contentViewChild?.nativeElement,
          'td.p-datepicker-today span:not(.p-disabled):not(.p-ink)'
        );
        if (todayCell) {
          cell = todayCell;
        } else {
          cell = DomHandler.findSingle(
            this.contentViewChild?.nativeElement,
            '.p-datepicker-calendar td span:not(.p-disabled):not(.p-ink)'
          );
        }
      }
    }

    if (cell) {
      cell.tabIndex = '0';

      if (!this.preventFocus && (!this.navigationState || !this.navigationState.button)) {
        setTimeout(() => {
          cell.focus();
        }, 1);
      }

      this.preventFocus = false;
    }
  }

  //#endregion
}

export interface CalendarConfig {
  todayMode: boolean;
  todayValue?: string;
}
