import { NgClass } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import InfoDialogComponent, {
  InfoDialogType,
} from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { Global } from '@iupics-manager/models/global-var';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-matching-acct-viewer-window-ui',
  templateUrl: './matching-acct-viewer-window-ui.component.html',
  styleUrls: ['./matching-acct-viewer-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    TranslateModule,
  ],
})
export default class MatchingAcctViewerWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  totals = 0;
  @ViewChild(InfoDialogComponent, { static: true })
  infoDialog: InfoDialogComponent;
  LengthOfRowSelected = 0;
  LetteringVerification_Process_ID = 0;
  checkLettrageProcess_ID = 0;
  checkAndDelete_Process_ID = 0;

  private config: AppConfig = inject(AppConfig);

  isModal: boolean = false;

  processLettering(item) {
    const button = this.getDatacontainer('unLettering');
    if (button) {
      button.isReadOnly = true;
    }
    const buttoVerif = this.getDatacontainer('LetteringVerification');
    if (buttoVerif) {
      buttoVerif.isReadOnly = true;
    }

    this.executeProcess(item.processId);
  }

  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    gridView.GridTabInfinityScrollUiComponent.pagination = false;
    gridView.GridTabInfinityScrollUiComponent.grid.api.setSort(
      gridView.GridTabInfinityScrollUiComponent.grid.columnApi.getColumn('AmtAbsolute'),
      'desc',
      false
    );
  }

  notifyFromDataChange(item: any) {
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['Qty'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['C_UOM_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['AD_Table_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['Record_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['C_Currency_ID'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['AmtSourceDr'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['AmtSourceCr'], false);
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['Rate'], false);

    if (item.data.columnName === 'IsShowQuantity' && this.dataStore.data['IsShowQuantity'] == 'Y') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['Qty'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['C_UOM_ID'], true);
    } else if (item.data.columnName === 'SelectDocument' && this.dataStore.data['SelectDocument'] == 'Y') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['AD_Table_ID'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['Record_ID'], true);
    } else if (item.data.columnName === 'IsShowSourceInfo' && this.dataStore.data['IsShowSourceInfo'] == 'Y') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['C_Currency_ID'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['AmtSourceDr'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['AmtSourceCr'], true);
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.columnApi.setColumnsVisible(['Rate'], true);
    } else if (item.data.columnName === 'LoadAll') {
      this.gridViews[0].GridTabInfinityScrollUiComponent.cacheBlockSize =
        this.dataStore.data['LoadAll'] === 'Y'
          ? 100000
          : this.config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize');
    }
    super.notifyFromDataChange(item);
  }

  clearSelections() {
    this.dataStore.data['selections'].forEach((grid) => {
      grid['selection'] = [];
    });
    this.gridViews.forEach((grid) => {
      grid.GridTabInfinityScrollUiComponent.grid.api.deselectAll();
    });
  }

  notifyFromRowSelected(gridView: GridViewUiComponent) {
    this.LengthOfRowSelected = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows().length;
    this.totals = 0;
    if (this.dataStore.data.selections[0].selection.length > 0) {
      for (let i = 0; i < this.dataStore.data.selections[0].selection.length; i++) {
        this.totals +=
          this.dataStore.data.selections[0].selection[i].AmtAcctDr -
          this.dataStore.data.selections[0].selection[i].AmtAcctCr;
      }
      this.dataStore.data.Total = this.totals;
    } else {
      this.dataStore.data.Total = 0;
    }
    const buttonLettrage = this.getDatacontainer('Lettering');
    const buttonDelettrage = this.getDatacontainer('unlettering');

    if (this.totals === 0 && this.LengthOfRowSelected !== 0) {
      // disable lettrage
      buttonLettrage.isReadOnly = false;
    } else {
      buttonLettrage.isReadOnly = true;
    }

    if (
      this.dataStore.data['IsShowLettrage'] === 'Y' &&
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getRowCount() > 1 &&
      this.dataStore.data['MatchingNo'] !== null &&
      this.dataStore.data['MatchingNo'].length > 0
    ) {
      buttonDelettrage.isReadOnly = false;
    } else {
      buttonDelettrage.isReadOnly = true;
    }

    this.setDataContainersValueWithChangedStore();
  }

  checkBeforeProcessing() {
    if (this.checkAndDelete_Process_ID === 0) {
      this.fields.forEach((item) => {
        if (item.data.columnName === 'CheckAndDeleteDirtyMatched') {
          this.checkAndDelete_Process_ID = item.processId;
        } else if (item.data.columnName === 'LetteringVerification') {
          this.LetteringVerification_Process_ID = item.processId;
          this.checkLettrageProcess_ID = item.processId;
        }
      });
    }
    return true;
  }

  processLetteringVerification(item) {
    let button = this.getDatacontainer('LetteringVerification');
    if (button) {
      button.isReadOnly = true;
    }
    Global.infoDialog.message = {
      summary: this.translateService.instant('infodialog.dialogs.DeleteDirtyMatched.title'),
      detail: this.translateService.instant('infodialog.dialogs.DeleteDirtyMatched.message'),
    };

    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
      const button = this.getDatacontainer('LetteringVerification');
      this.executeProcess(this.checkAndDelete_Process_ID);
      if (button) {
        button.isReadOnly = true;
      }
      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });

    const cancel = Global.infoDialog.cancel.subscribe((e) => {
      if (button) {
        button.isReadOnly = false;
      }
      this.executeProcess(this.checkLettrageProcess_ID);

      if (confirm !== undefined) {
        confirm.unsubscribe();
      }
      if (cancel !== undefined) {
        cancel.unsubscribe();
      }
    });
  }

  onEndProcess() {
    const button = this.getDatacontainer('unLettering');
    if (button) {
      button.isReadOnly = false;
    }
    const buttoVerif = this.getDatacontainer('LetteringVerification');
    if (buttoVerif) {
      buttoVerif.isReadOnly = false;
    }
    this.refreshGrids(this.dataStore, false);
    this.clearAllGridsSelection();
  }
}
