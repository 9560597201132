import { NgClass } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereField } from '@compiere-ws/models/window-json';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import CalendarUiComponent from '@iupics-components/standard/fields/calendar-ui/calendar-ui.component';
import InputTextUiComponent from '@iupics-components/standard/fields/input-text-ui/input-text-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { IupicsField } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { TranslateModule } from '@ngx-translate/core';
import { has, isNil } from 'lodash';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
@Component({
  selector: 'iu-product-attribute',
  templateUrl: './product-attribute.component.html',
  styleUrls: ['./product-attribute.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class ProductAttributeComponent
  extends SpecificWindowUiComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  attributeInstance_id = 0;
  ctxFromWs = {};
  fields: IupicsField[] = [];
  loaded = false;

  @ViewChild('grid', { read: ViewContainerRef, static: true }) gridVcr: ViewContainerRef;

  ngOnInit() {
    this.init();
  }

  notifyFromDatacontainerInit(datacontainer: AbstractDataContainer) {
    if (has(this.ctxFromWs, datacontainer.data.columnName)) {
      datacontainer.updateStore(this.ctxFromWs[datacontainer.data.columnName]);
    }
    if (datacontainer.data.columnName === 'M_Lot_ID') {
      (datacontainer as AutocompleteUiComponent).autoComplete.onSelect.subscribe((data) => {
        const lot = this.dataContainers.filter((el) => el.data.columnName === 'Lot')[0] as InputTextUiComponent;
        if (data === null || data.id === null) {
          lot.isReadOnly = false;
          lot.updateStore('');
        } else {
          lot.isReadOnly = true;
          lot.fieldValue = data.displayValue;
          lot.updateStore(data.displayValue);
        }
      });
    }
  }
  processLotNew() {
    this.newLot();
  }
  processSerNoNew() {
    this.newSerie();
  }
  init() {
    this.subscriptions.push(
      this.uiCreator
        .getProductAttribute(this.sourceComponent.data.tabId, this.getCurrentContext())
        .subscribe((fieldsWS) => {
          const fields: IupicsField[] = [];
          for (let i = 0; i < fieldsWS.length; i++) {
            const field = fieldsWS[i];
            const compiereField: CompiereField = {
              field,
              details: null,
              data: null,
              urlList: null,
              urlCallouts: null,
              urlSearch: null,
            };
            // compiereField.field.AD_Reference_ID = 10; // Remove this when WS is OK
            if (field.listBoxVO && field.listBoxVO.options) {
              // compiereField.field.AD_Reference_ID = 30; // Remove this when WS is OK
              compiereField.data = [];
              for (let j = 0; j < field.listBoxVO.options.length; j++) {
                const listValue = field.listBoxVO.options[j];
                compiereField.data.push({ id: listValue.id, displayValue: listValue.name });
              }
            }
            const fieldTransformed = UICreatorUtils.transformField(compiereField);
            if (fieldTransformed) {
              fields.push(fieldTransformed);
            }
            // }
          }

          this.buildProductPanel(fields);
        })
    );
  }
  buildProductPanel(fields: IupicsField[], isCssOnComponent = true) {
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    this.ctxFromWs['Description'] = null;
    this.fields = fields;
    const transformedFields = this.transformFields(fields);
    for (let i = 0; i < transformedFields.length; i++) {
      const item = transformedFields[i];
      /* on ajoute un defaultValue pour qu'on set les valeurs venants du store */
      this.addComponent(item, isCssOnComponent);
    }
  }

  transformFields(items: IupicsField[]): any {
    const itemsOrdered = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (['NewEdit'].includes(item.data.columnName)) {
        item.cssClass = 'p-col-6';
      } else {
        item.cssClass = 'p-col-12';
      }
      if (item.data.columnName === 'Lot') {
        item.data.readOnlyLogic = item.data.readOnlyLogic
          ? item.data.readOnlyLogic + ' | @M_Lot_ID@>0'
          : '@M_Lot_ID@>0';
      }
      if (!isNil(item.data.defaultValue) && item.data.defaultValue !== '') {
        this.dataStore.data[item.data.columnName] = item.data.defaultValue;
      }
      itemsOrdered.push(item);
    }
    return itemsOrdered;
  }

  validAttributes(isNew = false) {
    // permet d'annuler le refresh de l'editViewParent à la fermeture de la fenetre
    if (<EditViewUiComponent>this.parentComponent) {
      this.parentComponent.isProcessLaunch = false;
    }
    {
      // Sinon vérifie qu'on a bien entré toutes les données
      const jsonToSave = {};
      const errors = [];
      if (this.dataContainers.length > 0) {
        for (let i = 0; i < this.dataContainers.length; i++) {
          const el = this.dataContainers[i];
          if (el instanceof CalendarUiComponent) {
            if (
              el.calendar.inputFieldValue !== null &&
              el.calendar.inputFieldValue !== undefined &&
              el.fieldValue !== ''
            ) {
              jsonToSave[el.data.columnName] =
                '' + DateUtils.toDate(el.calendar.inputFieldValue, 'DD/MM/YYYY').getTime();
            } else {
              if (el.data.isMandatory) {
                errors.push(el.data.label);
              }
            }
          } else {
            if (el.fieldValue !== null && el.fieldValue !== undefined && el.fieldValue !== '') {
              jsonToSave[el.data.columnName] = el.fieldValue instanceof Object ? el.fieldValue.id : el.fieldValue + '';
            } else {
              if (el.data.isMandatory) {
                errors.push(el.data.label);
              }
            }
          }
        }
        if (errors.length === 0) {
          const product_id =
            this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
              ? this.sourceComponent.dataStored.data['M_Product_ID'].id
              : this.sourceComponent.dataStored.data['M_Product_ID'];
          const attributeInstanceId = this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
            ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'] instanceof Object
              ? this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID'].id
              : this.sourceComponent.dataStored.data['M_AttributeSetInstance_ID']
            : this.attributeInstance_id;
          this.uiCreator
            .saveProductAttribute(
              attributeInstanceId,
              product_id,
              this.sourceComponent.data.tabId,
              this.sourceComponent.data.fieldId,
              jsonToSave,
              this.getCurrentContext()
            )
            .subscribe((result) => {
              if (result) {
                this.selectAttribute(result[0]);
              }
              this.closeModalEmitter.emit();
            });
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('specificWindow.fillMandatory'), errors.join(', '), 'error')
          );
        }
      }
    }
  }
  newLot() {
    const product_id =
      this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_Product_ID'].id
        : this.sourceComponent.dataStored.data['M_Product_ID'];
    this.uiCreator.saveProductAttributeLot(product_id).subscribe((response) => {
      if (response) {
        this.getDatacontainer('M_Lot_ID').data.items.push({ id: response['M_Lot_ID'], displayValue: response['Name'] });
        this.dataStore.data['M_Lot_ID'] = { id: response['M_Lot_ID'], displayValue: response['Name'] };
        this.getDatacontainer('M_Lot_ID').updateStore(this.dataStore.data['M_Lot_ID']);
        const lot = this.getDatacontainer('Lot') as InputTextUiComponent;
        lot.isReadOnly = true;
        lot.updateStore(response['Name']);
      }
    });
  }
  newSerie() {
    const product_id =
      this.sourceComponent.dataStored.data['M_Product_ID'] instanceof Object
        ? this.sourceComponent.dataStored.data['M_Product_ID'].id
        : this.sourceComponent.dataStored.data['M_Product_ID'];
    this.uiCreator.saveProductAttributeSerie(product_id).subscribe((response) => {
      if (response) {
        this.dataStore.data['SerNo'] = response;
        this.getDatacontainer('SerNo').updateStore(this.dataStore.data['SerNo']);
      }
    });
  }
  private selectAttribute(data: any) {
    const sourceFieldName = this.sourceComponent.data.columnName;
    if (sourceFieldName) {
      const event = {
        data: {
          Data_UUID: sourceFieldName + ',' + data.id,
        },
      };
      (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
    }
    return;
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (this.sourceModal) {
      this.sourceModal.modalClass = 'p-col-10 p-md-8 p-lg-6';
    }
  }
}
