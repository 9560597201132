<div class="fav-grid-buttons">
  <div class="fav-grid-buttons-container">
    <span [ngClass]="['fav-grid-button', activeView === 'CVT' ? 'active' : '']" (click)="switchTo('CVT')">{{
      'favGrid.count' | translate
    }}</span>
    <span [ngClass]="['fav-grid-button', activeView === 'LVT' ? 'active' : '']" (click)="switchTo('LVT')">{{
      'favGrid.list' | translate
    }}</span>
  </div>
</div>

<div #fgiListElt class="fav-grid-items" [ngStyle]="{ display: activeView === 'LVT' ? 'flex' : 'none' }">
  @if (activeView === 'LVT') {
    <cdk-virtual-scroll-viewport itemSize="40" [minBufferPx]="240" [maxBufferPx]="240" class="fav-grid-virtualScroll">
      <div
        *cdkVirtualFor="let item of items$ | async; trackBy: trackByFn"
        class="fav-grid-item"
        (click)="openTab($event, item)">
        {{ item.displayedName }}
      </div>
    </cdk-virtual-scroll-viewport>
  }
</div>

@if (activeView === 'CVT') {
  <div
    #widgetCircularProgress
    class="circular-progress"
    [life]="3000"
    (click)="openTab($event)"
    data-cy="openInWindow"
    pTooltip="{{ widget.alertLevel || widget.normalLevel ? percent + ' %' : (result | number: numberFormat) }}"
    aria-hidden="true">
    <div class="progress-value">
      <span
        [life]="3000"
        [hideDelay]="250"
        [style.color]="bgColor">
        {{ result | number: numberFormat : lang }}
        @if (this.currency) {
          <i [attr.disabled]="disabled" class="progress-value-currency" [style.color]="bgColor">
            {{ this.currency }}
          </i>
        }
      </span>
    </div>
  </div>
}
