import { NgIf, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import {
  GanttData,
  GanttDragConfig,
  GanttDragMode,
  GanttGridColumn,
  GanttTask,
  GanttZoomLevel,
  Task,
} from '@iupics-components/models/gantt.model';
import UniversalFilterStandaloneUiComponent from '@iupics-components/standard/menu/universal-filter-standalone-ui/universal-filter-standalone-ui.component';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { TranslateModule } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { GanttPlugins, GanttStatic, gantt } from 'dhtmlx-gantt';
import { Observable, Subscription } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'iu-gantt',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.scss'],
  standalone: true,
  imports: [NgIf, UniversalFilterStandaloneUiComponent, NgStyle, TranslateModule],
})
export default class GanttComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('ganttDiv', { static: true }) ganttDiv: ElementRef<HTMLDivElement>;
  private _id_iterate = 0;
  private subscriptions: Subscription[] = [];

  paginationInProgress = false;
  gantt: GanttStatic;

  //#region universal-filter-related
  @Input() uf_columns: any[];
  @Input() uf_formId: number;
  @Input() uf_isStandalone = false;
  @Input() hasUniversalFilter = false;
  @Output() filterChange = new EventEmitter<{
    filterToApply: CompiereDataGridRequestJSON;
    isNotFromUF: boolean;
    gantt: GanttComponent;
  }>();
  @Input() setFilterEmitter: EventEmitter<CompiereDataGridRequestJSON>;
  //#endregion

  @Output() pageChange = new EventEmitter<{ gantt: GanttComponent; type: 'next' | 'prev' }>();

  @Input() ganttHeight = 600;
  @Input() gridWidth = 500;
  @Input() columns: GanttGridColumn[] = [
    {
      name: 'text',
      label: 'Task name',
      tree: true,
      width: '*',
      template: (task: GanttTask) => {
        return `<div title="${task.text}">${task.text}</div>`;
      },
    },
    { name: 'description', label: 'Description', width: 100 },
    { name: 'start_date', label: 'Start time', width: 80, align: 'center' },
    { name: 'duration', label: 'Duration', width: 60, align: 'center' },
    { name: 'add' },
  ];
  @Input() ganttData: GanttData = { data: [], links: [] };
  @Input() ganttData$: Observable<GanttData>;
  @Input() levelZoom: GanttZoomLevel = GanttZoomLevel.DAY;
  @Input() dateFormat = '%Y-%m-%d %H:%i';
  @Input() showCollapseButton = true;
  @Input() showExpandButton = true;
  @Input() showUndoButton = true;
  @Input() showRedoButton = true;
  @Input() showZoomInButton = true;
  @Input() showZoomOutButton = true;
  @Input() dragConfig: GanttDragConfig = {};
  @Input() paginationEnable = false;
  @Input() actualPage = 0;
  @Input() maximumPage = 0;
  @Input() buildCalendar: (_gantt: GanttStatic) => void = this._buildCalendar.bind(this);
  @Input() onAfterTaskDrag: (task: Task, gantt: GanttStatic, mode: GanttDragMode, e: Event) => Task[];
  @Input() onBeforeTaskDrag: (task: Task, gantt: GanttStatic, mode: GanttDragMode, e: Event) => boolean;
  @Input() onTaskOpened: (task: Task, gantt: GanttComponent) => void;
  @Input() taskText: (start: Date, end: Date, task: Task) => string = (start: Date, end: Date, task: GanttTask) =>
    task.text;
  @Input() taskClass: (start: Date, end: Date, task: Task) => string = (start: Date, end: Date, task: GanttTask) => '';
  @Input() gridRowClass: (start: Date, end: Date, task: any) => string = (start: Date, end: Date, task: GanttTask) =>
    '';
  @Input() taskRowClass: (start: Date, end: Date, task: any) => string = (start: Date, end: Date, task: any) => '';

  //#region template related
  readonly IupicsMenuType = IupicsMenuType;
  //#endregion

  constructor(private connectorService: SecurityManagerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns && !changes.columns.firstChange) {
      gantt.config.columns = this.columns;
      gantt.config.grid_width = this.columns
        .map((c) => c.width)
        .reduce((sum: number, curr: number) => sum + curr) as number;
      gantt.render();
    }
  }

  ngOnInit(): void {
    gantt.clearAll();
    gantt.config.open_split_tasks = false;

    gantt.i18n.setLocale(this.connectorService.getIupicsDefaultLanguage().iso_code.split('_')[0].toLowerCase());

    gantt.config.drag_lightbox = this.dragConfig.hasOwnProperty('drag_lightbox')
      ? this.dragConfig.drag_lightbox
      : false;
    gantt.config.drag_links = this.dragConfig.hasOwnProperty('drag_links') ? this.dragConfig.drag_links : true;
    gantt.config.drag_move = this.dragConfig.hasOwnProperty('drag_move') ? this.dragConfig.drag_move : true;
    gantt.config.drag_multiple = this.dragConfig.hasOwnProperty('drag_multiple')
      ? this.dragConfig.drag_multiple
      : false;
    gantt.config.drag_progress = this.dragConfig.hasOwnProperty('drag_progress') ? this.dragConfig.drag_progress : true;
    gantt.config.drag_resize = this.dragConfig.hasOwnProperty('drag_resize') ? this.dragConfig.drag_resize : true;
    gantt.config.drag_timeline = this.dragConfig.hasOwnProperty('drag_timeline') ? this.dragConfig.drag_timeline : true;

    gantt.config.date_format = this.dateFormat;

    gantt.config.work_time = true;
    gantt.config.calendar_property = 'calendar_id';

    gantt.plugins({
      undo: true,
      redo: true,
      zoom: true,
      grouping: true,
      multiselect: true,
      marker: true,
    } as GanttPlugins);

    gantt.addMarker({
      start_date: new Date(),
      text: 'Now',
    });

    gantt.ext.zoom.init(this.getZoomConfig());
    gantt.ext.zoom.setLevel(this.levelZoom);

    gantt.config.columns = this.columns;

    this.attachEvents();
    this.configGanttStyle();
    this.buildCalendar(gantt);

    gantt.init(this.ganttDiv.nativeElement);
    this.gantt = gantt;
  }

  private _buildCalendar(_gantt: GanttStatic): void {
    _gantt.addCalendar({
      id: 'cal_01',
      worktime: {
        hours: [8, 17],
        days: [0, 1, 1, 1, 1, 1, 0],
      },
    });
  }

  private configGanttStyle() {
    gantt.config.grid_width = this.gridWidth;
    gantt.templates.task_text = this.taskText;
    gantt.templates.task_class = this.taskClass;
    gantt.templates.grid_row_class = this.gridRowClass;
    gantt.templates.task_row_class = this.taskRowClass;
    gantt.templates.grid_folder = () => '';
    gantt.templates.grid_file = () => '';
    gantt.config.row_height = 30;
    gantt.config.task_height = 25;
    gantt.templates.timeline_cell_class = function (task, date) {
      if (!gantt.isWorkTime({ date })) {
        return 'day-off';
      }
      return '';
    };
  }

  private attachEvents() {
    gantt.attachEvent(
      'onGanttReady',
      () => {
        if (this.ganttData$) {
          this.subscriptions.push(
            this.ganttData$.subscribe((ganttData) => {
              this.ganttData = ganttData;
              gantt.parse(this.ganttData);
            })
          );
        } else {
          gantt.parse(this.ganttData);
        }
      },
      { id: `event-${this._id_iterate++}` }
    );

    gantt.attachEvent(
      'onParse',
      () => {
        gantt.showDate(DateUtils.toDate(Date.now()));
      },
      { id: `event-${this._id_iterate++}` }
    );

    if (this.onAfterTaskDrag) {
      gantt.attachEvent(
        'onAfterTaskDrag',
        (id: string, mode: GanttDragMode, e: Event) => {
          const updatedTasks = this.onAfterTaskDrag(gantt.getTask(id), gantt, mode, e);
          for (let i = 0; i < updatedTasks.length; i++) {
            const updatedTask = updatedTasks[i];
            gantt.updateTask(updatedTask.id as string, updatedTask);
          }
          gantt.render();
        },
        { id: `event-${this._id_iterate++}` }
      );
    }

    if (this.onBeforeTaskDrag) {
      gantt.attachEvent(
        'onBeforeTaskDrag',
        (id: number, mode: GanttDragMode, e: Event) => {
          return this.onBeforeTaskDrag(gantt.getTask(id), gantt, mode, e);
        },
        { id: `event-${this._id_iterate++}` }
      );
    }

    if (this.onTaskOpened) {
      gantt.attachEvent(
        'onTaskOpened',
        (id: string | number) => {
          this.onTaskOpened(gantt.getTask(id), this);
        },
        { id: `event-${this._id_iterate++}` }
      );
    }
  }

  private getZoomConfig() {
    return {
      levels: [
        {
          name: 'hour',
          scale_height: 27,
          min_column_width: 50,
          scales: [{ unit: 'hour', step: 1, format: '%H:%i' }],
        },
        {
          name: 'day',
          scale_height: 27,
          min_column_width: 50,
          scales: [{ unit: 'day', step: 1, format: '%d %M' }],
        },
        {
          name: 'week',
          scale_height: 50,
          min_column_width: 50,
          scales: [
            {
              unit: 'week',
              step: 1,
              format: (date: Date) => {
                const dateToStr = gantt.date.date_to_str('%d %M');
                const endDate = gantt.date.add(date, -6, 'day');
                const weekNum = gantt.date.date_to_str('%W')(date);
                return '#' + weekNum + ', ' + dateToStr(date) + ' - ' + dateToStr(endDate);
              },
            },
            { unit: 'day', step: 1, format: '%j %D' },
          ],
        },
        {
          name: 'month',
          scale_height: 50,
          min_column_width: 120,
          scales: [
            { unit: 'month', format: '%F, %Y' },
            { unit: 'week', format: 'Week #%W' },
          ],
        },
        {
          name: 'quarter',
          height: 50,
          min_column_width: 90,
          scales: [
            { unit: 'month', step: 1, format: '%M' },
            {
              unit: 'quarter',
              step: 1,
              format: (date: Date) => {
                const dateToStr = gantt.date.date_to_str('%M');
                const endDate = gantt.date.add(gantt.date.add(date, 3, 'month'), -1, 'day');
                return dateToStr(date) + ' - ' + dateToStr(endDate);
              },
            },
          ],
        },
        {
          name: 'year',
          scale_height: 50,
          min_column_width: 30,
          scales: [{ unit: 'year', step: 1, format: '%Y' }],
        },
      ],
    };
  }

  updateDisplayStatus(visible: boolean) {
    const length = gantt.getVisibleTaskCount();
    const taskToUpdate = [];
    for (let i = 0; i < length; i++) {
      const task: GanttTask = gantt.getTaskByIndex(i);
      if (gantt.hasChild(task.id) && task.$open === !visible) {
        task.$open = visible;
        taskToUpdate.push(task);
      }
    }

    for (let i = 0; i < taskToUpdate.length; i++) {
      const task = taskToUpdate[i];
      gantt.updateTask(task.id + '', task);
    }
  }

  printToConsole() {
    console.log(gantt.serialize());
    console.log(gantt.getState());
  }

  handlePageBtn(event: Event, type: 'next' | 'prev') {
    event.stopPropagation();
    this.paginationInProgress = true;
    this.pageChange.emit({ gantt: this, type });
  }

  onPageChange(ganttData$?: Observable<GanttData>) {
    if (ganttData$) {
      this.ganttData$ = ganttData$;
      this.subscriptions.push(
        this.ganttData$.subscribe((ganttData) => {
          gantt.clearAll();
          gantt.parse(ganttData);
          this.ganttData = ganttData;
          this.paginationInProgress = false;
        })
      );
    } else {
      this.paginationInProgress = false;
    }
  }

  onFilterChange(result: { filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean }) {
    this.filterChange.emit({ ...result, ...{ gantt: this } });
  }

  ngOnDestroy() {
    for (let i = 0; i < this._id_iterate; i++) {
      gantt.detachEvent(`event-${i}`);
    }

    for (let i = 0; i < this.subscriptions.length; i++) {
      const sub = this.subscriptions[i];
      sub.unsubscribe();
    }
  }
}
