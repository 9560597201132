import { EventEmitter, Injectable, signal } from '@angular/core';
import { TabMenuItem } from './tab-menu-item';

@Injectable({
  providedIn: 'root',
})
export class TabMenuService {
  items = signal<TabMenuItem[]>([]);

  nbItemsChange = new EventEmitter<any>();

  addWindow(item: TabMenuItem): TabMenuItem {
    const items = this.items();
    if (item.id === null || item.id === undefined) {
      item.id = items.length;
    }
    item.tooltipOptions = { tooltipLabel: item.label };
    this.items.set([...items, item]);

    this.nbItemsChange.emit();
    return item;
  }

  removeWindow(id: TabMenuItem['id']): TabMenuItem {
    const items = this.items();
    const removedItem = items.find((i) => id === i.id);
    this.items.set([...items.filter((i) => i.id != id)]);
    this.nbItemsChange.emit();
    return removedItem;
  }
}
