import { DOCUMENT, NgClass, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CompiereDataGridResponseJSON, DataStore } from '@compiere-ws/models/compiere-data-json';
import { WFDataJSON } from '@compiere-ws/models/compiere-tree-json';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { IAutocomplete } from '@iupics-components/models/autocomplete-interfaces';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import {
  ActiveTabEventType,
  ActiveTabManagerService,
} from '@iupics-manager/managers/active-tab-manager/active-tab-manager.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { MongoSearchQuery, MongoSearchQueryOperator, MongoSearchQueryPart } from '@iupics-manager/models/mongo-search';
import { OverridedCSS } from '@iupics-manager/models/overrided-css';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep, isNil } from 'lodash';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import PrimeFieldsetComponent from '../../../overrided/prime-fieldset/prime-fieldset.component';
import { SpreadOperatorPipe } from '../../../pipes/spread-operator/spread-operator.pipe';
import InputFileUiComponent from '../../../standard/fields/input-file-ui/input-file-ui.component';
import InputNumberUiComponent from '../../../standard/fields/input-number-ui/input-number-ui.component';
import InputTextUiComponent from '../../../standard/fields/input-text-ui/input-text-ui.component';
import InputTextareaUiComponent from '../../../standard/fields/input-textarea-ui/input-textarea-ui.component';
import ModalUiComponent from '../../../standard/layouts/modal-ui/modal-ui.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import {
  FinalProduct,
  Operation,
  formatAttachments,
  getOPComponents,
  getOPRessources,
  getOperationDetail,
  getProcess,
  getProducts,
  getTable,
} from './operation-management-utils';

@Component({
  selector: 'iu-operation-management-ui',
  templateUrl: './operation-management-ui.component.html',
  styleUrls: ['./operation-management-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    PrimeFieldsetComponent,
    InputTextareaUiComponent,
    InputFileUiComponent,
    NgTemplateOutlet,
    InputNumberUiComponent,
    InputTextUiComponent,
    ModalUiComponent,
    UpperCasePipe,
    TranslateModule,
    SpreadOperatorPipe,
  ],
})
export default class OperationManagementUIComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {
  readonly FULLSCREEN_CLASS = 'specific-window-fullscreen';
  @ViewChild('top', { read: ViewContainerRef, static: true }) vcrTop: ViewContainerRef;

  fakeOperation: Operation = {
    status: { id: '', displayValue: '' },
    components: [
      {
        name: 'vis',
        QtyToConsume: 10,
        QtyConsumed: 0,
        usedQty: 10,
        unit: 'unité(s)',
        Z_Production_Out_ID: -1,
        Z_Production_OutLine_ID: -1,
      },
      {
        name: 'boulon',
        QtyToConsume: 5,
        QtyConsumed: 0,
        usedQty: 5,
        unit: 'unité(s)',
        Z_Production_Out_ID: -1,
        Z_Production_OutLine_ID: -1,
      },
      {
        name: 'bois',
        QtyToConsume: 24,
        QtyConsumed: 0,
        usedQty: 24,
        unit: 'unité(s)',
        Z_Production_Out_ID: -1,
        Z_Production_OutLine_ID: -1,
      },
    ],
    ressources: [
      // { name: 'Montage', baseTime: 2, usedTime: 2 },
      // { name: 'Vernisage', baseTime: 1, usedTime: 1 }
    ],
    attachedFile: [
      // {
      //   docId: 'img-test-1234',
      //   id: 'img-test-1234',
      //   name: 'img-test.png',
      //   src: 'assets/img-test.png',
      //   isDeletable: false
      // },
      // {
      //   docId: 'pdf-test-1234',
      //   id: 'pdf-test-1234',
      //   name: 'pdf-test.pdf',
      //   src: 'assets/pdf-test.pdf',
      //   isDeletable: false
      // }
    ],
    description: "description de l'opération",
    deltaTime: '',
    note: '',
  };

  selectedOperation: Operation;

  fakeProducts = [
    {
      name: 'Article C1',
      baseQty: 2,
      realQty: 2,
    },
    {
      name: 'Article C2',
      baseQty: 5,
      realQty: 5,
    },
  ];

  selectedProducts: FinalProduct[];

  bigLabel: OverridedCSS = {
    labelFontSize: '16pt',
  };

  bigLegend: OverridedCSS = {
    legendFontSize: '18pt',
  };

  bigInput: OverridedCSS = {
    inputFontSize: '12pt',
    inputHeight: '30px',
  };

  hasDeltaTime = false;
  isLastOperation = false;
  isAddingOperationComponent = false;

  isFullScreenEnabled = true;
  isFullScreen = false;
  fullscreenChangeListener: () => void = () => {};
  keydownListener: () => void = () => {};
  activeTabListener: () => void = () => {};

  private docServerService = inject(DocServerService);
  private poService = inject(PoService);
  private workflowService = inject(CompiereWorkflowService);
  private activeTabManager = inject(ActiveTabManagerService);
  private document = inject(DOCUMENT);
  private renderer = inject(Renderer2);

  constructor() {
    super();

    this.customDesignArray.push(
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Status',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'QtyEntered',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'AD_User_ID',
        label: this.translateService.instant('operation-management.user'),
        overridedCSS: { ...this.bigLabel, ...this.bigInput },
        cssClass: 'p-col-6',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_Production_ID',
        overridedCSS: { ...this.bigLabel, ...this.bigInput },
        cssClass: 'p-col-6',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'ZLancer',
        overridedCSS: { ...this.bigLabel, ...this.bigInput },
        cssClass: 'p-col-6 btn-zlancer-style',
      },
      {
        vcr: 'vcrTop',
        type: CustomDesignItemType.FIELD,
        columnName: 'Z_Production_Operation_ID',
        overridedCSS: { ...this.bigLabel, ...this.bigInput },
        cssClass: 'p-col-6',
      }
    );
  }

  ngOnInit() {
    super.ngOnInit();
    //#region fullscreen (si on veut le recup en standard)
    if (this.isFullScreenEnabled) {
      this.enableFullscreenListener();
    }
    //#endregion
    this.activeTabListener = this.activeTabManager.addListener(
      this.activeTab,
      (e) => {
        if (e.type === ActiveTabEventType.TAB_ACTIVE) {
          this.enableFullscreenListener();
        } else if (e.type === ActiveTabEventType.TAB_INACTIVE) {
          this.disableFullscreenListener();
        } else {
          throw new Error(`ActiveTabEventType not allowed: ${e.type}`);
        }
      },
      { inSplitView: false }
    );

    this.showSpecificWindow();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.disableFullscreenListener();
    this.activeTabListener();
  }
  //#endregion

  //#region fullscreen (si on veut le recup en standard)
  enableFullscreenListener() {
    this.fullscreenChangeListener();
    this.fullscreenChangeListener = this.renderer.listen(this.document, 'fullscreenchange', () => {
      const fullscreenElement =
        this.document.fullscreenElement ||
        this.document['mozFullScreenElement'] ||
        this.document['webkitFullscreenElement'] ||
        this.document['msFullscreenElement'];
      this.isFullScreen = fullscreenElement !== undefined;
    });

    this.keydownListener();
    this.keydownListener = this.renderer.listen(this.document, 'keydown.f11', (event: KeyboardEvent) => {
      event.preventDefault();
      this.toggleFullscreen();
    });
  }

  private toggleFullscreen() {
    const fullscreenElement =
      this.document.fullscreenElement ||
      this.document['mozFullScreenElement'] ||
      this.document['webkitFullscreenElement'] ||
      this.document['msFullscreenElement'];
    if (this.isFullScreen && !isNil(fullscreenElement)) {
      this.document.exitFullscreen();
    } else if (!this.isFullScreen && isNil(fullscreenElement)) {
      this.specificContainer.nativeElement.requestFullscreen();
    }
  }

  disableFullscreenListener() {
    this.fullscreenChangeListener();
    this.keydownListener();
  }
  //#endregion

  notifyFromDatacontainerInit(dataContainer: AbstractDataContainer) {
    switch (dataContainer.data.columnName) {
      case 'Z_Production_ID':
        this.subscriptions.push(
          dataContainer.fieldValueModified.subscribe((value: DataStore) => {
            this.selectedOperation = undefined;
          })
        );
        break;
      case 'Z_Production_Operation_ID':
        this.subscriptions.push(
          dataContainer.fieldValueModified.subscribe((value: DataStore) => {
            const Z_Production_Operation_ID: IAutocomplete = value.data['Z_Production_Operation_ID'];
            const ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
            if (Z_Production_Operation_ID) {
              const searchQuery = new MongoSearchQuery(
                MongoSearchQueryPart.createQueryPart(
                  'META|Z_PRODUCTION_OPERATION_ID',
                  `${Z_Production_Operation_ID.id}`,
                  MongoSearchQueryOperator.EQUALS
                ),
                this.connectorService.getIupicsUserContext(),
                this.connectorService.getIupicsUserAccount().current_role.orgAccess
              );
              zip(
                this.store.getDataGrid(getOperationDetail(Z_Production_Operation_ID.id, ad_language)),
                this.store.getDataGrid(getOPComponents(Z_Production_Operation_ID.id, ad_language)),
                this.store.getDataGrid(getOPRessources(Z_Production_Operation_ID.id, ad_language)),
                this.docServerService
                  .advancedSearchDocuments(searchQuery, { attachmentInteraction: false, limit: -1, start: 0 })
                  .pipe(map(formatAttachments))
              )
                .pipe(
                  switchMap(([operation, components, ressources, attachedFile]) => {
                    const _selectedOperation: Operation = {
                      status: operation.data[0]['DocStatus'],
                      attachedFile,
                      components: components.data.map((data) => {
                        return {
                          name: data['M_Product_ID']?.displayValue,
                          QtyConsumed: data['QtyConsumed'],
                          QtyToConsume: data['QtyToConsume'],
                          unit: data['C_UOM_ID'].displayValue,
                          usedQty: data['QtyToConsume'] - data['QtyConsumed'],
                          Z_Production_Out_ID: data['Z_Production_Out_ID'],
                          Z_Production_OutLine_ID: data['Z_Production_OutLine_ID'],
                        };
                      }),
                      deltaTime: '',
                      description: operation.data[0]['Description'],
                      note: '',
                      ressources: cloneDeep(this.fakeOperation.ressources),
                    };
                    this.isLastOperation = operation.data[0]['IsLastOperation'] === 'Y';
                    this.selectedOperation = _selectedOperation;
                    // TODO: modification temporaire, le temps que je corrige le processus de lancement des operations
                    const _keys = [
                      { from: 'DocStatus', to: 'Status' },
                      { from: 'QtyEntered', to: 'QtyEntered' },
                    ];
                    const tosync = {};
                    for (let i = 0; i < _keys.length; i++) {
                      const key = _keys[i];
                      const _datacontainer = this.dataContainers.find((dc) => dc.data.columnName === key.to);
                      if (_datacontainer) {
                        tosync[key.to] = operation.data[0][key.from];
                      }
                    }
                    if (Object.keys(tosync).length > 0) {
                      this.store.syncDataChanges(this.dataStore, tosync);
                    }
                    if (this.isLastOperation) {
                      return this.store.getDataGrid(getProducts(Z_Production_Operation_ID.id, ad_language));
                    }
                    return of(undefined as CompiereDataGridResponseJSON);
                  })
                )
                .subscribe((products) => {
                  if (products !== undefined) {
                    this.selectedProducts = products.data.map((data) => {
                      return {
                        name: data['M_Product_ID'].displayValue,
                        QtyProduced: data['QtyProduced'],
                        QtyToProduce: data['QtyToProduce'],
                        unit: data['C_UOM_ID'].displayValue,
                        usedQty: data['QtyToProduce'] - data['QtyProduced'],
                        Z_Production_In_ID: data['Z_Production_In_ID'].id,
                        Z_Production_InLine_ID: data['Z_Production_InLine_ID'],
                      };
                    });
                  }
                });
            } else {
              this.selectedOperation = undefined;
            }
          })
        );
        break;
      default:
        break;
    }
  }

  changeUsedQtyOperation(nb: number, index: number) {
    if (!(this.selectedOperation.components[index].usedQty === 0 && nb < 0)) {
      this.selectedOperation.components[index].usedQty += nb;
    }
  }

  updateUsedQtyOperation(value: number, index: number) {
    if (value < 0) {
      value = 0;
    }
    this.selectedOperation.components[index].usedQty = value;
  }

  changeUsedQtyProduct(nb: number, index: number) {
    if (!(this.selectedProducts[index].usedQty === 0 && nb < 0)) {
      this.selectedProducts[index].usedQty += nb;
    }
  }

  updateUsedQtyProduct(value: number, index: number) {
    if (value < 0) {
      value = 0;
    }
    this.selectedProducts[index].usedQty = value;
  }

  changeUsedTime(nb: number, index: number) {
    if (!(this.selectedOperation.ressources[index].usedTime === 0 && nb < 0)) {
      this.selectedOperation.ressources[index].usedTime += nb;
    }
    this.checkDeltaTime();
  }

  updateUsedTime(value: number, index: number) {
    if (value < 0) {
      value = 0;
    }
    this.selectedOperation.ressources[index].usedTime = value;
    this.checkDeltaTime();
  }

  private checkDeltaTime(): void {
    let hasDeltaTime = false;
    for (let i = 0; i < this.selectedOperation.ressources.length; i++) {
      const res = this.selectedOperation.ressources[i];
      if (res.baseTime !== res.usedTime) {
        hasDeltaTime = true;
        break;
      }
    }

    if (this.hasDeltaTime !== hasDeltaTime) {
      this.hasDeltaTime = hasDeltaTime;
      if (!hasDeltaTime) {
        this.selectedOperation.deltaTime = '';
      }
    }
  }

  updateDeltaTime(value: string) {
    this.selectedOperation.deltaTime = value;
  }

  saveOperation() {
    this._saveOperation().subscribe((responses) => {
      const errors = responses.filter((data) => data.isError);
      if (errors.length) {
      }
    });
  }

  private _saveOperation(): Observable<any> {
    const data_to_save = this.selectedOperation.components.map((conso) => {
      const QtyConsumed = conso.QtyConsumed + conso.usedQty;
      return this.poService.save('Z_Production_OutLine', { QtyConsumed }, conso.Z_Production_OutLine_ID).pipe(
        catchError((error) => {
          return of({ isError: true, line: conso });
        })
      );
    });
    return zip(...data_to_save);
  }

  validateOperation() {
    this._saveOperation()
      .pipe(
        switchMap((responses) => {
          const errors = responses.filter((data) => data.isError);
          if (errors.length) {
            return of();
          } else {
            const ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
            return zip(
              this.store.getDataGrid(getTable('Z_Production_Out', ad_language)),
              this.store.getDataGrid(getProcess('Z_Production_Out Process', ad_language))
            ).pipe(
              switchMap(([table, process]) => {
                const wfData: WFDataJSON = {
                  action: 'CO',
                  ad_process_id: `${process.data[0]['AD_Process_ID']}`,
                  record_id: `${this.selectedOperation.components[0].Z_Production_Out_ID}`,
                  table_Name: `${table.data[0]['tableName']}`,
                  table_id: `${table.data[0]['AD_Table_ID']}`,
                  windowCtx: this.getCurrentContext(),
                };
                return this.workflowService.runWF(wfData);
              })
            );
          }
        })
      )
      .subscribe((response) => {
        console.log(response);
      });
  }

  saveProducts() {
    this._saveProducts().subscribe((responses) => {
      const errors = responses.filter((data) => data.isError);
      if (errors.length) {
      }
    });
  }

  private _saveProducts() {
    const data_to_save = this.selectedProducts.map((product) => {
      const QtyProduced = product.QtyProduced + product.usedQty;
      return this.poService.save('Z_Production_InLine', { QtyProduced }, product.Z_Production_InLine_ID).pipe(
        catchError((error) => {
          return of({ isError: true, line: product });
        })
      );
    });
    return zip(...data_to_save);
  }

  validateProducts() {
    this._saveProducts()
      .pipe(
        switchMap((responses) => {
          const errors = responses.filter((data) => data.isError);
          if (errors.length) {
            return of();
          } else {
            const ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
            return zip(
              this.store.getDataGrid(getTable('Z_Production_In', ad_language)),
              this.store.getDataGrid(getProcess('Process_DeclarationProduction', ad_language))
            ).pipe(
              switchMap(([table, process]) => {
                const wfData: WFDataJSON = {
                  action: 'CO',
                  ad_process_id: `${process.data[0]['AD_Process_ID']}`,
                  record_id: `${this.selectedProducts[0].Z_Production_In_ID}`,
                  table_id: `${table.data[0]['AD_Table_ID']}`,
                  table_Name: `${table.data[0]['tableName']}`,
                  windowCtx: this.getCurrentContext(),
                };
                return this.workflowService.runWF(wfData);
              })
            );
          }
        })
      )
      .subscribe((response) => {
        console.log(response);
      });
  }

  toggleComponentInput() {
    this.isAddingOperationComponent = true;
  }

  // TODO: renommer la méthode pour qu'elle corresponde à ce qu'elle fait
  addOperationComponent(name: string, baseQty: number, unit: string) {
    // TODO: ajouter la logique d'ajout du composant
    // this.selectedOperation.components.push({
    //   name,
    //   QtyToConsume: baseQty,
    //   usedQty: baseQty,
    //   unit,
    //   Z_Production_OutLine_ID: -1
    // });
    this.isAddingOperationComponent = false;
  }
}
